import axios from "axios";
import { useNavigate  } from "react-router-dom";


//get Token From local storage
const token = JSON.parse(localStorage.getItem("token"));

const airwallexToken = JSON.parse(localStorage.getItem("airWallexToken"));
const airwallexObBehalfToken = JSON.parse(
  localStorage.getItem("airwallexOnBehalfToken")
);

//apply base url for axios
// const API_URL = process.env.REACT_APP_BASE_URL

const API_URL = process.env.REACT_APP_BASE_URL
const AIRWALLEX_URL = process.env.REACT_APP_AIRWALLEX_URL



const axiosApi = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
    traceId: 123,
    Authorization: `${token}`,
    allowedHeaders: "*",
  },
});



const axiosApiAirwallex = axios.create({
  baseURL: AIRWALLEX_URL,
  headers: {
    "Content-Type": "application/json",
    "x-client-id": process.env.REACT_APP_AIRWALLEX_CLIENT_ID, //globle tps id
    "x-api-key": process.env.REACT_APP_X_API_KEY ,
    "x-on-behalf-of": `${airwallexObBehalfToken}`,
    Authorization: `Bearer ${airwallexToken}`,
  },
});


axiosApi.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (originalConfig.url !== "/tps-auth-service/api/auth/authenticate" && err.response) {

      // Access Token was expired
      if (err.response.status === 401) {
        return window.location.href = '/logout'
      }
    }

    return Promise.reject(err);
  }
);

export async function get(url, config = {}) {
  return await axiosApi
    .get(url, { ...config })
    .then((response) => response.data);
}

export async function post(url, data, config = {}, token) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then((response) => response.data);
}


export async function postAirwallex(url, data, config = {}) {
  return axiosApiAirwallex
    .post(url, { ...data }, { ...config })
    .then((response) => response.data);
}



export { axiosApi, axiosApiAirwallex };