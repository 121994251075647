import React, { useState } from "react"
import VerticalLayout from "../../components/VerticalLayout/VerticalLayout"

//constants
import { layoutTypes } from "../../constants/layout"
import { useEffect } from "react"
import { VerifyAccessToken } from "../../services/AuthService"
import {useDispatch, useSelector} from "react-redux"
import { setAuthData } from "../../store/reducers/authReducer"
import {Navigate, useLocation} from "react-router-dom";

const Authmiddleware = props => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [authorize, setAuthorize] = useState(false)
  const accessToken = JSON.parse(localStorage.getItem("token"))


  const data = useSelector(state => state.auth.data)
  
  const location = useLocation();

  useEffect(() => {
    setLoading(true)
    varifyUserAccess()
  }, [])

  useEffect(() => {
      checkVerifyMethod()
  }, [authorize])

  const checkVerifyMethod = async () => {
    setTimeout(() => {
      setLoading(false)
    }, 1500)
  }
  
  

  const varifyUserAccess = async () => {

    try {
      const response = await VerifyAccessToken({
        accessToken: accessToken,
      })
      dispatch(setAuthData(response.result))
      setAuthorize(true)
    } catch (error) {
      setAuthorize(false)
      //toast.error("Unauthorized")
    }
  }

 
  const layoutType = "vertical"

  const getLayout = layoutType => {
    let Layout = VerticalLayout
    switch (layoutType) {
      case layoutTypes.VERTICAL:
        Layout = VerticalLayout
        break
      default:
        break
    }
    return Layout
  }

  const Layout = getLayout(layoutType)


  if (loading) {
    return <div className="pre-loader">
      <div className="loader"></div>
    </div>
  } else {
 
    if ( Object.keys(data).length === 0 ) {
      const currentPath = location.pathname;
      return <Navigate to={`/login?redirect=${currentPath}`} />;
    }else{
      return (
        <React.Fragment>
          <Layout>{props.children}</Layout>
        </React.Fragment>
      )
    }
 }

}

export default Authmiddleware
