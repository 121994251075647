import React from "react";
import {
  Col,
  Container,
  Row,
  Card,
  CardTitle, CardBody,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Link } from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Avatar} from "@material-ui/core";

const FundsTransfer = () => {

  const { t } = useTranslation();

  document.title = t("FUNDS_TRANSFER_TPS_SOLUTIONS");
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box p-0">
            <Row>
              <Col md={6}>
                <Breadcrumbs
                  title={t('TPS_SOLUTIONS')}
                  breadcrumbItem={t("FUNDS_TRANSFER")}
                  mainTitleLink="/"
                  titleLink="#"
                />
              </Col>
            </Row>
          </div>

          <Row>
            <Col md={6} lg={4}>
              <Link to="/funds-transfer/registered-payee">
                <Card className="mb-3">
                  <CardBody className="">
                    <div className="d-flex justify-content-center mb-3">
                      <i className="ti-check-box custom-primary-color f-72"></i>
                    </div>
                    <div className="text-center">
                      <h6 className="m-2 font-size-18 fw-700">{t("REGISTERED_PAYEE")}</h6>
                      <p className="mb-3 font-size-14 h-71 txt-overflow txt-overflow-3">{t("A_REGISTERED_PAYEE_IS_A_PERSON_OR_ENTITY_WHOSE_DETAILS_HAVE_BEEN_OFFICIALLY_RECORDED_WITHIN_OUR_TPS_SYSTEM_FOR_EASY_AND_SECURE_USE")}</p>
                      <button className="btn btn-primary w-md">{t("CLICK_HERE")}</button>
                    </div>
                  </CardBody>
                </Card>
              </Link>
            </Col>

            <Col md={6} lg={4}>
              <Link to="/funds-transfer/new-payee">
                <Card className="mb-3">
                  <CardBody className="">
                    <div className="d-flex justify-content-center mb-3">
                      <i className="ti-user custom-primary-color f-72"></i>
                    </div>
                    <div className="text-center">
                      <h6 className="m-2 font-size-18 fw-700">{t("NEW_PAYEE")}</h6>
                      <p className="mb-3 font-size-14 h-71 txt-overflow txt-overflow-3">{t("A_NEW_PAYEE_IS_SOMEONE_YOU_HAVEN_PAID_BEFORE")}</p>
                      <button className="btn btn-primary w-md">{t("CLICK_HERE")}</button>
                    </div>
                  </CardBody>
                </Card>
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default FundsTransfer;
