import React, { useEffect, useState } from "react";
import {
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
// import { answersForSecurityQuestions } from "services/AuthService"
import { getSecurityQuestion } from "../../services/CommonService";
import FormTextField from "../../common/formComponents/FormTextField";
import {
  answersForSecurityQuestions,
  getSecurityQuestionById,
} from "../../services/AuthService";

const AnswerSecurityQuestions = ({
  setSecurityQuestionsVerified,
  coUserId,
}) => {
  const { t } = useTranslation();

  const [userQuestionDetails, setUserQuestionDetails] = useState([
    {"questionText" : ""},
    {"questionText" : ""},
    {"questionText" : ""}
  ]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchSecurityQuestion();
  }, []);

  const fetchSecurityQuestion = async () => {
    setLoading(true);
    try {
      const response = await getSecurityQuestionById(coUserId);
      setUserQuestionDetails(response.result);
      setLoading(false);
    } catch (error) {
      toast.error(t("ERROR_SECURITY_QUESTION"));
      setLoading(false);
    }
  };

  const onSubmit = async (values) => {

    setLoading(true);
    const data = userQuestionDetails.map((question, index) => {
      return {
        coUserId: coUserId,
        answerId: "null",
        questionId: question.questionId,
        answerText:
          index === 0
            ? values.answers_1
            : index === 1
            ? values.answers_2
            : values.answers_3,
      };
    });

    const answerData = {
      answers: data,
    };

    try {
      const response = await answersForSecurityQuestions(answerData);
      if (response.statusMessage == "Success") {
        setSecurityQuestionsVerified(true);
        setLoading(false);
        toast.success(t("ANSWERED_SECURITY_QUESTIONS_SUCCESSFULLY"));
      }
      setLoading(false);
    } catch (error) {
      toast.error(t("INCORRECT_ANSWERS_PLEASE_TRY_AGAIN"));
      setLoading(false);
    }
  };

  const validationSchema = yup.object().shape({
    answers_1: yup.string().required(t("ANSWER_ONE_IS_REQUIRED")),
    answers_2: yup.string().required(t("ANSWER_TWO_IS_REQUIRED")),
    answers_3: yup.string().required(t("ANSWER_THREE_IS_REQUIRED")),
  });

  document.title = t("ANSWER_SECURITY_QUESTIONS_TPS_SOLUTIONS");
  return (
    <React.Fragment>
      <Row className="mt-3 mb-3">
        <Col md={12} style={{ marginTop: "20px" }}>
          <h4 className="text-white">{t("ANSWER_SECURITY_QUESTIONS")}</h4>
        </Col>
      </Row>
      {loading ? (
        <Spinner size="sm">Loading...</Spinner>
      ) : (
        <Formik
          enableReinitialize
          initialValues={{
            answers_1: "",
            answers_2: "",
            answers_3: "",
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ values }) => (
            <Form>
              <Row>
             
                <Col md={12} >
                  <FormGroup className="mb-3 text-white-70">
                    <Label
                      className="form-label"
                      // htmlFor={`answers[${index}]`}
                    >
                      {userQuestionDetails[0].questionText}
                    </Label>
                    <FormTextField
                      name="answers_1"
                      id="answers_1"
                      // placeholder={userQuestion.text}
                    />
                  </FormGroup>
                </Col>

                <Col md={12} >
                  <FormGroup className="mb-3 text-white-70">
                    <Label
                      className="form-label"
                      // htmlFor={`answers[${index}]`}
                    >
                      {userQuestionDetails[1].questionText}
                    </Label>
                    <FormTextField
                      name="answers_2"
                      id="answers_2"
                      // placeholder={userQuestion.text}
                    />
                  </FormGroup>
                </Col>

                <Col md={12} >
                  <FormGroup className="mb-3 text-white-70">
                    <Label
                      className="form-label"
                      // htmlFor={`answers[${index}]`}
                    >
                      {userQuestionDetails[2].questionText}
                    </Label>
                    <FormTextField
                      name="answers_3"
                      id="answers_3"
                      // placeholder={userQuestion.text}
                    />
                  </FormGroup>
                </Col>

                <Col md={12} className="text-center mt-4">
                  <button
                    className="btn glass-btn w-md waves-effect waves-light"
                    type="submit"
                  >
                    {loading ? <Spinner size="sm">Loading...</Spinner> : ""}{" "}
                    {t("SUBMIT")}
                  </button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      )}
    </React.Fragment>
  );
};

export default AnswerSecurityQuestions;
