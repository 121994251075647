import React from 'react';
import {
    Container,
    Row,
    Col,
    Button,
    Card,
    CardBody,
    Form,
    Input,
    FormGroup,
    Label
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import Breadcrumbs from '../../components/Common/Breadcrumb';

const AddTopUp = (props) => {

    document.title = 'Add Top-Up | TPS Solutions';
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs maintitle="TPS Solutions" title="Top-Up" breadcrumbItem="Add Top-Up" mainTitleLink="/" titleLink="/top-up" />

                    <Row>
                        <Col md={12}>
                            <Card>
                                <CardBody>
                                    <Form action="#">
                                        <Row>
                                            <Col sm={6} md={4}>
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="bankAccountCurrency">Bank Account Currency <sup className="text-danger">*</sup></Label>
                                                    <div className="d-flex align-items-center">
                                                        {/*Bank Currency Balance*/}
                                                        <div className="w-75">
                                                            <Input
                                                                name="bankAccountCurrencyBalance"
                                                                placeholder="Bank Account Currency Balance"
                                                                type="text"
                                                                className="form-control input-group-left-radius input-group-left-border"
                                                                id="bankAccountCurrencyBalance"
                                                                // onChange={e => setBankAccountCurrencyBalance(e.target.value)}
                                                                // value={validation.values.bankAccountCurrencyBalance || ""}
                                                                // invalid={errors.bankAccountCurrencyBalance ? true : false}
                                                            />
                                                            {/*{errors.bankAccountCurrencyBalance ? (*/}
                                                            {/*    <FormFeedback type="invalid">{errors.bankAccountCurrencyBalance}</FormFeedback>*/}
                                                            {/*) : null}*/}
                                                        </div>
                                                        {/*Bank Currency Balance*/}

                                                        {/*Bank Currency Type*/}
                                                        <div className="w-25">
                                                            <Input
                                                                name="bankAccountCurrency"
                                                                placeholder="Bank Account Currency"
                                                                type="select"
                                                                className="form-control input-group-right-radius"
                                                                id="bankAccountCurrency"
                                                                // onChange={e => setBankAccountCurrency(e.target.value)}
                                                                // value={bankAccountCurrency}
                                                                // invalid={errors.bankAccountCurrency ? true : false}
                                                            >
                                                                <option value="">LKR</option>
                                                                <option value="">USD</option>
                                                                <option value="">GBP</option>
                                                                {/*{bankAccountCurrencies.map((bankAccountCurrency, index) => (*/}
                                                                {/*    <option key={index} value={bankAccountCurrency.value}>*/}
                                                                {/*        {bankAccountCurrency.label}*/}
                                                                {/*    </option>*/}
                                                                {/*))}*/}
                                                            </Input>
                                                            {/*{errors.bankAccountCurrency ? (*/}
                                                            {/*    <FormFeedback type="invalid">{errors.bankAccountCurrency}</FormFeedback>*/}
                                                            {/*) : null}*/}
                                                        </div>
                                                        {/*Bank Currency Type*/}
                                                    </div>
                                                </FormGroup>
                                            </Col>

                                            <Col md={6} lg={4}>
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="sourceOfFunds">Source Of Funds <sup className="text-danger">*</sup></Label>
                                                    <Input
                                                        name="sourceOfFunds"
                                                        placeholder="Source Of Funds"
                                                        type="text"
                                                        className="form-control"
                                                        id="sourceOfFunds"
                                                        // onChange={e => setSourceOfFunds(e.target.value)}
                                                        // value={validation.values.sourceOfFunds || ""}
                                                        // invalid={errors.sourceOfFunds ? true : false}
                                                    />
                                                    {/*{errors.sourceOfFunds ? (*/}
                                                    {/*    <FormFeedback type="invalid">{errors.sourceOfFunds}</FormFeedback>*/}
                                                    {/*) : null}*/}
                                                </FormGroup>
                                            </Col>

                                            <Col md={6} lg={4}>
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="amount">Amount <sup className="text-danger">*</sup></Label>
                                                    <Input
                                                        name="amount"
                                                        placeholder="Amount"
                                                        type="number"
                                                        className="form-control"
                                                        id="amount"
                                                        // onChange={e => setAmount(e.target.value)}
                                                        // value={validation.values.amount || ""}
                                                        // invalid={errors.amount ? true : false}
                                                    />
                                                    {/*{errors.amount ? (*/}
                                                    {/*    <FormFeedback type="invalid">{errors.amount}</FormFeedback>*/}
                                                    {/*) : null}*/}
                                                </FormGroup>
                                            </Col>

                                            <Col md={6} lg={12} className="d-flex justify-content-end align-items-center gap-3">
                                                <Button className="btn-primary w-md" type="button">Top-Up</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withTranslation()(AddTopUp);