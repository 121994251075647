
import React, { useState, useEffect } from "react"
import { Formik, Form } from "formik"
import * as yup from "yup"
import { useTranslation } from "react-i18next"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap';

import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  Label,
  FormFeedback,
  Input,
} from "reactstrap"


import { Link,  useLocation,  useNavigate, useParams } from "react-router-dom"

// import images
import logoSm from "../../assets/images/logo/Logo-Icon.webp"
import loginBg from "../../assets/images/login/auth-bg.jpeg"
import FormTextField from "../../common/formComponents/FormTextField"
import { toast } from "react-toastify"
import { useDispatch } from "react-redux"
import { customerAthenticateAsync } from "../../store/reducers/authReducer"
import { useSelector } from "react-redux"

const Login = props => {
  const { t } = useTranslation()
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const location = useLocation();
  const params = useParams();


  const queryParams = new URLSearchParams(location.search);
  const redirectValue = queryParams.get("redirect");

  const redirectPath = redirectValue ? redirectValue : '/dashboard'


  const [userLogin, setUserLogin] = useState([])
  const [advisoryMsgConfirm, setAdvisoryMsgConfirm] = useState(false)
  const [isChecked, setIsChecked] = useState(false);
  const [showPassword, setShowPassword] = useState(false)
  const [modal, setModal] = useState(false);
  const [loginFormValues, setLoginFormValues] = useState({})
  const [loginLoading, setLoginLoading] = useState(false)

  const  loading = useSelector(state => state.auth.loading)
  const  data = useSelector(state => state.auth.data)
  const  status = useSelector(state => state.auth.status)

 

  useEffect(() => {

    if(status === "failed"){
      setLoginLoading(false)
      toast.error(t("INVALID_CREDENTIALS"))
      return 
    }

    if(loading === false && status === "succeeded"  && Object.keys(data).length > 0){
      setLoginLoading(false)
      navigate(redirectPath)
    }
    
  }, [ data, loading])


  async function onSubmit(values) {
    setLoginLoading(true)
    setLoginFormValues(values)

    if(advisoryMsgConfirm === false){
      setModal(true)
    }else{

      const data = {
        username: values.email,
        password: values.password,
        type: ""
      }
      try {
        dispatch(customerAthenticateAsync(data))
  
      } catch (error) {
        toast.error("Authenticate Fail")
      }

    }

   
  }

  const validationSchema = yup.object().shape({
    email: yup.string().email().required(t("EMAIL_IS_REQUIRED")),
    password: yup.string().required(t("PASSWORD_IS_REQUIRED")),
  })

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  
  const toggle = () => {
    setModal(!modal)
    setLoginLoading(false)
  };

  const loginHandler = (submitForm) => {
    if(advisoryMsgConfirm === false){
      setModal(true)
    }else{
      submitForm()
    }

  }

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleContinueClick = () => {
    if(isChecked == true){
      setAdvisoryMsgConfirm(true)
      setModal(false)
      const data = {
        username: loginFormValues.email,
        password: loginFormValues.password,
        type: ""
      }
      try {
        dispatch(customerAthenticateAsync(data))
      } catch (error) {
        toast.error("Authenticate Fail")
      }



    }
    // submitForm()
  };

  document.title = `${t("LOGIN")} | TPS ${t("SOLUTIONS")}`
  return (
    <React.Fragment>
      {/*<div className="home-btn d-none d-sm-block">*/}
      {/*  <Link to="/" className="text-dark">*/}
      {/*    <i className="fas fa-home h2" />*/}
      {/*  </Link>*/}
      {/*</div>*/}
      <div className="account-pages h-100vh bg-white overflow-auto">
        <Container className="h-100">
          <Row className="flex-wrap justify-content-center align-items-center h-100 py-3">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden mb-0 auth-bg rounded-5">
                <img className="auth-bg-img" src={loginBg} alt="login-bg"/>
                <div className="position-relative z-3">
                  <div className="text-primary text-center p-4">
                    <h5 className="text-white font-size-20">
                      {t("WELCOME")} !
                    </h5>
                    <p className="text-white-70">
                      {t("SIGN_IN_TO_COUNTINUE_TO_SILKWORM")}
                    </p>
                    <Link to="/" className="logo logo-admin">
                      <img src={logoSm} height="42" alt="logo" />
                    </Link>
                  </div>
                </div>

                <CardBody className="py-4 px-4 mt-4 position-relative z-3">
                  <Formik
                    enableReinitialize
                    initialValues={{
                      email: "",
                      password: "",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                  >
                    {({ isSubmitting, submitForm }) => (
                      <Form>
                        <div className="mb-3">
                          <Label className="form-label text-white-70" htmlFor="username">
                            {t("EMAIL")}
                          </Label>
                          <FormTextField
                            name="email"
                            id="email"
                            placeholder={t("ENTER_EMAIL")}
                          />
                        </div>

                        <div className="mb-3">
                          <Label className="form-label text-white-70" htmlFor="userpassword">
                            {t("PASSWORD")}
                          </Label>
                          <div
                            className="input-group password-input-group"
                            style={{
                              display: "grid",
                              gridTemplateColumns: "91% 9%",
                            }}
                          >
                            <FormTextField
                              name="password"
                              id="password"
                              placeholder={t("ENTER_PASSWORD")}
                              type={showPassword ? "text" : "password"}
                            />
                            <div className="input-group-append bg-white auth-bg-input-append">
                              <button
                                type="button"
                                className="btn btn-secondary password-btn"
                                onClick={togglePasswordVisibility}
                              >
                                {showPassword ? (
                                  <i className="fas fa-eye"></i> // You may need to import FontAwesome or use another icon library
                                ) : (
                                  <i className="fas fa-eye-slash"></i> // You may need to import FontAwesome or use another icon library
                                )}
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="mb-4 row">
                          <div className="col-sm-6">
                            <div className="form-check">
                              {/*<input*/}
                              {/*  type="checkbox"*/}
                              {/*  className="form-check-input"*/}
                              {/*  id="customControlInline"*/}
                              {/*/>*/}
                              {/*<label*/}
                              {/*  className="form-check-label"*/}
                              {/*  htmlFor="customControlInline"*/}
                              {/*>*/}
                              {/*  /!*{t("REMEMBER_ME")}*!/*/}
                              {/*</label>*/}
                            </div>
                          </div>

                          <div className="col-sm-6 text-sm-end">
                            <Link to="/forgot-password">
                              <i className="mdi mdi-lock text-white"></i>
                              <span className="text-white">{t("FORGOT_YOUR_PASSWORD")}</span>
                            </Link>
                          </div>
                        </div>

                        <div className="mb-4 row">
                          <div className="col-sm-12 text-center">
                            <button
                              className="btn glass-btn w-lg waves-effect waves-light"
                              // onClick={() =>{ 
                              //   // setSubmitObj(submitForm)
                              //   loginHandler(submitForm)
                              // }}
                              type="submit"
                            >
                              {loginLoading ? (
                                <Spinner size="sm" style={{ marginRight: "25px"}}>Loading...</Spinner>
                              ) : (
                                ""
                              )}{" "}
                              {t("LOG_IN")}
                            </button>
                          </div>
                        </div>

                        <div className="text-center">
                          <h6 className="mb-1 text-white-70" style={{ fontWeight: "bold"}}>
                            {t("DON")}&#39;{t("T_HAVE_AN_ACCOUNT")}{" "}
                            <Link
                              to="/sign-up"
                              className="fw-medium text-primary"
                              // style={{ fontWeight: "bold"}}
                            >
                              {" "}
                              <span className="text-white" style={{ fontWeight: "bold"}}>{t("REGISTER_NOW")}</span>{" "}
                            </Link>{" "}
                          </h6>
                          <p className="mb-0 text-white-70">
                            © {new Date().getFullYear()} {t("TPS_SOLUTIONS")}.
                          </p>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </CardBody>
              </Card>
            </Col>

            {/*<Col md={8} lg={6} xl={7} className="d-none d-lg-block">*/}
            {/*  <div className="d-flex justify-content-center align-items-end h-100 login-side br-1 bx-shadow-1">*/}
            {/*    <div className="text-center p-4 mb-4">*/}
            {/*      <h5 className="text-primary-blue font-size-24 mb-3">*/}
            {/*        {t("WELCOME_TO_TPS_SOLUTIONS")}*/}
            {/*      </h5>*/}
            {/*      <p className="text-primary-blue">*/}
            {/*        {t("A_CONTEMPORARY_COMPANY_ACCOUNT_THAT_COMBINED_CORPORATE_CARDS")}*/}
            {/*      </p>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</Col>*/}
          </Row>
        </Container>
      </div>



      {/*Safeguard Modal*/}
      <Modal isOpen={modal} toggle={toggle} centered className="border-none-modal rounded-modal">
        {/*Modal Body*/}
        <ModalBody className="text-center p-0 auth-bg overflow-hidden rounded-5">

          <img className="auth-bg-img" src={loginBg} alt="login-bg"/>

          {/*Modal title*/}
          <h5 className="text-white font-size-16 fw-normal mb-3 px-2 py-3 position-relative z-3">{t("SAFEGUARD_YOUR_TRANSACTIONS_WITH_GOOGLE_MICROSOFT_AUTHENTICATOR")}</h5>

          <div className="w-100 px-3 pb-3 position-relative z-3">
            {/*Modal Text*/}
            <p className="mb-2 font-size-14 text-white-70">
              {t("USE_OUR_AUTHENTICATION_SYSTEM_TO_SAFEGUARD_ALL_YOUR_TRANSACTIONS")}
            </p>

            {/*Modal Text*/}
            <p className="mb-2 font-size-14 text-white-70">
              {t("OTP_IS_NOT_TO_BE_SHARED_WITH_ANYONE_TO_PREVENT_MISUSE")}
            </p>

            {/*Modal Text*/}
            <p className="mb-3 font-size-14 text-white-70">
              {t("FIND_OUT_MORE_ON_OUR_WEBSITE")}
            </p>

            {/*Modal Checkbox*/}
            <div className="d-flex justify-content-center">
              <div className="p-3 border-1-white w-fit rounded-2">
                <div className="form-check">
                  <input
                      type="checkbox"
                      className="form-check-input"
                      id="customControlInline"
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                  />
                  <label
                      className={`form-check-label font-size-14 m-0 ${isChecked ? 'text-white-70' : 'text-white'}`}
                      htmlFor="customControlInline"
                  >
                    {t("I_HAVE_READ_AND_UNDERSTAND")}
                  </label>
                </div>
              </div>
            </div>

            {/*Modal Button*/}
            <div className="d-flex justify-content-center mt-3 mb-1">
              <button
                  className="btn glass-btn w-lg waves-effect waves-light"
                  type="submit"
                  onClick={handleContinueClick}
              >
                {t("CONTINUE")}
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}


export default Login

// Login.propTypes = {
//   error: PropTypes.any,
//   history: PropTypes.object,
//   loginUser: PropTypes.func,
// }
