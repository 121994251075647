import { post, get } from "../helpers/api_helper";
import * as url from "./url_helper"


export const saveSourceOfFunds = (data) => post(url.POST_SOURCE_OF_FUNDS, data)
export const getSourceOfFunds = (coUserId) => get(url.GET_SOURCE_OF_FUNDS + coUserId)

export const getCurrencyConvert = (data) => post(url.GET_CURRENCY_CONVERT_DATA , data)
export const getSourceOfFundsPurpose = () => get(url.GET_SOURCE_OF_FUNDS_PURPOSE)

// export const saveSourceOfFundsRegisterPayee = (data) => post(url.SAVE_SOURCE_OF_FUNDS_REGISTER_PAYEE, data)
export const saveSourceOfFundsRegisterPayee = (data) => post(url.SAVE_SOURCE_OF_FUNDS_REGISTER_PAYEE, data)
export const saveSourceOfFundsNewPayee = (data) => post(url.SAVE_SOURCE_OF_FUNDS_NEW_PAYEE, data)

export const validateFundTransfer = (data, coUserId) => post(url.VALIDATE_FUND_TRANSFER + coUserId, data)