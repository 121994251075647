import React, { useEffect, useState } from "react";
import * as yup from "yup";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  CardTitle,
  FormGroup,
  Label,
  FormFeedback,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useTranslation } from "react-i18next";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { toast } from "react-toastify";
import FormDropdown from "../../common/formComponents/FormDropdown";
import FormTextField from "../../common/formComponents/FormTextField";
import {
  getPayeeDetailsBypayeeId,
  getPayeeRegisterData,
} from "../../services/PayeeService";
import { useSelector } from "react-redux";
import {
  getCurrencyConvert,
  getSourceOfFundsPurpose,
  saveSourceOfFundsNewPayee,
} from "../../services/SourceOfFunds";
import { getBankCurrency, getCountries } from "../../services/CommonService";
import { useNavigate } from "react-router-dom";
import FormImageUploader from "../../common/formComponents/FormImageUploader";
import { getGlobalAccounts } from "../../services/GlobalAccountsService";
import { getGlobalAccountBalance } from "../../services/CommonService";


const NewPayee = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [registeredPayees, setRegisteredPayees] = useState([]);
  const [selectedPayee, setSelectedPayee] = useState([]);
  const [purposeOfTransfer, setPurposeOfTransfer] = useState([]);
  const [countries, setCountries] = useState([]);
  const [transferMethods, setTransferMethods] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [transferAmount, setTransferAmount] = useState(0);
  const [modal, setModal] = useState(false);
  const [formSubmitData, setFormSubmitData] = useState([])
  const [loading, setLoading] = useState(false)
  const [globalAccounts, setGlobalAccounts] = useState([])
  const [accountBalance, setAccountBalance] = useState("")
  const [selectedAccountCurrency, setSelectedAccountCurrency] = useState(null)
  const [fxWarningMassage, setFxWarningMassage] = useState(false)
  const [fxErrorMassage, setFxErrorMassage] = useState("");
  const [transferFee, setTrasnferFee] = useState(0)
  const [swiftOptions, setSwiftOptions] = useState([
    {label: "SHA", value: "SHARED"},
    {label: "OUR", value: "PAYER"}
  ]);
  const [formFieldConfigsData, setFormFieldConfigsData] = useState([
    {
      country: "Singapore",
      code: "SG",
      currency: "SGD",
      payementMethod: "Local",
      localClearingSystem: "FAST",
      accountRoutingType1: null,
      accountRoutingValue1: null,
      swiftCode: "DBSSSGSG",
    },
    {
      country: "Singapore",
      code: "SG",
      currency: "SGD",
      payementMethod: "Local",
      localClearingSystem: "GIRO",
      accountRoutingType1: null,
      accountRoutingValue1: null,
      swiftCode: "DBSSSGSG",
    },
    {
      country: "Singapore",
      code: "SG",
      currency: "SGD",
      payementMethod: "Local",
      localClearingSystem: "RTGS",
      accountRoutingType1: null,
      accountRoutingValue1: null,
      swiftCode: "DBSSSGSG",
    },

    {
      country: "Hong Kong",
      code: "HK",
      currency: "HKD",
      payementMethod: "Local",
      localClearingSystem: "ACH",
      accountRoutingType1: "bank_code",
      accountRoutingValue1: "024",
    },

    {
      country: "Hong Kong",
      code: "HK",
      currenc: "HKD",
      payementMethod: "Local",
      localClearingSystem: "FPS",
      accountRoutingType1: "bank_code",
      accountRoutingValue1: "024",
    },
    {
      country: "Hong Kong",
      code: "HK",
      currency: "HKD",
      payementMethod: "Local",
      localClearingSystem: "RTGS",
      accountRoutingType1: null,
      accountRoutingValue1: null,
      swiftCode: "DBSSSGSG",
    },

    {
      country: "China",
      code: "CN",
      currency: "CNY",
      payementMethod: "Local",
      localClearingSystem: null,
      accountRoutingType1: "cnaps",
      accountRoutingValue1: "105584000005",
    },

    {
      country: "United Kingdom",
      code: "GB",
      currency: "GBP",
      payementMethod: "Local",
      localClearingSystem: "FASTER_PAYMENTS",
      accountRoutingType1: "sort_code",
      accountRoutingValue1: "609242",
    },

    {
      country: "United Arab Emirates",
      code: "AE",
      currency: "AED",
      payementMethod: "Local",
      localClearingSystem: null,
      accountRoutingType1: null,
      accountRoutingValue1: null,
      iban: "AE070331234567890123456",
    },

    {
      country: "United State",
      code: "US",
      currency: "USD",
      payementMethod: "Local",
      localClearingSystem: "ACH",
      accountRoutingType1: "aba",
      accountRoutingValue1: "021000021",
    },
    {
      country: "United State",
      code: "US",
      currency: "USD",
      payementMethod: "Local",
      localClearingSystem: "FEDWIRE",
      accountRoutingType1: "aba",
      accountRoutingValue1: "021000021",
    },
    {
      country: "United State",
      code: "US",
      currency: "USD",
      payementMethod: "Local",
      localClearingSystem: "PAPER_CHECK",
      accountRoutingType1: null,
      accountRoutingValue1: null,
    },

    {
      country: "Australia",
      code: "AU",
      currency: "AUD",
      payementMethod: "Local",
      localClearingSystem: "BANK_TRANSFER",
      accountRoutingType1: "bsb",
      accountRoutingValue1: "082401",
    },
    {
      country: "Australia",
      code: "AU",
      currency: "AUD",
      payementMethod: "Local",
      localClearingSystem: "BPAY",
      accountRoutingType1: "bpay_biller_code",
      accountRoutingValue1: "123000",
    },

    {
      country: "Japan",
      code: "JP",
      currency: "JPY",
      payementMethod: "Local",
      localClearingSystem: "",
      accountRoutingType1: "bank_code",
      accountRoutingValue1: "0001",
      accountRoutingType2: "branch_code",
      accountRoutingValue2: "001",
    },

    {
      country: "Canada",
      code: "CA",
      currency: "CAD",
      payementMethod: "Local",
      localClearingSystem: "",
      accountRoutingType1: "institution_number",
      accountRoutingValue1: "010",
      accountRoutingType2: "transit_number",
      accountRoutingValue2: "04832",
    },

    {
      country: "India",
      code: "IN",
      currency: "INR",
      payementMethod: "Local",
      localClearingSystem: null,
      accountRoutingType1: "bank_code",
      accountRoutingValue1: "ICIC0001962",
    },
  ]);

  //comment
  const user = useSelector((state) => state.auth.data?.userDTO);
  const isAccountActive = user?.isActive;

  useEffect(() => {
    if (isAccountActive) {
      fetchPurpose();
      fetchCountries();
      fetchBankCurrency();
      fetchCurrentBalances();
    }
  }, [isAccountActive]);

  const fetchCurrentBalances = async () => {
    const coUserId = user?.coUserId;
    try {
      const respons = await getGlobalAccountBalance(coUserId);
      const newResponse = respons.result.map((account) => {
        return {
          value: account?.currency_code,
          label: `${account?.total} ${account?.currency_code}`,
          total: account?.total,
          flag: account?.flag,
        };
      });
      setGlobalAccounts(newResponse);
    } catch (error) {
      //error massage
    }
  };

  const toggle = () => setModal(!modal);

  const closeBtn = (
    <button className="close" onClick={toggle} type="button">
      &times;
    </button>
  );

  const fetchCountries = async () => {
    try {
      const response = await getCountries();
      const data = response.result; // Assuming your response contains the data array

      // Transform data for setCountries
      const countriesData = data.map((country) => ({
        ...country,
        label: country.country_name,
        value: country.country_code, // Assuming you want the value in lowercase
      }));

      setCountries(countriesData);
    } catch (error) {
      toast.error(t("ERROR_FETCH_COUNTRIES"));
    }
  };

  const fetchBankCurrency = async () => {
    try {
      const response = await getBankCurrency();

      let bankCurrency = response.result.map((bankCurrency) => ({
        label: bankCurrency.bank_currency,
        value: bankCurrency.bank_currency_code,
      }));

      setCurrencies(bankCurrency);
    } catch (error) {
      toast.error(t("ERROR_FETCH_BANK_CURRENCY"));
    }
  };

  const fetchPurpose = async () => {
    try {
      const response = await getSourceOfFundsPurpose();
      const data = response.result.map((purpose) => {
        return {
          value: purpose.purpose,
          label: purpose.purpose,
        };
      });
      setPurposeOfTransfer(data);
    } catch (error) {
      toast.error(t("ERROR_FETCH_PAYEE_PURPOSE"));
    }
  };

  const validationSchema = yup.object().shape({
    payee_name: yup.string().required(t("PAYEE_NAME_IS_REQUIRED")),
    bankCountry: yup.string().required(t("BANK_COUNTRY_IS_REQUIRED")),
    accountNumber: yup
      .string()
      .required(t("BANK_ACCOUNT_NUMBER_IS_REQUIRED"))
      .min(8, t("BANK_ACCOUNT_NUMBER_MIN_LENGTH"))
      .matches(/^\d+$/, t("BANK_ACCOUNT_NUMBER_MUST_BE_NUMERIC")),
    transferMethod: yup.string().required(t("TRANSFER_METHOD_IS_REQUIRED")),
    bankCurrency: yup
      .string()
      .required(t("ACCOUNT_CURRENCY_IS_REQUIRED"))
      .nullable(),
    transferOn: yup.string().required(t("TRANSFER_ON_IS_REQUIRED")),
    transferAmount: yup.string().required(t("TRANSFER_AMOUNT_IS_REQUIRED")),
    payAmount: yup
      .number()
      .required(t("PAY_AMOUNT_IS_REQUIRED"))
      .typeError(t("MUST_BE_A_NUMBER"))
      .positive(t("MUST_BE_A_POSITIVE_NUMBER"))
      .min(10, t("PAY_AMOUNT_SHOULD_BE_AT_LEAST_TWO_DIGIT_NUMBER"))
      .max(
        accountBalance,
        t("PAY_AMOUT_MUST_BE_LESS_THAN_OR_QEUAL_TO_AVAILABLE_BALANCE")
      ),

    firstName: yup.string().when("isBusinessPayee", {
      is: (isBusinessPayee) => isBusinessPayee == false,
      then: yup.string().required(t("FIRST_NAME_IS_REQUIRED")),
    }),

    lastName: yup.string().when("isBusinessPayee", {
      is: (isBusinessPayee) => isBusinessPayee == false,
      then: yup.string().required(t("LAST_NAME_IS_REQUIRED")),
    }),

    businessName: yup.string().when("isBusinessPayee", {
      is: (isBusinessPayee) => isBusinessPayee == true,
      then: yup.string().required(t("BUSINESS_NAME_IS_REQUIRED")),
    }),

    nickName: yup.string().required(t("NICK_NAME_IS_REQUIRED")),
    email: yup.string().email(t("EMAIL_MUST_BE_VALID")).required(t("EMAIL_IS_REQUIRED")),
    address: yup.string().required(t("ADDRESS_IS_REQUIRED")),
    postalCode: yup.string().required(t("POSTAL_CODE_IS_REQUIRED")),
    city: yup.string().required(t("CITY_IS_REQUIRED")),
    state: yup.string().required(t("STATE_IS_REQUIRED")),
    legalRepFName: yup.string().when("bankCountry", {
      is: (bankCountry) => bankCountry === "CN",
      then: yup
        .string()
        .required(t("LEGAL_REPRESENTATIVE_FIRST_NAME_IN_CHINESE_LANGUAGE")),
    }),
    legalRepLName: yup.string().when("bankCountry", {
      is: (bankCountry) => bankCountry === "CN",
      then: yup
        .string()
        .required(t("LEGAL_REPRESENTATIVE_LAST_NAME_IN_CHINESE_LANGUAGE")),
    }),
    reference: yup.string().required(t("REFERENCE_NAME_IS_REQUIRED")),
    purpose: yup.string().required(t("PURPOSE_NAME_IS_REQUIRED")),
    accountCurrency: yup
      .string()
      .required(t("CURRENCY_IS_REQUIRED"))
      .nullable(),

    swiftCode: yup.string().when("transferMethod", {
      is: (transferMethod) => transferMethod === "swift",
      then: yup.string().required(t("SWIFT_CODE_IS_REQUIRED")),
    }),
    localSwiftCode: yup.string().when("transferMethod", {
      is: (transferMethod) =>
        transferMethod === "local - fast" ||
        transferMethod === "local - giro" ||
        transferMethod === "local - rtgs",
      then: yup.string().required(t("SWIFT_CODE_IS_REQUIRED")),
    }),
    accountRoutingValue: yup.string().when(["transferMethod", "bankCountry"], {
      is: (transferMethod, bankCountry) =>
        transferMethod === "local - ach" ||
        transferMethod === "local - fps" ||
        (transferMethod === "local" && bankCountry === "CN") ||
        transferMethod === "local - faster_payments" ||
        // transferMethod === "local - paper_check" ||
        transferMethod === "local - fedwire" ||
        transferMethod === "local - bpay" ||
        transferMethod === "local - bank_transfer" ||
        (transferMethod === "local" && bankCountry === "JP") ||
        (transferMethod === "local" && bankCountry === "CA") ||
        (transferMethod === "local" && bankCountry === "IN"),
      then: yup.string().required(t("ACCOUNT_ROUTING_VALUE_IS_REQUIRED")),
    }),
  });

  const onSubmit = async (values) => {
    let localClearVal = "";
    let localClearVal1 = "";

    if (values && values.transferMethod) {
      localClearVal =
        values.transferMethod.split("-")[0].replace(/\s/g, "").toLowerCase()
          .length > 0
          ? values.transferMethod.split("-")[0].replace(/\s/g, "").toLowerCase()
          : "";
    }

    if (values && values.transferMethod) {
      localClearVal1 =
        values?.transferMethod?.split("-")[1]?.replace(/\s/g, "").toLowerCase()
          .length > 0
          ? values?.transferMethod
              ?.split("-")[1]
              ?.replace(/\s/g, "")
              .toLowerCase()
          : "";
    }

    let configData = formFieldConfigsData.find(
      (obj) =>
        obj.code.toLowerCase() === values.bankCountry.toLowerCase() &&
        obj.payementMethod.toLowerCase() === localClearVal &&
        obj.localClearingSystem.toLowerCase() === localClearVal1
    );

    let transferAmout = values.transferAmount.split(" ");

    const data = {
      fundTransfer: {
        fundTransferId: null,
        coUserId: user.coUserId,
        walletId: user.walletsId[0] ? user.walletsId[0] : null,
        payeeId: null,
        paymentAmount: values.payAmount,
        status: "string",
        reason: values.purpose,
        reference: values.reference,
        requestId: null,
        paymentMethod: values.transferMethod.split("-")[0].replace(/\s/g, "").toUpperCase(),
        feeCurrency: values.accountCurrency,
        referenceDocument: values.dirDocument,
        bank_country: values.bankCountry,
        transfer_amount: transferAmout[0],
        transferDate: values.transferOn,
        swiftOption: values.swiftOption
      },
      payee: {
        co_user_id: user.coUserId,
        payee_type: values.isBusinessPayee ? "COMPANY" : "PERSONAL",
        first_name: values.firstName,
        last_name: values.lastName,
        bank_code_type: values.bankCountry.toUpperCase(),
        bank_code: "b0003",
        bank_name: "BOC",
        branch_name: "USA",
        account_number: values.accountNumber,
        account_name: "John Walker",
        bank_country: values.bankCountry.toUpperCase(),
        bank_currency: values.bankCurrency.toUpperCase(),
        nick_name: values.nickName,
        email: values.email,
        id_issued_in: "acsds",
        id_type: "id_type",
        id_number: "123",
        id_image: "",
        br_number: "1",
        cnpas: "cnatring",
        is_active: true,
        is_verified: false,
        company_name: values.businessName,
        // swift_code: values.swiftCode,
        //payment_methods: [values.transferMethod.toUpperCase()],
        //local_clearing_system: "ACH",
        legal_rep_last_name: values.legalRepLName,
        legal_rep_first_name: values.legalRepFName,
        swift_code: values.swiftCode
          ? values.swiftCode
          : values.localSwiftCode
          ? values.localSwiftCode
          : null,
        account_routing_type1: configData?.accountRoutingType1
          ? configData.accountRoutingType1
          : null,
        account_routing_value1: values.accountRoutingValue
          ? values.accountRoutingValue
          : null,
        local_clearing_system: configData?.localClearingSystem
          ? configData.localClearingSystem.toUpperCase()
          : null,
        payment_methods: [
          values.transferMethod.split("-")[0].replace(/\s/g, "").toUpperCase(),
        ],
        addressList: [
          {
            address_type: "BANK",
            address_line1: values.address,
            address_line2: "",
            address_line3: "",
            city_province: values.city,
            street: "my streat",
            postal_code: values.postalCode,
            state: values.state,
          },
        ],
      },
      registerPayee: false, //false for not create a new payee
    };

    setFormSubmitData(data);
    setModal(true);
  };

  const formSubmitManually = async (value) => {
    const data = { ...formSubmitData, registerPayee: value };
    setLoading(true);
    try {
      const response = await saveSourceOfFundsNewPayee(data);
      setLoading(false);
      toast.success(t("FUNDS_TRANSFER_TO_NEW_PAYEE_SUCCESS"));
      navigate("/payee-transaction-history");
    } catch (error) {
      if (error.code === "ERR_NETWORK") {
        toast.error(`Error: ${error.message}`);
      } else if (error.response.data.errorType === "Airwallex side failure!") {
        let errorMessage = error.response.data.result.error.errors[0].source
          ? error.response.data.result.error.errors[0].source
          : error.response.data.result.error.errors[0].code;

        if (errorMessage && errorMessage.includes("beneficiary.address.")) {
          errorMessage = errorMessage.replace("beneficiary.address.", "");
        }

        if (
          errorMessage &&
          errorMessage.includes("beneficiary.bank_details.")
        ) {
          errorMessage = errorMessage.replace("beneficiary.bank_details.", "");
        }

        toast.error(`Error: ${errorMessage}`);
      } else {
        toast.error(`Error: ${error.response.data.result.error}`);
      }
      setLoading(false);
      //toast.error(t("ERROR_SOURCE_OF_FUNDS_NEW_PAYEE"))
    }
  };

  // const showAvailableBalance = async (value, form) => {
  //   const getAccount = globalAccounts.find( account => account?.value === value)
  //   setAccountBalance(getAccount?.balance)
  //   setSelectedAccountCurrency(getAccount?.currency)
  //   form.setFieldValue("globalAccount", value);
  // };

  const showAvailableBalance = async (value, form) => {
    const getAccount = globalAccounts.find(
      (account) => account?.value === value
    );
    const selectedCurrencyTotal = getAccount?.total;

    if (value == "Select an option") {
      setAccountBalance(null);
      setSelectedAccountCurrency(null);
      form.setFieldValue("accountCurrency", null);
    } else if (
      form.values.payAmount > 0 &&
      form.values.payAmount <= selectedCurrencyTotal
    ) {
      setAccountBalance(getAccount?.total);
      setSelectedAccountCurrency(getAccount?.value);
      form.setFieldValue("accountCurrency", value);
      calculateTransferAmount(form.values.payAmount, form);
    } else {
      setAccountBalance(getAccount?.total);
      setSelectedAccountCurrency(getAccount?.value);
      form.setFieldValue("accountCurrency", value);
    }
  };



  const calculateTransferAmount = async (value, form) => {
    setTrasnferFee(0)
    if (form.values.bankCurrency === "") {
      form.setFieldValue("payAmount", value);
      form.setFieldValue("transferAmount", "");
      return;
    }

    let swiftOpt = form.values?.swiftOption == 'SHARED' ? 'SHA' : form.values?.swiftOption === 'PAYER' ? 'OUR' : undefined
    let paymentMethod = getPaymentValue(form.values?.transferMethod);
    paymentMethod = paymentMethod === 'SWIFT' && swiftOpt ? paymentMethod + ' - ' + swiftOpt : paymentMethod
    
    if (value <= accountBalance) {
      const availbleBalance = globalAccounts.find( account => account?.value === form.values.accountCurrency)

      const data = {
        buy_amount: value,
        buy_currency: selectedAccountCurrency,
        sell_currency: form.values?.bankCurrency,
        isFx:
          selectedAccountCurrency == form.values?.bankCurrency
            ? false
            : true,
        accountBalance: availbleBalance?.total,
        transferFee: {
          country: form.values?.bankCountry,
          currency: form.values?.bankCurrency,
          paymentMethod: paymentMethod,
          type: "RATES",
        },
      };

      setFxErrorMassage("");
      setFxWarningMassage(
        selectedAccountCurrency == form.values?.bankCurrency ? false : true
      );

      try {
        if (value > 10) {
          const response = await getCurrencyConvert(data);
          const convertedAmount = response.result?.transferAmount;
          form.setFieldValue("transferAmount", `${convertedAmount} ${form.values.bankCurrency}`);
          setTrasnferFee(response.result?.fee)
        }
      } catch (error) {
        if (error?.response?.data?.result?.error) {
          setFxErrorMassage(error?.response?.data?.result?.error);
        }
        form.setFieldValue("transferAmount", "");
      }

      form.setFieldValue("payAmount", value);

      // if (selectedAccountCurrency == form.values?.bankCurrency) {
      //   setFxWarningMassage(false)
      //   form.setFieldValue("payAmount", value);
      //   form.setFieldValue("transferAmount", value);
      // } else {
      //   const data = {
      //     buy_amount: value,
      //     buy_currency: selectedAccountCurrency,
      //     sell_currency: form.values?.bankCurrency,
      //   };

      //   try {
      //     if (value > 10) {
      //       setFxWarningMassage(true)
      //       const response = await getCurrencyConvert(data);
      //       const convertedAmount = response.result.rate_details[0].sell_amount;
      //       form.setFieldValue("transferAmount", convertedAmount);
      //     }
      //   } catch (error) {
      //     form.setFieldValue("transferAmount", "");
      //   }
      //   form.setFieldValue("payAmount", value);
      // }
    } else {
      form.setFieldValue("payAmount", value);
      form.setFieldValue("transferAmount", "");
    }
  };

  const changeCountry = async (value, form) => {
    const selectedCountry = countries.find(
      (country) => country.country_code == value
    );
    const transferOfMethodsData = selectedCountry.source_currency.map(
      (method) => {
        return {
          value: method.toLowerCase(),
          label: method.toUpperCase(),
        };
      }
    );
    setTransferMethods(transferOfMethodsData);
    form.setFieldValue("bankCountry", value);
    form.setFieldValue("swiftCode", "");
    form.setFieldValue("localSwiftCode", "");
    form.setFieldValue("accountRoutingValue", "");
  };

  const onChangeBankCurrency = (value, form) => {
    form.setFieldValue("payAmount", "");
    form.setFieldValue("accountCurrency", "");
    form.setFieldValue("transferAmount", "");
    setAccountBalance(0);
    if (value == "Select an option") {
      form.setFieldValue("bankCurrency", "");
    } else {
      form.setFieldValue("bankCurrency", value);
    }
  };

  const changeTransferMethods = (value, form) => {
    form.setFieldValue("transferMethod", value);
    form.setFieldValue("swiftCode", "");
    form.setFieldValue("localSwiftCode", "");
    form.setFieldValue("accountRoutingValue", "");
  };

  function getPaymentValue(paymentMethod) {
    if (paymentMethod.startsWith("local - ")) {
      return paymentMethod.split(" - ")[1].toUpperCase();
    } else {
      return paymentMethod.toUpperCase();
    }
  }

  document.title = t("FUNDS_TRANSFER_TPS_SOLUTIONS");

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            maintitle={t('TPS_SOLUTIONS')}
            title={t("FUNDS_TRANSFER")}
            breadcrumbItem={t("FUNDS_TRANSFER_TO_NEW_PAYEE")}
            mainTitleLink="/"
            titleLink="/funds-transfer"
          />

          <Row>
            <Col md={12}>
              {/*<Card>*/}
              {/*  <CardBody>*/}
              {/*<CardTitle className="h4 font-size-18">Linked Source Of Funds</CardTitle>*/}

              <Row className="mt-0">
                <Formik
                  enableReinitialize
                  initialValues={{
                    payee_name: "",
                    bankCountry: "",
                    accountNumber: "",
                    // accountCurrency : selectedPayee ? selectedPayee?.bank_currency : "",
                    transferMethod: "",
                    bankCurrency: null,
                    payAmount: "",
                    transferOn: "",
                    transferAmount: "",
                    purpose: "",
                    reference: "",
                    description: "",
                    isBusinessPayee: false,
                    firstName: "",
                    lastName: "",
                    nickName: "",
                    businessName: "",
                    email: "",
                    address: "",
                    city: "",
                    state: "",
                    postalCode: "",
                    legalRepFName: "",
                    legalRepLName: "",
                    swiftCode: "",
                    dirDocument: "",
                    accountCurrency: "",
                    localSwiftCode: "",
                    accountRoutingValue: "",
                    swiftOption: "SHARED",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  {({ isSubmitting, values, setFieldValue }) => (
                    <Row className="d-flex justify-content-start">
                      <Col md={10} xl={8}>
                        <Form>
                          <Col md={12} className="">
                            <Card>
                              <CardBody>
                                <Row>
                                  <Col md={24}>
                                    <h5
                                      style={{
                                        marginBottom: "20px",
                                      }}
                                    >
                                      {t("PAYEE_DETAILS")}
                                    </h5>
                                  </Col>

                                  <Col md={6} xl={6}>
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="payee_name">
                                        {t("PAYEE_NAME")}
                                        <sup className="text-danger"> *</sup>
                                      </Label>
                                      <FormTextField
                                        name="payee_name"
                                        id="payee_name"
                                        placeholder={t("PAYEE_NAME")}
                                        disabled={
                                          isAccountActive ? false : true
                                        }
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={6} xl={6}>
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="bankCountry">
                                        {t("BANK_COUNTRY")}
                                        <sup className="text-danger"> *</sup>
                                      </Label>
                                      <FormDropdown
                                        name="bankCountry"
                                        id="bankCountry"
                                        placeholder={t("BANK_COUNTRY")}
                                        optionsValues={countries}
                                        changeDropdown={changeCountry}
                                        setFieldValue={setFieldValue}
                                        disabled={
                                          isAccountActive ? false : true
                                        }
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={6} xl={6}>
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="accountNumber">
                                        {t("ACCOUNT_NUMBER")}
                                        <sup className="text-danger"> *</sup>
                                      </Label>

                                      <FormTextField
                                        type="text"
                                        name="accountNumber"
                                        id="accountNumber"
                                        placeholder={t("ACCOUNT_NUMBER")}
                                        disabled={
                                          isAccountActive ? false : true
                                        }
                                      />
                                    </FormGroup>
                                  </Col>

                                  <Col md={6} xl={6}>
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="transferMethod">
                                        {t("TRANSFER_METHOD")}
                                        <sup className="text-danger"> *</sup>
                                      </Label>
                                      <FormDropdown
                                        name="transferMethod"
                                        id="transferMethod"
                                        placeholder={t("TRANSFER_METHOD")}
                                        optionsValues={transferMethods}
                                        changeDropdown={changeTransferMethods}
                                        // changeDropdown={changePayee}
                                        // setFieldValue={setFieldValue}
                                        disabled={
                                          isAccountActive ? false : true
                                        }
                                      />
                                    </FormGroup>
                                  </Col>

                                  {values.transferMethod == "swift" ? (
                                    <span>
                                      <Col md={6} xl={6}>
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="swiftCode">
                                          {t("SWIFT_CODE")}
                                          <sup className="text-danger"> *</sup>
                                        </Label>
                                        <FormTextField
                                          name="swiftCode"
                                          id="swiftCode"
                                          placeholder={t("SWIFT_CODE")}
                                          disabled={
                                            isAccountActive ? false : true
                                          }
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col md={6} xl={6}>
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="swiftOption">
                                        {t("SWIFT_OPTION")}
                                        <sup className="text-danger"> *</sup>
                                      </Label>
                                      <FormDropdown
                                        name="swiftOption"
                                        id="swiftOption"
                                        placeholder={t("SWIFT_OPTION")}
                                        optionsValues={swiftOptions}
                                        changeDropdown={changeCountry}
                                        setFieldValue={setFieldValue}
                                        disabled={
                                          isAccountActive ? false : true
                                        }
                                      />
                                    </FormGroup>
                                  </Col>
                                    </span>
                                  ) : (
                                    ""
                                  )}

                                  <Col md={6} xl={6}>
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="bankCurrency">
                                        {t("BANK_CURRENCY")}
                                        <sup className="text-danger"> *</sup>
                                      </Label>
                                      <FormDropdown
                                        name="bankCurrency"
                                        id="bankCurrency"
                                        placeholder={t("BANK_CURRENCY")}
                                        optionsValues={currencies}
                                        changeDropdown={onChangeBankCurrency}
                                        // setFieldValue={setFieldValue}
                                        disabled={
                                          isAccountActive ? false : true
                                        }
                                      />
                                    </FormGroup>
                                  </Col>

                                  {/* -------------------- Singapore ---------------------------------------------- */}

                                  {values?.bankCountry &&
                                  values?.transferMethod &&
                                  typeof values?.transferMethod === "string" &&
                                  values?.transferMethod.includes("-") ? (
                                    values?.bankCountry === "SG" &&
                                    values?.transferMethod?.split("-").length >
                                      0 &&
                                    values?.transferMethod
                                      ?.split("-")[0]
                                      .replace(/\s/g, "") === "local" &&
                                    values?.transferMethod
                                      ?.split("-")[1]
                                      .replace(/\s/g, "") === "fast" ? (
                                      <Col md={6} xl={6}>
                                        <FormGroup className="mb-3">
                                          <Label htmlFor="localSwiftCode">
                                            {t("SWIFT_CODE")}
                                            <sup className="text-danger">
                                              {" "}
                                              *
                                            </sup>
                                          </Label>
                                          <FormTextField
                                            name="localSwiftCode"
                                            id="localSwiftCode"
                                            placeholder={t("SWIFT_CODE")}
                                            disabled={
                                              isAccountActive ? false : true
                                            }
                                          />
                                        </FormGroup>
                                      </Col>
                                    ) : null
                                  ) : null}

                                  {values?.bankCountry &&
                                  values?.transferMethod &&
                                  typeof values?.transferMethod === "string" &&
                                  values?.transferMethod.includes("-") ? (
                                    values?.bankCountry === "SG" &&
                                    values?.transferMethod?.split("-").length >
                                      0 &&
                                    values?.transferMethod
                                      ?.split("-")[0]
                                      .replace(/\s/g, "") === "local" &&
                                    values?.transferMethod
                                      ?.split("-")[1]
                                      .replace(/\s/g, "") === "giro" ? (
                                      <Col md={6} xl={6}>
                                        <FormGroup className="mb-3">
                                          <Label htmlFor="localSwiftCode">
                                            {t("SWIFT_CODE")}
                                            <sup className="text-danger">
                                              {" "}
                                              *
                                            </sup>
                                          </Label>
                                          <FormTextField
                                            name="localSwiftCode"
                                            id="localSwiftCode"
                                            placeholder={t("SWIFT_CODE")}
                                            disabled={
                                              isAccountActive ? false : true
                                            }
                                          />
                                        </FormGroup>
                                      </Col>
                                    ) : null
                                  ) : null}

                                  {values?.bankCountry &&
                                  values?.transferMethod &&
                                  typeof values?.transferMethod === "string" &&
                                  values?.transferMethod.includes("-") ? (
                                    values?.bankCountry === "SG" &&
                                    values?.transferMethod?.split("-").length >
                                      0 &&
                                    values?.transferMethod
                                      ?.split("-")[0]
                                      .replace(/\s/g, "") === "local" &&
                                    values?.transferMethod
                                      ?.split("-")[1]
                                      .replace(/\s/g, "") === "rtgs" ? (
                                      <Col md={6} xl={6}>
                                        <FormGroup className="mb-3">
                                          <Label htmlFor="localSwiftCode">
                                            {t("SWIFT_CODE")}
                                            <sup className="text-danger">
                                              {" "}
                                              *
                                            </sup>
                                          </Label>
                                          <FormTextField
                                            name="localSwiftCode"
                                            id="localSwiftCode"
                                            placeholder={t("SWIFT_CODE")}
                                            disabled={
                                              isAccountActive ? false : true
                                            }
                                          />
                                        </FormGroup>
                                      </Col>
                                    ) : null
                                  ) : null}

                                  {/* --------------------  Hong Kong ---------------------------------------------- */}

                                  {values?.bankCountry &&
                                  values?.transferMethod &&
                                  typeof values?.transferMethod === "string" &&
                                  values?.transferMethod.includes("-") ? (
                                    values?.bankCountry === "HK" &&
                                    values?.transferMethod.split("-").length >
                                      0 &&
                                    values?.transferMethod
                                      ?.split("-")[0]
                                      .replace(/\s/g, "") === "local" &&
                                    values?.transferMethod
                                      ?.split("-")[1]
                                      .replace(/\s/g, "") === "ach" ? (
                                      <Col md={6} xl={6}>
                                        <FormGroup className="mb-3">
                                          <Label htmlFor="accountRoutingValue">
                                            {t("ACCOUNT_ROUTING_VALUE")}
                                            <sup className="text-danger">
                                              {" "}
                                              *
                                            </sup>
                                          </Label>
                                          <FormTextField
                                            name="accountRoutingValue"
                                            id="accountRoutingValue"
                                            placeholder={t(
                                              "ACCOUNT_ROUTING_VALUE"
                                            )}
                                            disabled={
                                              isAccountActive ? false : true
                                            }
                                          />
                                        </FormGroup>
                                      </Col>
                                    ) : null
                                  ) : null}

                                  {values?.bankCountry &&
                                  values?.transferMethod &&
                                  typeof values?.transferMethod === "string" &&
                                  values?.transferMethod.includes("-") ? (
                                    values?.bankCountry === "HK" &&
                                    values?.transferMethod?.split("-").length >
                                      0 &&
                                    values?.transferMethod
                                      ?.split("-")[0]
                                      .replace(/\s/g, "") === "local" &&
                                    values?.transferMethod
                                      ?.split("-")[1]
                                      .replace(/\s/g, "") === "fps" ? (
                                      <Col md={6} xl={6}>
                                        <FormGroup className="mb-3">
                                          <Label htmlFor="accountRoutingValue">
                                            {t("ACCOUNT_ROUTING_VALUE")}
                                            <sup className="text-danger">
                                              {" "}
                                              *
                                            </sup>
                                          </Label>
                                          <FormTextField
                                            name="accountRoutingValue"
                                            id="accountRoutingValue"
                                            placeholder={t(
                                              "ACCOUNT_ROUTING_VALUE"
                                            )}
                                            disabled={
                                              isAccountActive ? false : true
                                            }
                                          />
                                        </FormGroup>
                                      </Col>
                                    ) : null
                                  ) : null}

                                  {values?.bankCountry &&
                                  values?.transferMethod &&
                                  typeof values?.transferMethod === "string" &&
                                  values?.transferMethod.includes("-") ? (
                                    values?.bankCountry === "HK" &&
                                    values?.transferMethod?.split("-").length >
                                      0 &&
                                    values?.transferMethod
                                      ?.split("-")[0]
                                      .replace(/\s/g, "") === "local" &&
                                    values?.transferMethod
                                      ?.split("-")[1]
                                      .replace(/\s/g, "") === "rtgs" ? (
                                      <Col md={6} xl={6}>
                                        <FormGroup className="mb-3">
                                          <Label htmlFor="localSwiftCode">
                                            {t("SWIFT_CODE")}
                                            <sup className="text-danger">
                                              {" "}
                                              *
                                            </sup>
                                          </Label>
                                          <FormTextField
                                            name="localSwiftCode"
                                            id="localSwiftCode"
                                            placeholder={t("SWIFT_CODE")}
                                            disabled={
                                              isAccountActive ? false : true
                                            }
                                          />
                                        </FormGroup>
                                      </Col>
                                    ) : null
                                  ) : null}

                                  {/* --------------------  China ---------------------------------------------- */}

                                  {values?.bankCountry &&
                                  values?.transferMethod &&
                                  typeof values?.transferMethod === "string" ? (
                                    values?.bankCountry === "CN" &&
                                    values?.transferMethod.length > 0 &&
                                    values?.transferMethod === "local" ? (
                                      <Col md={6} xl={6}>
                                        <FormGroup className="mb-3">
                                          <Label htmlFor="accountRoutingValue">
                                            {t("ACCOUNT_ROUTING_VALUE")}
                                            <sup className="text-danger">
                                              {" "}
                                              *
                                            </sup>
                                          </Label>
                                          <FormTextField
                                            name="accountRoutingValue"
                                            id="accountRoutingValue"
                                            placeholder={t(
                                              "ACCOUNT_ROUTING_VALUE"
                                            )}
                                            disabled={
                                              isAccountActive ? false : true
                                            }
                                          />
                                        </FormGroup>
                                      </Col>
                                    ) : null
                                  ) : null}

                                  {/* --------------------  United Kingdom ---------------------------------------------- */}

                                  {values?.bankCountry &&
                                  values?.transferMethod &&
                                  typeof values?.transferMethod === "string" &&
                                  values?.transferMethod.includes("-") ? (
                                    values?.bankCountry === "GB" &&
                                    values?.transferMethod.split("-").length >
                                      0 &&
                                    values?.transferMethod
                                      ?.split("-")[0]
                                      .replace(/\s/g, "") === "local" &&
                                    values?.transferMethod
                                      ?.split("-")[1]
                                      .replace(/\s/g, "") ===
                                      "faster_payments" ? (
                                      <Col md={6} xl={6}>
                                        <FormGroup className="mb-3">
                                          <Label htmlFor="accountRoutingValue">
                                            {t("ACCOUNT_ROUTING_VALUE")}
                                            <sup className="text-danger">
                                              {" "}
                                              *
                                            </sup>
                                          </Label>
                                          <FormTextField
                                            name="accountRoutingValue"
                                            id="accountRoutingValue"
                                            placeholder={t(
                                              "ACCOUNT_ROUTING_VALUE"
                                            )}
                                            disabled={
                                              isAccountActive ? false : true
                                            }
                                          />
                                        </FormGroup>
                                      </Col>
                                    ) : null
                                  ) : null}

                                  {/* --------------------  United State ---------------------------------------------- */}

                                  {values?.bankCountry &&
                                  values?.transferMethod &&
                                  typeof values?.transferMethod === "string" &&
                                  values?.transferMethod.includes("-") ? (
                                    values?.bankCountry === "US" &&
                                    values?.transferMethod?.split("-").length >
                                      0 &&
                                    values?.transferMethod
                                      ?.split("-")[0]
                                      .replace(/\s/g, "") === "local" &&
                                    values?.transferMethod
                                      ?.split("-")[1]
                                      .replace(/\s/g, "") === "ach" ? (
                                      <Col md={6} xl={6}>
                                        <FormGroup className="mb-3">
                                          <Label htmlFor="accountRoutingValue">
                                            {t("ACCOUNT_ROUTING_VALUE")}
                                            <sup className="text-danger">
                                              {" "}
                                              *
                                            </sup>
                                          </Label>
                                          <FormTextField
                                            name="accountRoutingValue"
                                            id="accountRoutingValue"
                                            placeholder={t(
                                              "ACCOUNT_ROUTING_VALUE"
                                            )}
                                            disabled={
                                              isAccountActive ? false : true
                                            }
                                          />
                                        </FormGroup>
                                      </Col>
                                    ) : null
                                  ) : null}

                                  {values?.bankCountry &&
                                  values?.transferMethod &&
                                  typeof values?.transferMethod === "string" &&
                                  values?.transferMethod.includes("-") ? (
                                    values?.bankCountry === "US" &&
                                    values?.transferMethod?.split("-").length >
                                      0 &&
                                    values?.transferMethod
                                      ?.split("-")[0]
                                      .replace(/\s/g, "") === "local" &&
                                    values?.transferMethod
                                      ?.split("-")[1]
                                      .replace(/\s/g, "") === "fedwire" ? (
                                      <Col md={6} xl={6}>
                                        <FormGroup className="mb-3">
                                          <Label htmlFor="accountRoutingValue">
                                            {t("ACCOUNT_ROUTING_VALUE")}
                                            <sup className="text-danger">
                                              {" "}
                                              *
                                            </sup>
                                          </Label>
                                          <FormTextField
                                            name="accountRoutingValue"
                                            id="accountRoutingValue"
                                            placeholder={t(
                                              "ACCOUNT_ROUTING_VALUE"
                                            )}
                                            disabled={
                                              isAccountActive ? false : true
                                            }
                                          />
                                        </FormGroup>
                                      </Col>
                                    ) : null
                                  ) : null}

                                  {/*{*/}
                                  {/*    (values?.bankCountry && values?.transferMethod && typeof values?.transferMethod === 'string' && values?.transferMethod.includes("-")) ? (*/}
                                  {/*        (values?.bankCountry === "US" && values?.transferMethod?.split("-").length > 0 && values?.transferMethod?.split("-")[0].replace(/\s/g, '') === "local" && values?.transferMethod?.split("-")[1].replace(/\s/g, '') === "paper_check") ? (*/}
                                  {/*            <Col md={6} xl={6}>*/}
                                  {/*                <FormGroup className="mb-3">*/}
                                  {/*                    <Label htmlFor="accountRoutingValue">*/}
                                  {/*                        {t("ACCOUNT_ROUTING_VALUE")}*/}
                                  {/*                        <sup*/}
                                  {/*                            className="text-danger"> *</sup>*/}
                                  {/*                    </Label>*/}
                                  {/*                    <FormTextField*/}
                                  {/*                        name="accountRoutingValue"*/}
                                  {/*                        id="accountRoutingValue"*/}
                                  {/*                        placeholder={t("ACCOUNT_ROUTING_VALUE")}*/}
                                  {/*                        disabled={isAccountActive ? false : true}*/}
                                  {/*                    />*/}
                                  {/*                </FormGroup>*/}
                                  {/*            </Col>*/}
                                  {/*            ) : null*/}

                                  {/*        ):*/}
                                  {/*        null*/}
                                  {/*}*/}

                                  {/* --------------------  Australia ---------------------------------------------- */}

                                  {values?.bankCountry &&
                                  values?.transferMethod &&
                                  typeof values?.transferMethod === "string" &&
                                  values?.transferMethod.includes("-") ? (
                                    values?.bankCountry === "AU" &&
                                    values?.transferMethod?.split("-").length >
                                      0 &&
                                    values?.transferMethod
                                      ?.split("-")[0]
                                      .replace(/\s/g, "") === "local" &&
                                    values?.transferMethod
                                      ?.split("-")[1]
                                      .replace(/\s/g, "") === "bpay" ? (
                                      <Col md={6} xl={6}>
                                        <FormGroup className="mb-3">
                                          <Label htmlFor="accountRoutingValue">
                                            {t("ACCOUNT_ROUTING_VALUE")}
                                            <sup className="text-danger">
                                              {" "}
                                              *
                                            </sup>
                                          </Label>
                                          <FormTextField
                                            name="accountRoutingValue"
                                            id="accountRoutingValue"
                                            placeholder={t(
                                              "ACCOUNT_ROUTING_VALUE"
                                            )}
                                            disabled={
                                              isAccountActive ? false : true
                                            }
                                          />
                                        </FormGroup>
                                      </Col>
                                    ) : null
                                  ) : null}

                                  {values?.bankCountry &&
                                  values?.transferMethod &&
                                  typeof values?.transferMethod === "string" &&
                                  values?.transferMethod.includes("-") ? (
                                    values?.bankCountry === "AU" &&
                                    values.transferMethod.split("-").length >
                                      0 &&
                                    values?.transferMethod
                                      ?.split("-")[0]
                                      .replace(/\s/g, "") === "local" &&
                                    values?.transferMethod
                                      ?.split("-")[1]
                                      .replace(/\s/g, "") ===
                                      "bank_transfer" ? (
                                      <Col md={6} xl={6}>
                                        <FormGroup className="mb-3">
                                          <Label htmlFor="accountRoutingValue">
                                            {t("ACCOUNT_ROUTING_VALUE")}
                                            <sup className="text-danger">
                                              {" "}
                                              *
                                            </sup>
                                          </Label>
                                          <FormTextField
                                            name="accountRoutingValue"
                                            id="accountRoutingValue"
                                            placeholder={t(
                                              "ACCOUNT_ROUTING_VALUE"
                                            )}
                                            disabled={
                                              isAccountActive ? false : true
                                            }
                                          />
                                        </FormGroup>
                                      </Col>
                                    ) : null
                                  ) : null}

                                  {/* --------------------  Japan ---------------------------------------------- */}

                                  {values?.bankCountry &&
                                  values?.transferMethod &&
                                  typeof values?.transferMethod === "string" ? (
                                    values?.bankCountry === "JP" &&
                                    values?.transferMethod.length > 0 &&
                                    values?.transferMethod === "local" ? (
                                      <Col md={6} xl={6}>
                                        <FormGroup className="mb-3">
                                          <Label htmlFor="accountRoutingValue">
                                            {t("ACCOUNT_ROUTING_VALUE")}
                                            <sup className="text-danger">
                                              {" "}
                                              *
                                            </sup>
                                          </Label>
                                          <FormTextField
                                            name="accountRoutingValue"
                                            id="accountRoutingValue"
                                            placeholder={t(
                                              "ACCOUNT_ROUTING_VALUE"
                                            )}
                                            disabled={
                                              isAccountActive ? false : true
                                            }
                                          />
                                        </FormGroup>
                                      </Col>
                                    ) : null
                                  ) : null}

                                  {/* --------------------  Canada ---------------------------------------------- */}

                                  {values?.bankCountry &&
                                  values?.transferMethod &&
                                  typeof values?.transferMethod === "string" ? (
                                    values?.bankCountry === "CA" &&
                                    values?.transferMethod.length > 0 &&
                                    values?.transferMethod === "local" ? (
                                      <Col md={6} xl={6}>
                                        <FormGroup className="mb-3">
                                          <Label htmlFor="accountRoutingValue">
                                            {t("ACCOUNT_ROUTING_VALUE")}
                                            <sup className="text-danger">
                                              {" "}
                                              *
                                            </sup>
                                          </Label>
                                          <FormTextField
                                            name="accountRoutingValue"
                                            id="accountRoutingValue"
                                            placeholder={t(
                                              "ACCOUNT_ROUTING_VALUE"
                                            )}
                                            disabled={
                                              isAccountActive ? false : true
                                            }
                                          />
                                        </FormGroup>
                                      </Col>
                                    ) : null
                                  ) : null}

                                  {/* --------------------  India ---------------------------------------------- */}

                                  {values?.bankCountry &&
                                  values?.transferMethod &&
                                  typeof values?.transferMethod === "string" ? (
                                    values?.bankCountry === "IN" &&
                                    values?.transferMethod.length > 0 &&
                                    values?.transferMethod === "local" ? (
                                      <Col md={6} xl={6}>
                                        <FormGroup className="mb-3">
                                          <Label htmlFor="accountRoutingValue">
                                            {t("ACCOUNT_ROUTING_VALUE")}
                                            <sup className="text-danger">
                                              {" "}
                                              *
                                            </sup>
                                          </Label>
                                          <FormTextField
                                            name="accountRoutingValue"
                                            id="accountRoutingValue"
                                            placeholder={t(
                                              "ACCOUNT_ROUTING_VALUE"
                                            )}
                                            disabled={
                                              isAccountActive ? false : true
                                            }
                                          />
                                        </FormGroup>
                                      </Col>
                                    ) : null
                                  ) : null}
                                </Row>
                              </CardBody>
                            </Card>
                            <Card>
                              <CardBody>
                                <Row>
                                  <Col md={24}>
                                    <h5
                                      style={{
                                        marginBottom: "20px",
                                      }}
                                    >
                                      {t("TRANSFER_DETAILS")}
                                    </h5>
                                  </Col>
                                  <Col md={6} xl={6}>
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="payAmount">
                                        {t("SELECT_ACCOUNT")}
                                        <sup className="text-danger"> *</sup>
                                      </Label>
                                      <FormDropdown
                                        name="accountCurrency"
                                        id="accountCurrency"
                                        optionsValues={globalAccounts}
                                        disabled={
                                          isAccountActive &&
                                          values.bankCurrency != null
                                            ? false
                                            : true
                                        }
                                        placeholder={t("SELECT_ACCOUNT")}
                                        changeDropdown={showAvailableBalance}
                                      />
                                    </FormGroup>
                                  </Col>

                                  <Col md={6} xl={6}>
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="payAmount">
                                        {t("AMOUNT_YOU_PAY")}
                                        <sup className="text-danger"> *</sup>
                                      </Label>
                                      <FormTextField
                                        name="payAmount"
                                        id="payAmount"
                                        type="number"
                                        placeholder={t("AMOUNT_YOU_PAY")}
                                        changeInput={calculateTransferAmount}
                                        disabled={
                                          isAccountActive &&
                                          values.bankCurrency != null
                                            ? false
                                            : true
                                        }
                                      />

                                      <h6 style={{ textAlign: "center" }}>
                                        {t("AVAILABLE_BALANCE")} :{" "}
                                        {accountBalance ? accountBalance : "0"}{" "}
                                        {values?.accountCurrency
                                          ? ` - ${values?.accountCurrency}`
                                          : ""}
                                        </h6>
                                        {fxErrorMassage ? (
                                        <span style={{ color: "#cc3300" }}>
                                          {fxErrorMassage}
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                      </FormGroup>
                                    </Col>
                                  
                                    <Col md={6} xl={6}>
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="transferOn">
                                          {t("TRANSFER_ON")}
                                          <sup className="text-danger"> *</sup>
                                        </Label>
                                        <FormTextField
                                            type="date"
                                            name="transferOn"
                                            placeholder={t("TRANSFER_ON")}
                                            id="transferOn "
                                            disabled={isAccountActive ? false : true}
                                            disablePastDate={true}
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col md={6} xl={6}>
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="transferFees">
                                          {t("TRANSFER_FEE")}
                                          <sup className="text-danger"> *</sup>
                                        </Label>
                                        {/* <h6>{t("1_SGD_014_USD")}</h6> */}
                                        { transferFee && transferFee} { transferFee && transferFee > 0 ? values.accountCurrency : ""}
                                      </FormGroup>
                                    </Col>
                                    <Col md={6} xl={6}>
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="email">
                                          {t("TRANSFER_AMOUNT")}
                                          <sup className="text-danger"> *</sup>
                                        </Label>
                                        <FormTextField
                                            disabled={true}
                                            placeholder={t("TRANSFER_AMOUNT")}
                                            name="transferAmount"
                                            id="transferAmount"
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col md={6} xl={6}>
                                    <FormGroup className="mb-3" style={{ marginTop : "25px" }}>
                                      {/* <Label htmlFor="email">
                                        {t("TRANSFER_AMOUNT")}
                                        <sup className="text-danger"> *</sup>
                                      </Label> */}
                                      {fxWarningMassage ? (
                                        <span style={{ color: "#cc3300" }}>
                                          Transferred amount may vary due to
                                          currency exchange rates.
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                            <Card>
                              <CardBody>
                                <Row>
                                  <Col md={24}>
                                    <h5
                                      style={{
                                        marginBottom: "20px",
                                      }}
                                    >
                                      {t("ADDITIONAL_DETAILS")}
                                    </h5>
                                  </Col>
                                  <Col md={12} lg={12} xl={12}>
                                    <FormGroup className="mb-3" switch>
                                      <FormTextField
                                        name="isBusinessPayee"
                                        id="isBusinessPayee"
                                        type="switch"
                                      />
                                      <Label htmlFor="isBusinessPayee">
                                        {t("BUSINESS_PAYEE")}
                                      </Label>
                                    </FormGroup>
                                  </Col>
                                  {values.isBusinessPayee ? (
                                    <>
                                      <Col md={6} xl={6}>
                                        <FormGroup className="mb-3">
                                          <Label htmlFor="businessName">
                                            {t("BUSINESS_NAME")}
                                            <sup className="text-danger">
                                              {" "}
                                              *
                                            </sup>
                                          </Label>
                                          <FormTextField
                                            name="businessName"
                                            id="businessName"
                                            placeholder={t("BUSINESS_NAME")}
                                            disabled={
                                              isAccountActive ? false : true
                                            }
                                          />
                                        </FormGroup>
                                      </Col>
                                    </>
                                  ) : (
                                    <>
                                      <Col md={6} xl={6}>
                                        <FormGroup className="mb-3">
                                          <Label htmlFor="firstName">
                                            {t("FIRST_NAME")}
                                            <sup className="text-danger">
                                              {" "}
                                              *
                                            </sup>
                                          </Label>
                                          <FormTextField
                                            name="firstName"
                                            id="firstName"
                                            placeholder={t("FIRST_NAME")}
                                            disabled={
                                              isAccountActive ? false : true
                                            }
                                          />
                                        </FormGroup>
                                      </Col>

                                      <Col md={6} xl={6}>
                                        <FormGroup className="mb-3">
                                          <Label htmlFor="lastName">
                                            {t("LAST_NAME")}
                                            <sup className="text-danger">
                                              {" "}
                                              *
                                            </sup>
                                          </Label>
                                          <FormTextField
                                            name="lastName"
                                            id="lastName"
                                            placeholder={t("LAST_NAME")}
                                            disabled={
                                              isAccountActive ? false : true
                                            }
                                          />
                                        </FormGroup>
                                      </Col>
                                    </>
                                  )}

                                  <Col md={6} xl={6}>
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="nickName">
                                        {t("NICK_NAME")}
                                        <sup className="text-danger"> *</sup>
                                      </Label>
                                      <FormTextField
                                        name="nickName"
                                        id="nickName"
                                        placeholder={t("NICK_NAME")}
                                        disabled={
                                          isAccountActive ? false : true
                                        }
                                      />
                                    </FormGroup>
                                  </Col>

                                  <Col md={6} xl={6}>
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="email">
                                        {t("EMAIL")}
                                        <sup className="text-danger"> *</sup>
                                      </Label>
                                      <FormTextField
                                        name="email"
                                        id="email"
                                        placeholder={t("EMAIL")}
                                        disabled={
                                          isAccountActive ? false : true
                                        }
                                      />
                                    </FormGroup>
                                  </Col>

                                  <Col md={6} xl={6}>
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="address">
                                        {t("ADDRESS")}
                                        <sup className="text-danger"> *</sup>
                                      </Label>
                                      <FormTextField
                                        name="address"
                                        id="address"
                                        placeholder={t("ADDRESS")}
                                        disabled={
                                          isAccountActive ? false : true
                                        }
                                      />
                                    </FormGroup>
                                  </Col>

                                  <Col md={6} xl={6}>
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="city">
                                        {t("CITY")}
                                        <sup className="text-danger"> *</sup>
                                      </Label>
                                      <FormTextField
                                        name="city"
                                        id="city"
                                        placeholder={t("CITY")}
                                        disabled={
                                          isAccountActive ? false : true
                                        }
                                      />
                                    </FormGroup>
                                  </Col>

                                  <Col md={6} xl={6}>
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="state">
                                        {t("STATE")}
                                        <sup className="text-danger"> *</sup>
                                      </Label>
                                      <FormTextField
                                        name="state"
                                        id="state"
                                        placeholder={t("STATE")}
                                        disabled={
                                          isAccountActive ? false : true
                                        }
                                      />
                                    </FormGroup>
                                  </Col>

                                  <Col md={6} xl={6}>
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="postalCode">
                                        {t("POSTAL_CODE")}
                                        <sup className="text-danger"> *</sup>
                                      </Label>
                                      <FormTextField
                                        name="postalCode"
                                        id="postalCode"
                                        placeholder={t("POSTAL_CODE")}
                                        disabled={
                                          isAccountActive ? false : true
                                        }
                                      />
                                    </FormGroup>
                                  </Col>
                                  {values.bankCountry === "CN" ? (
                                    <>
                                      <Col md={6} xl={6}>
                                        <FormGroup className="mb-3">
                                          <Label htmlFor="legalRepFName">
                                            {t(
                                              "FIRST_NAME_IN_CHINESE_LANGUAGE"
                                            )}
                                            <sup className="text-danger">
                                              {" "}
                                              *
                                            </sup>
                                          </Label>
                                          <FormTextField
                                            name="legalRepFName"
                                            id="legalRepFName"
                                            placeholder={t(
                                              "LEGAL_REPRESENTATIVE_FIRST_NAME_IN_CHINESE_LANGUAGE"
                                            )}
                                            disabled={
                                              isAccountActive ? false : true
                                            }
                                          />
                                        </FormGroup>
                                      </Col>

                                      <Col md={6} xl={6}>
                                        <FormGroup className="mb-3">
                                          <Label htmlFor="legalRepLName">
                                            {t("LAST_NAME_IN_CHINESE_LANGUAGE")}
                                            <sup className="text-danger">
                                              {" "}
                                              *
                                            </sup>
                                          </Label>
                                          <FormTextField
                                            name="legalRepLName"
                                            id="legalRepLName"
                                            placeholder={t(
                                              "LEGAL_REPRESENTATIVE_LAST_NAME_IN_CHINESE_LANGUAGE"
                                            )}
                                            disabled={
                                              isAccountActive ? false : true
                                            }
                                          />
                                        </FormGroup>
                                      </Col>
                                    </>
                                  ) : (
                                    " "
                                  )}
                                  <Col md={6} xl={6}>
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="purpose">
                                        {t("PURPOSE_OF_TRANSFER")}
                                        <sup className="text-danger"> *</sup>
                                      </Label>
                                      <FormDropdown
                                        name="purpose"
                                        id="purpose"
                                        // placeholder={t("SELECT_BANK_CODE_TYPE")}
                                        optionsValues={purposeOfTransfer}
                                        disabled={
                                          isAccountActive ? false : true
                                        }
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={6} xl={6}>
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="reference">
                                        {t("REFERENCE")}
                                        <sup className="text-danger"> *</sup>
                                      </Label>
                                      <FormTextField
                                        name="reference"
                                        id="reference"
                                        placeholder={t("REFERENCE")}
                                        disabled={
                                          isAccountActive ? false : true
                                        }
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={6} xl={6}>
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="description">
                                        {t("DESCRIPTION")}
                                      </Label>
                                      <FormTextField
                                        name="description"
                                        id="description"
                                        placeholder={t("DESCRIPTION")}
                                        disabled={
                                          isAccountActive ? false : true
                                        }
                                      />
                                    </FormGroup>
                                  </Col>

                                  <Col md={6} xl={6}>
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="dirDocument">
                                        {t("REFERENCE_DOCUMENT")}
                                      </Label>
                                      <Field
                                        name="dirDocument"
                                        component={FormImageUploader}
                                        label="Choose a file"
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Col>

                          <Col
                            md={12}
                            className="d-flex justify-content-end align-items-center gap-3 mt-3 mb-3"
                          >
                            <Button
                              className="btn-secondary w-md"
                              type="button"
                              onClick={() => navigate("/funds-transfer")}
                            >
                              {t("CANCEL")}
                            </Button>
                            <Button
                              className="btn-primary w-md"
                              type="submit"
                              disabled={isAccountActive ? false : true}
                            >
                              {loading ? (
                                <Spinner size="sm">Loading...</Spinner>
                              ) : (
                                ""
                              )}{" "}
                              {t("PAY")}
                            </Button>
                          </Col>
                        </Form>
                      </Col>
                    </Row>
                  )}
                </Formik>
              </Row>
              {/*  </CardBody>*/}
              {/*</Card>*/}
            </Col>
          </Row>

          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} close={closeBtn}>
              {t("DO_YOU_WANT_TO_REGISTER_THIS_PAYEE")}
            </ModalHeader>
            {/* <ModalBody>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </ModalBody> */}
            <ModalFooter>
              <Button
                color="primary"
                onClick={() => {
                  toggle();
                  formSubmitManually(true);
                }}
              >
                {t("YES")}
              </Button>{" "}
              <Button
                color="secondary"
                onClick={() => {
                  toggle();
                  formSubmitManually(false);
                }}
              >
                {t("NO")}
              </Button>
            </ModalFooter>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default NewPayee;
