import React, { useEffect } from "react";

//redux
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearAuthData } from "../../store/reducers/authReducer";

const Logout = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    // Set all headers to null
    // axiosApiAirwallex.defaults.headers = {
    //   "x-on-behalf-of": null,
    //   "Authorization": null,
    // };

    // axiosApi.defaults.headers = {
    //   "Authorization": null,
    //   ""
    // };

    localStorage.removeItem('token')
    localStorage.removeItem('airWallexToken')
    localStorage.removeItem('airwallexOnBehalfToken')
    dispatch(clearAuthData());

    // localStorage.clear();
    navigate("/login");
  }, [navigate]);

  return <></>;
};

export default Logout;
