import { Form, Formik } from "formik";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
  Toast,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import FormDropdown from "../../common/formComponents/FormDropdown";
import FormTextField from "../../common/formComponents/FormTextField";
import * as yup from "yup";
import { getBankCurrency, getCountries } from "../../services/CommonService";
import { toast } from "react-toastify";
import {
  deleteGlobalAccounts,
  getGlobalAccounts,
  saveGlobalAccount,
  updateGlobalAccount,
  downloadGlobalAccountStatement
} from "../../services/GlobalAccountsService";
import { useSelector } from "react-redux";
import MaterialTableCustom from "../../common/tables/MaterialTableCustom";
import jsonCountryCurrency from '../../data/COUNTRY_CURRENCY.json';

const AccountConfirmationLetter = () => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.auth.data?.userDTO);
  const [countries, setCountries] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [globalAccounts, setGlobalAccounts] = useState([]);
  const [openModel, setModelOpen] = useState(false);
  const [selectedGlobalAccount, setSelectedGlobalAccount] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const [rowDataToDelete, setRowDataToDelete] = useState({})
  const [openCreateGlobalAccountModal, setCreateGlobalAccountModal] = useState(false);
  const [isFormGlobalAccountsValues, setFormGlobalAccountsValues] = useState([]);
  const GLOBAL_ACCOUNT_FEE = process.env.REACT_APP_GLOBAL_ACCOUNT_FEE
  const ADDITIONAL_GLOBAL_ACCOUNT_FEE = process.env.REACT_APP_ADDITIONAL_GLOBAL_ACCOUNT_FEE

  const [paymentsMethods, setPaymentMethods] = useState([
    {
      label: "LOCAL",
      value: "LOCAL",
    },
    {
      label: "SWIFT",
      value: "SWIFT",
    },
  ]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchCountries();
    // fetchBankCurrency();
    fetchGlobalAccounts();
  }, []);

  const deleteToggleModal = () => {
    setOpenDeleteModal(false)
    setOpenWarningModal(false)
  };
  const [columns, setColumns] = useState([
    { title: t("ACCOUNT_NAME"), field: "accountName", width: 200 },
    { title: t("ACCOUNT_NUMBER"), field: "accountNumber", width: 200 },
    { title: t("NICK_NAME"), field: "nickName", width: 200 },
    { title: t("COUNTRY_CODE"), field: "countryCode", width: 200 },
    { title: t("CURRENCY"), field: "currency", width: 200 },
    {
      title: t("STATUS"),
      field: "status",
      width: 200,
      render: (rowData) => {
        const badgeColor =
          rowData.status === "ACTIVE"
            ? "bg-primary"
            : "INACTIVE"
            ? "bg-warning"
            : "CLOSED"
            ? "bg-danger"
            : "";
        const badgeText =
          rowData.status === "ACTIVE"
            ? "Active"
            : "INACTIVE"
            ? "Inactive"
            : "CLOSED"
            ? "Close"
            : "";

        return <Badge className={`py-1 ${badgeColor}`}>{badgeText}</Badge>;
      },
    },
  ]);

  const tableRowDeleteHandler = async (rowData) => {
    setRowDataToDelete(rowData)
    if(rowData.status == "ACTIVE"){
      setOpenDeleteModal(true);
    }else{
      setOpenWarningModal(true)
    }
    
  };

  const confirmDelete = async () => {
    setRowDataToDelete({})
    setOpenDeleteModal(false);
    try {
      const response = await deleteGlobalAccounts(
        rowDataToDelete.airwallexGlobalAccountId
      );
      fetchGlobalAccounts();
      toast.success(t("SUCCESSFULLY_GLOBAL_ACCOUNT_DELETE"));
    } catch (error) {
      if (error.code === "ERR_NETWORK") {
        toast.error(`Error: ${error.message}`);
      } else {
        toast.error(`Error: ${error.response.data.result.error}`);
      }

      
      toast.error(t("ERROR_DELETE_GLOBAL_ACCOUNTS"))
    }
};

  const tableRowViewHandler = (rowData) => {
    setSelectedGlobalAccount(rowData);
    setModelOpen(true);
  };

  const fetchGlobalAccounts = async () => {
    setLoading(true);

    try {
      const response = await getGlobalAccounts(user.coUserId);

      setGlobalAccounts(response.result);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const fetchCountries = async () => {
    try {
      const response = await getCountries();
      let countries = response.result.map((country) => ({
        label: country.country_name,
        value: country.country_code,
      }));

      setCountries(countries);
    } catch (error) {
      toast.error(t("ERROR_FETCH_COUNTRIES"));
    }
  };


  const createGlobalAccountToggleModal = () => setCreateGlobalAccountModal(!openCreateGlobalAccountModal);

  const confirmCreateGlobalAccount =  async () => {
    setCreateGlobalAccountModal(false)
    setLoading(true);

    try {
      const data = {
        country_code: isFormGlobalAccountsValues.country_code,
        currency: isFormGlobalAccountsValues.currency,
        nick_name: isFormGlobalAccountsValues.nick_name,
        payment_methods: [isFormGlobalAccountsValues.payment_methods],
        request_id: null,
      };
      const response = await saveGlobalAccount(data, user.coUserId);
      fetchGlobalAccounts();
      setLoading(false);
      toast.success(t("SUCCESSFULLY_GLOBAL_ACCOUNT_CREATE"));
    } catch (error) {
      setLoading(false);
      if (error.code === "ERR_NETWORK") {
        toast.error(`Error: ${error.message}`);
      } else {
        toast.error(`Error: ${error.response.data.result.error}`);
      }
      //toast.error(t("ERROR_GLOBAL_ACCOUNT_CREATE"))
    }
  }

  const updateGlobalAccountData = async (newNickName, globalAccountId) => {

    setLoading(true);
    try {
      const data = {
        globalAccountId: globalAccountId,
        nickName: newNickName,
      };
      const response = await updateGlobalAccount(data, user.coUserId);
      fetchGlobalAccounts();
      setLoading(false);
      toast.success(t("SUCCESSFULLY_GLOBAL_ACCOUNT_CREATE"));
    } catch (error) {
      setLoading(false);
      if (error.code === "ERR_NETWORK") {
        toast.error(`Error: ${error.message}`);
      } else {
        toast.error(`Error: ${error.message}`);
        // toast.error(`Error: ${error.response.data.result.error}`);
      }
      //toast.error(t("ERROR_GLOBAL_ACCOUNT_CREATE"))
    }

  }


  const validationSchema = yup.object().shape({
    nick_name: yup.string().required(t("NICK_NAME_IS_REQUIRED")),
    country_code: yup.string().required(t("COUNTRY_IS_REQUIRED")),
    currency: yup.string().required(t("CURRENCY_IS_REQUIRED")),
    payment_methods: yup.string().required(t("PAYMENT_METHOD_IS_REQUIRED")),
  });

  const onSubmit = (values, { resetForm }) => {
    createGlobalAccountToggleModal()
    setFormGlobalAccountsValues(values)
    resetForm()
  };

  // const handleCheckboxChange = () => {
  //   setIsChecked(!isChecked);
  // };

  // const handleContinueClick = () => {
  //   if(isChecked == true){
  //     setAdvisoryMsgConfirm(true)
  //     setModal(false)
  //   }
  //   // submitForm()
  // };

  const changeCountryHandler = (value, form) => {
    form.setFieldValue("country_code", value)
    setCurrencies([]);
    const data = jsonCountryCurrency.find((country) => country.COUNTRY_CODE == value)

      let bankCurrency = data?.CURRENCY.map((bankCurrency) => ({
        label: bankCurrency,
        value: bankCurrency,
      }));
  
      setCurrencies(bankCurrency);
  
  }

  const downloadStatement = async (rowData) => {
    try {
      setLoading(true)
      const data = {
        coUserId: user.coUserId,
        airwallexGlobalAccountId: rowData.airwallexGlobalAccountId,
      }
      const response = await downloadGlobalAccountStatement(data);
      const base64Data = response.result;
      console.log("base64Data", base64Data)

      //Convert Base64 string to Blob
      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });

      // Create a URL for the Blob and open it in a new tab
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl, '_blank');
    } catch (error) {
      toast.error(t("ERROR_DOWNLOAD_GLOBAL_ACCOUNT_STATEMENT"));
    } finally {
      setLoading(false)
    }
  }

  document.title = t("ACCOUNT_CONFIRMATION_LETTER");

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box p-0">
            <Row>
              <Col md={6}>
                <Breadcrumbs
                  title={t("TPS_SOLUTIONS")}
                  breadcrumbItem={t("ACCOUNT_CONFIRMATION_LETTER")}
                  mainTitleLink="/"
                  titleLink="#"
                />
              </Col>
            </Row>
          </div>
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <CardTitle className="h4 mb-3">
                    {t("ACCOUNT_CONFIRMATION_LETTER")}
                  </CardTitle>

                  {/*<MDBDataTable responsive striped bordered data={data} />*/}
                  <MaterialTableCustom
                    data={globalAccounts}
                    columns={columns}
                    loading={loading}
                    // tableRowDeleteHandler={tableRowDeleteHandler}
                    // tableRowViewHandler={tableRowViewHandler}
                    clickDownloadButton={downloadStatement}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AccountConfirmationLetter;
