import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Row, Col, CardBody, Card, Container, Form, FormFeedback, Label, Input, Alert } from "reactstrap";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";



import { Link, useNavigate } from "react-router-dom";

// import images
import logoSm from "../../assets/images/logo/Logo-Icon.webp";

const Register = props => {
  const history = useNavigate();


 

  // useEffect(() => {
  //   if (user) {
  //     setTimeout(() => history("/login"), 3000);
  //   }

  //   // setTimeout(() => {
  //   //     dispatch(resetRegisterFlag());
  //   // }, 3000);

  // }, [dispatch, user, history]);


  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
      username: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      username: Yup.string().required("Please Enter Your User Name"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      // dispatch(registerUser(values));
    }
  });

  // handleValidSubmit
  const handleValidSubmit = (event, values) => {
    props.registerUser(values);
  };

  useEffect(() => {
    props.apiError("");
  }, []);

  document.title = "Register | TPS Solutions";
  return (
    <React.Fragment>
      {/*<div className="home-btn d-none d-sm-block">*/}
      {/*  <Link to="/" className="text-dark">*/}
      {/*    <i className="fas fa-home h2"></i>*/}
      {/*  </Link>*/}
      {/*</div>*/}
      <div className="account-pages h-100vh">
        <Container className="h-100">
          <Row className="justify-content-center align-items-center h-100">
            <Col md={8} lg={7} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary">
                  <div className="text-primary text-center p-4">
                    <h5 className="text-white font-size-20">Registration</h5>
                    <p className="text-white-70">Get your free TPS Solutions account now.</p>
                    <Link to="/index" className="logo logo-admin">
                      <img src={logoSm} height="36" alt="logo" />
                    </Link>
                  </div>
                </div>
                <CardBody className="p-4">
                  <div className="p-3">
                  {/** {user ? (
                      <Alert color="success" style={{ marginTop: "13px" }} className="mt-5">
                        Register User Successful
                      </Alert>
                    ) : null}  */ } 
                    <Form className="mt-4" onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                      action="#">

                      <div className="mb-3">
                        <Label className="form-label" htmlFor="useremail">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter Email"
                          type="email"
                          id="useremail"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email ? true : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label" htmlFor="username">Username</Label>
                        <Input
                          name="username"
                          className="form-control"
                          placeholder="Enter Username"
                          type="text"
                          id="username"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.username || ""}
                          invalid={
                            validation.touched.username && validation.errors.username ? true : false
                          }
                        />
                        {validation.touched.username && validation.errors.username ? (
                          <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label" htmlFor="userpassword">Password</Label>
                        <Input
                          name="password"
                          value={validation.values.password || ""}
                          type="password"
                          id="userpassword"
                          className="form-control"
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password && validation.errors.password ? true : false
                          }
                        />
                        {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3 row">
                        <div className="col-12 text-end">
                          <button className="btn btn-primary w-md waves-effect waves-light" type="submit">Register</button>
                        </div>
                      </div>

                      <div className="mt-2 mb-0 row">
                        <div className="col-12 mt-4">
                          <p className="mb-0">By registering you agree to the TPS Solutions <Link to="#" className="text-primary">Terms of Use</Link></p>
                        </div>
                      </div>

                      <div className="mt-5 text-center">
                        <p className="mb-1">
                          Already have an account ?{" "}
                          <Link to="/login" className="fw-medium text-primary">
                            {" "}
                            Login
                          </Link>{" "}
                        </p>
                        <p className="mb-0">
                          © {new Date().getFullYear()} TPS Solutions. Crafted with{" "}
                          <i className="mdi mdi-heart text-danger" /> by Zincat Technology
                        </p>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};


export default Register;
