import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";
import FormTextField from "../../common/formComponents/FormTextField";
import * as yup from "yup";
import {
  oTPVerification,
  sendOtpToPhnNumber,
} from "../../services/AuthService";
import { toast } from "react-toastify";
import FormPhoneNumberField from "../../common/formComponents/FormPhoneNumberField";

const OTPVerifications = ({ setPhoneNumberVerified, setCoUserId }) => {
  const { t } = useTranslation();

  const inputRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  const [resultId, setResultId] = useState(null);

  // ----------------------------

  const [selectedMulti, setSelectedMulti] = useState(null);
  const [contactNumberSelected, setContactNumberSelected] = useState(true);
  const [otpFormVisible, setOtpFormVisible] = useState(false);
  const [countdown, setCountdown] = useState(30);
  const [timerActive, setTimerActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isCancelModal, setIsCancelModal] = useState(false);
  const [isOtpWrong, setOtpWrong] = useState(false);
  const [otpVerified, setOptVerified] = useState(false);
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [isSendOtpBtnShow, setIsSendOtpBtnShow] = useState(true);
  const [otps, setOtps] = useState({
    otp1: "",
    otp2: "",
    otp3: "",
    otp4: "",
    otp5: "",
    otp5: "",
  });

  const [disabledPhoneNumberField, setDisabledPhoneNumberField] =
    useState(false);
  const [disabledOtpBtn, setDisabledOtpBtn] = useState(false);
  const [timer, setTimer] = useState(60);
  const [isOtpButtonText, setOtpButtonText] = useState(t("SEND_OTP"));
  const [isOtpButtonColor, setOtpButtonColor] = useState("glass-btn");

  let timerInterval;

  const resetTimer = () => {
    clearInterval(timerInterval);
    setTimer(60);
    timerInterval = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);
  };

  // Countdown timer logic
  useEffect(() => {
    if (showOtpInput && timer > 0) {
      timerInterval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      clearInterval(timerInterval);
      setShowOtpInput(false);
      setTimer(60);
      setIsSendOtpBtnShow(true);
      setOtpButtonText(t("RESEND_OTP"));
      setOtpButtonColor("glass-warning-btn");
      setOptVerified(false);
      // resetDataOtpFields()
    }

    return () => {
      clearInterval(timerInterval);
    };
  }, [showOtpInput, timer]);

  useEffect(() => {
    let interval;
    if (timerActive) {
      interval = setInterval(() => {
        if (countdown > 0) {
          setCountdown(countdown - 1);
        } else {
          setTimerActive(false);
        }
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [countdown, timerActive]);

  const sendOptHandler = async (value) => {
    if (value.length > 7) {
      try {
        const data = {
          expireIn: 500,
          messageId: null,
          messageText: null,
          messageType: "FORGET_PASSWORD_OTP",
          recipientPhoneNumber: value,
          senderId: null,
          senderPhoneNumber: null,
          status: null,
          otpType: "SMS"
        };

        const response = await sendOtpToPhnNumber(data);
        setResultId(response.result);

        setIsSendOtpBtnShow(false);
        setShowOtpInput(true);
      } catch (error) {
        toast.error(t("ERROR_SEND_PHONE_NUMBER"));
      }

      //temp
      setIsSendOtpBtnShow(false);
      setShowOtpInput(true);
    } else {
      toast.error(t("PHONE_NUMBER_IS_REQUIRED"));
    }
  };

  const handleVerifyClick = async (value) => {
    try {
      const data = {
        massageId: resultId,
        otpCode: `${otps.otp1}${otps.otp2}${otps.otp3}${otps.otp4}${otps.otp5}${otps.otp6}`,
      };

      const response = await oTPVerification(data);

      if (response.statusMessage == "Success") {
        setShowOtpInput(false);
        setOtpButtonText(t("SUCCESS"));
        setDisabledOtpBtn(true);
        setOtpButtonColor("glass-succes-btn");
        setIsSendOtpBtnShow(true);
        setDisabledPhoneNumberField(true);

        resetTimer();
        setOtps({
          otp1: "",
          otp2: "",
          otp3: "",
          otp4: "",
          otp5: "",
          otp6: "",
        });
        setOptVerified(true);
        toast.success(t("VERIFY_SUCCESS"));
        setCoUserId(response.result);
        setPhoneNumberVerified(true)
        //toast.success("Verification Success")
        // setOptVerified(true)
      } else {
        setPhoneNumberVerified(false)
        setOtpWrong(true);
        setOptVerified(false);
      }
    } catch (error) {
      if (error.code === "ERR_NETWORK") {
        toast.error(`Error: ${error.message}`);
      } else {
        setOtpWrong(true);
        setOptVerified(false);
        toast.error(`Error: ${error.response.data.result.error}`);
      }
      setPhoneNumberVerified(false)
    }
  };

  const handleConfirm = () => {
    setIsCancelModal(false);
    // resetData()
  };
  const handleShowModal = () => {
    setIsCancelModal(true);
  };

  const handleCancel = () => {
    // Reset form fields
    // resetData()
    setIsCancelModal(false);
  };

  const onSubmit = () => {
    setLoading(true);
    if (otpVerified == true) {
      setPhoneNumberVerified(true);
      setLoading(false);
    } else {
      setLoading(false);
      toast.error(t("PHONE_NUMBER_NOT_VERIFIED"));
    }
  };

  const validationSchema = yup.object().shape({
    phone: yup.string().required(t("PHONE_NUMBER_IS_REQUIRED")),
  });

  const handleInputChange = (index, value) => {
    // Move to the next input when a value is entered
    if (value.length === 1 && index < inputRefs.length - 1) {
      inputRefs[index + 1].current.focus();
    }
  };

  document.title = t("OTP_VERIFICATIONS_TPS_SOLUTIONS");

  return (
    <React.Fragment>
      <Formik
        enableReinitialize
        initialValues={{
          phone: "",
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values }) => (
          <Form>
            <Row>
              <Col md={12} className="text-center mb-4">
                <h6 className="font-size-16 mb-2 text-white-70" style={{ marginTop: "50px" }}>
                  {t("WE_SEND_YOU_A_CODE_TO_VERIFY_YOUR_CONTACT_NUMBERS")}
                </h6>
              </Col>

              <Col md={12}>
                <Col md={12} className="align-items-center justify-content-center d-flex">
                  {/*<Label className="" htmlFor="phone">*/}
                  {/*  {t("WHAT_IS_YOUR_PHONE_NUMBER?")}*/}
                  {/*</Label>*/}
                </Col>
                <Col md='12'>

                  <FormGroup className="align-items-center justify-content-center d-flex">

                    <div className="d-flex align-items-start gap-2 gap-sm-3 auth-phone-input-content">
                      <FormPhoneNumberField
                          name="phone"
                          id="phone"
                          // label={t("PHONE_NUMBER")}
                      />

                      {isSendOtpBtnShow ? (
                          <Button
                              className={`btn ${isOtpButtonColor} w-md waves-effect waves-light`}
                              type="button"
                              disabled={disabledOtpBtn}
                              onClick={() => {
                                sendOptHandler(values.phone);
                              }}
                          >
                            {isOtpButtonText}
                          </Button>
                      ) : (
                          ""
                      )}
                    </div>
                  </FormGroup>
                </Col>
              </Col>
              {/* <Col md={6} > */}
              {showOtpInput && (
                <Col
                  md={12}
                  className="d-flex justify-content-center align-items-center gap-2 gap-sm-2 gap-md-3"
                >
                  <FormGroup className="mb-3">
                    <input
                      key={0}
                      ref={inputRefs[0]}
                      name="otp1"
                      className="form-control text-center max-w-35"
                      placeholder=""
                      type="text"
                      id="otp1"
                      maxLength={1}
                      value={otps.otp1}
                      onChange={(e) => {
                        setOtps({
                          ...otps,
                          otp1: e.target.value,
                        });
                        handleInputChange(0, e.target.value);
                      }}
                    />
                  </FormGroup>

                  <FormGroup className="mb-3">
                    <input
                      key={1}
                      ref={inputRefs[1]}
                      name="otp2"
                      className="form-control text-center max-w-35"
                      placeholder=""
                      type="text"
                      id="otp2"
                      maxLength={1}
                      value={otps.otp2}
                      onChange={(e) => {
                        setOtps({
                          ...otps,
                          otp2: e.target.value,
                        });
                        handleInputChange(1, e.target.value);
                      }}
                    />
                  </FormGroup>

                  <FormGroup className="mb-3">
                    <input
                      key={2}
                      ref={inputRefs[2]}
                      name="otp3"
                      className="form-control text-center max-w-35"
                      placeholder=""
                      type="text"
                      id="otp3"
                      maxLength={1}
                      value={otps.otp3}
                      onChange={(e) => {
                        setOtps({
                          ...otps,
                          otp3: e.target.value,
                        });
                        handleInputChange(2, e.target.value);
                      }}
                    />
                  </FormGroup>

                  <FormGroup className="mb-3">
                    <input
                      key={3}
                      ref={inputRefs[3]}
                      name="otp4"
                      className="form-control text-center max-w-35"
                      placeholder=""
                      type="text"
                      id="otp4"
                      maxLength={1}
                      value={otps.otp4}
                      onChange={(e) => {
                        setOtps({
                          ...otps,
                          otp4: e.target.value,
                        });
                        handleInputChange(3, e.target.value);
                      }}
                    />
                  </FormGroup>

                  <FormGroup className="mb-3">
                    <input
                      key={4}
                      ref={inputRefs[4]}
                      name="otp5"
                      className="form-control text-center max-w-35"
                      placeholder=""
                      type="text"
                      id="otp5"
                      maxLength={1}
                      value={otps.otp5}
                      onChange={(e) => {
                        setOtps({
                          ...otps,
                          otp5: e.target.value,
                        });
                        handleInputChange(4, e.target.value);
                      }}
                    />
                  </FormGroup>

                  <FormGroup className="mb-3">
                    <input
                      key={5}
                      ref={inputRefs[5]}
                      name="otp6"
                      className="form-control text-center max-w-35"
                      placeholder=""
                      type="text"
                      id="otp6"
                      maxLength={1}
                      value={otps.otp6}
                      onChange={(e) => {
                        setOtps({
                          ...otps,
                          otp6: e.target.value,
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
              )}
              {/* </Col> */}
              {showOtpInput && (
                <Col md={12} className="text-center text-white-70">
                  {t("YOU_WILL_RECEIVE_YOUR_CODE_IN")}{" "}
                  <span className="text-white">{timer}</span>
                  {isOtpWrong ? (
                    <>
                      {" | "}{" "}
                      <span style={{ color: "red" }}>
                        {t("VERIFICATION_CODE_IS_WRONG")}
                      </span>
                    </>
                  ) : (
                    <></>
                  )}
                </Col>
              )}

              {isSendOtpBtnShow ? (
                ""
              ) : (
                <Col md='12' className="text-center mt-4 mb-2">
                  <Button
                      className="btn glass-btn w-md waves-effect waves-light"
                      type="button"
                      onClick={() => {
                        setOtpWrong(false);
                        handleVerifyClick(values.phone);
                      }}
                  >
                    {t("VERIFY")}{" "}
                    <Spinner size="sm" className="text-primary">
                      {t("LOADING")}...
                    </Spinner>
                  </Button>
                </Col>

              )}

              <Col md={12} className="text-center mt-4 mb-2">
                {/*<button*/}
                {/*  className="btn btn-primary w-md waves-effect waves-light"*/}
                {/*  type="submit"*/}
                {/*>*/}
                {/*  {loading ? <Spinner size="sm">Loading...</Spinner> : ""}{" "}*/}
                {/*  Submit*/}
                {/*</button>*/}

                {/* <p className="mt-4 mb-0 lh-base font-size-12">I didn't receive a code!</p>
                                <h6 className="text-primary-blue mb-0 cursor-pointer">Resend</h6> */}
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default OTPVerifications;
