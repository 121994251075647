import React from 'react';
import { useField } from 'formik';
import MuiPhoneNumber from 'material-ui-phone-number';
import { FormFeedback} from 'reactstrap';

const FormPhoneNumberField = ({ name, id, ...props }) => {
  const [field, meta, helpers] = useField(name);

  const handleOnChange = (value) => {
    helpers.setValue(value);
  };

  return (
    <div className="mb-3 auth-phone-input">
      <MuiPhoneNumber
        {...field}
        {...props}
        defaultCountry={'us'}
        onChange={handleOnChange}
      />
      {meta.touched && meta.error && (
        <FormFeedback style={{ display: 'block' }}>
          {meta.error}
        </FormFeedback>
      )}
    </div>
  );
};

export default FormPhoneNumberField;
