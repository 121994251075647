import React, {useEffect, useState} from "react";
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardTitle,
    Col,
    Container,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {Link} from "react-router-dom";
import MaterialTableCustom from "../../common/tables/MaterialTableCustom";
import RegisterPayeeHistory from "../Payee/components/registerPayeeHistory";
import {useTranslation} from "react-i18next";
import InquiryModel from "./components/InquiryModel";
import {useSelector} from "react-redux";
import ViewInquiryModel from "./components/ViewInquiryModel";
import {getPayeeRegisterData} from "../../services/PayeeService";
import {getInquiryByCoUserId} from "../../services/InquiryService";

const Inquiry = (props) => {

    const [inquiryModel, setInquiryModel] = useState(false);
    const [viewInquiryModel, setViewInquiryModel] = useState(false);
    const [clickedRowData, setClickedRowData] = useState(null);
    const user = useSelector((state) => state.auth.data.userDTO);
    const isAccountActive = user?.isActive;
    const [tableData, setTableData] = useState([]);
    const [isNew, setIsNew] = useState(true)
    const [loading, setLoading] = useState(false);

    const { t } = useTranslation()

    const [columns, setColumns] = useState([
        { title: t("DATE_&_TIME"), field: 'date' ,width: 150},
        { title: t("INQUIRY_ID"), field: 'inquiryId',width: 250},
        { title: t("MESSAGE_TITLE"), field: 'messageTitle',width: 350},
        { title: t("STATUS"), field: 'status',width: 200,
            render: rowData => {
                const badgeColor = rowData.status === "PENDING" ? "bg-warning" :
                    rowData.status === "COMPLETED" ? "bg-custom-success" :
                        rowData.status === "REJECTED" ? "bg-danger" : "bg-success";
                const badgeText = rowData.status;

                return <Badge className={`py-1 ${badgeColor}`}>{badgeText}</Badge>;
            },
        },
    ]);

    useEffect(()=>{
        //if (isAccountActive) {
            fetchPayeeRegisterData()
      //  }
    },[])

    const fetchPayeeRegisterData = async () =>{

        setLoading(true)

        try {
            const coUserId = user ? user.coUserId : ''

            const response = await getInquiryByCoUserId(coUserId)

            let registerData = response.result.map((registerData) => {
                let formattedDate = "";

                if (registerData.createdAt) {
                    // Parse the date string and format it
                    const parsedDate = new Date(registerData.createdAt);

                    // Format the hours and minutes with leading zeros
                    const formattedHours = ("0" + parsedDate.getHours()).slice(-2);
                    const formattedMinutes = ("0" + parsedDate.getMinutes()).slice(-2);

                    formattedDate = `${parsedDate.getFullYear()}-${
                        parsedDate.getMonth() + 1
                    }-${parsedDate.getDate()}, ${formattedHours}:${formattedMinutes}`;
                } else {
                    formattedDate = "";
                }

                return {
                    ...registerData,
                    date_and_time: formattedDate,
                };
            });

            const modifiedData = registerData.map(item => ({
                ...item,
                date: item.date_and_time,
                messageTitle:item.title,
                inquiryId:item.inquiryCode,
                status:item.status.toUpperCase(),
            }));
            setTableData(modifiedData)
            setLoading(false)
        }catch (error){
            setLoading(false)
            // toast.error(t("ERROR_FETCH_PAYEE_REGISTER_DATA"))
        }
    }


    const handleInquiry = () => {
        setIsNew(false)
        setInquiryModel(true)
    }

    const tableRowViewHandler = (rowData) => {
        // Save the clicked row data
        setClickedRowData(rowData);
        setViewInquiryModel(true)
    };

    document.title = `${t("INQUIRY")} | TPS ${t("SOLUTIONS")}`

    return(
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <div className="page-title-box p-0">
                        <Row>
                            <Col md={6}>
                                <Breadcrumbs title={t("TPS_SOLUTIONS")} breadcrumbItem={t("INQUIRY")} mainTitleLink="/" titleLink="#" />
                            </Col>

                            <Col md={6} className="d-flex justify-content-md-end py-3 py-md-4 gap-2">
                                <div>
                                    <Button color="primary w-md" onClick={()=>handleInquiry()}>
                                        {t("ADD_INQUIRY")}
                                    </Button>
                                </div>

                            </Col>
                        </Row>
                    </div>

                    <Row>
                        <Col md={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle className="h4 mb-3">{t("INQUIRY_LIST")}</CardTitle>

                                    {/*<MDBDataTable responsive striped bordered data={data} />*/}
                                    <div className="custom-table">
                                        <MaterialTableCustom
                                            data={tableData}
                                            columns={columns}
                                            loading={loading}
                                            tableRowViewHandler={tableRowViewHandler}
                                        />
                                    </div>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>



                    {inquiryModel && (
                        <InquiryModel  fetchPayeeRegisterData={fetchPayeeRegisterData} rowData={[]} fetchTPSUsers={[]}  isOpen={true} isNew={true} toggleModal={() => {
                            setInquiryModel(false)
                            //setSelectedRowData(null)
                        }}/>
                    )}

                    {viewInquiryModel && (
                        <ViewInquiryModel rowData={clickedRowData}  isOpen={true} isNew={true} toggleModal={() => {
                            setViewInquiryModel(false)
                            //setSelectedRowData(null)
                        }}/>
                    )}


                </Container>
            </div>

        </React.Fragment>
    )
}

export default Inquiry;