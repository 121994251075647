import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
} from "reactstrap";

import { useTranslation } from "react-i18next";
import {Link, useNavigate} from "react-router-dom";
import { useSelector } from "react-redux";

const PostSignUpPage = (props) => {

  const { t } = useTranslation();
  const navigate = useNavigate();

  const user = useSelector((state) => state.auth.data?.userDTO);

  const REGISTRATION_ACCOUNT_FEE = process.env.REACT_APP_REGISTRATION_ACCOUNT_FEE

  const reDirectLogIn = () => {
    navigate("/login");
  };


  document.title = `${t("THANK_YOU")} | TPS ${t("SOLUTIONS")}`

  return (
    <React.Fragment>
      <Row className="mt-3 mb-3">
        <Col md={12}>
          {/* <h4 className="text-white">{t("CONGRATULATIONS")}</h4> */}
        </Col>
      </Row>
      <div className="d-flex justify-content-center mt-3">
        <h4 className="text-white">{t("CONGRATULATIONS")}</h4>
      </div>
      <div className="w-100 px-3 pb-3 position-relative z-3">
        <div className="d-flex justify-content-center mt-3">
        <p className="mb-2 font-size-14 text-white">
          <span>{t("YOU_HAVE_SUCCESSFULLY_SIGNED_UP_FOR_THE_TPS")}</span>
          <span>{`${t("YOU_WILL_INCUR_A_REGISTRATION_FEE_OF")} $${REGISTRATION_ACCOUNT_FEE} ${t("PER_ACCOUNT_AFTER_THE_REGISTRATION_PROCESS_IS_COMPLETED")}`}</span>
        </p>
        </div>
        {/* <p className="mb-2 font-size-12 text-white">
              <br />
              <span className="text-white-70">{t("YOU_HAVE_SUCCESSFULLY_SIGNED_UP_FOR_THE_TPS")}</span>
              <br/>
              <span>{`${t("YOU_WILL_INCUR_A_REGISTRATION_FEE_OF")} $${REGISTRATION_ACCOUNT_FEE} ${t("PER_ACCOUNT_AFTER_THE_REGISTRATION_PROCESS_IS_COMPLETED")}`}</span>
            </p> */}

        <div className="d-flex justify-content-center mt-3">
          <h4 className="text-white">{t("THANK_YOU")}</h4>
        </div>
        <div className="d-flex justify-content-center mt-3">
          <button
            className="btn btn-secondary w-md waves-effect waves-light font-size-12"
            type="button"
            onClick={reDirectLogIn}
          >
            {t("LOGIN")}
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PostSignUpPage;
