import { ErrorMessage } from 'formik';
import React from 'react';
import { FormFeedback, Input } from 'reactstrap';

const FormImageUploader = ({ field, form: { touched, errors }, label }) => {
  const handleChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        field.onChange({ target: { name: field.name, value: event.target.result } });
      };

      reader.readAsDataURL(file);
    } else {
      field.onChange({ target: { name: field.name, value: '' } });
    }
  };

  return (
    <div>
      <Input
        type="file"
        accept="image/*"
        className="form-control flex-1"
        onChange={handleChange}
        invalid={errors[field.name] && touched[field.name]}
      />
      <FormFeedback>
              <ErrorMessage name={field.name} />
            </FormFeedback>
      {/* {touched[field.name] && errors[field.name] && (
        <div style={{ color: 'red' }}>{errors[field.name]}</div>
      )} */}
    </div>
  );
};

export default FormImageUploader;
