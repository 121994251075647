import React, { useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
} from "reactstrap"
import classnames from "classnames"

import SignUpPage from "./SignUpPage"
import SecurityQuestion from "./SecurityQuestion"
import PostSignUpPage from "./PostSignUpPage"
import { Link } from "react-router-dom"
import logoSm from "../../assets/images/logo/Logo-Icon.webp"
import { useTranslation } from 'react-i18next'
import { useEffect } from "react"
import { SignUpDataRetrive } from "../../services/AuthService"
import { getCountries } from "../../services/CommonService"
import loginBg from "../../assets/images/login/auth-bg.jpeg";


const SignUp = props => {
  const { t } = useTranslation();
  const [activeTab, setactiveTab] = useState(1)
  const [email, setEmail] = useState('example@company.com')
  const [registrationData ,setRegistrationData] = useState(null)
  const [userId, setUserId] = useState(null)
  const [signUpUser, setSignUpUser] = useState(null)
  const [countries, setCountries] = useState([
    // { label: "Vietnam", value: "VN" },
    // { label: "United Kingdom", value: "GB" },
    // { label: "UAE", value: "AE" }
  ])
  const [titles, setTitles] = useState([
    { label: "mr", value: "Mr." },
    { label: "mrs", value: "Mrs." },
    { label: "dr", value: "Dr." }
  ])

  // const [docTypes, setDocTypes] = useState([
  //     { label: "NIC", value: "1" },
  //     { label: "Driving Licence", value: "2" },
  //     { label: "Passport", value: "3" },
  // ])
  //
  // const [ industries, setIndustries] = useState([
  //   { label: "Industry 1", value: "1" }
  // ])

  const [questions, setQuestions] = useState([
      { label: "In what city you were born?", value: "1" },
      { label: "What is the name of your favorite pet?", value: "2" },
      { label: "What high school do you attend?", value: "3" },
      { label: "What is the model of your first car?", value: "4" },
      { label: "What was your favorite food as a child?", value: "5" },
      { label: "Where did you meet your spouse?", value: "6" },
  ])

  //temp
  // const [codeChallenge, setCodeChallenge] = useState(null)
  // const [authToken, setAuthToken] = useState(null)
  // const [authorizeCode, setAuthorizeCode] = useState(null)

  const changeTab = val => {
    setactiveTab(val)
  }

 

  useEffect(() => {
    const ckySubmit = JSON.parse(localStorage.getItem("ckySubmit"))

   if(ckySubmit === false){
    setactiveTab(2)
   }
    fetchCountries()
    fetchTitles()
  }, [])

  // useEffect( () => {
  //   fetchSignUpData()
  // }, [activeTab])


  const fetchCountries = async () =>{
    try {
       const response = await getCountries();
        let countries = response.result.map(country => ({
            label: country.country_name,
            value: country.country_name
        }));

        setCountries(countries)
    }catch (error){
        //toast.error("Error: Fetch Countries")
    }
}



   

  // const fetchSignUpData = async () => {
  //    try {
  //     // const { user } = useSelector(state => ({
  //     //   user: state.Account.user,
  //     // }))    
  //     const response = await SignUpDataRetrive(email)
  //     setRegistrationData(response.result)
  //   } catch (error) {
  //     //toast.error("Error: fetch Sign up data")
  //   }
  // }

 
  const fetchTitles = async () => {
    try {
      // const response = await getTitles()

    } catch (error) {
      //toast.error("Error: fetch Titles")
    }
  }


  document.title = `${t("REGISTRATION")} | TPS ${t("SOLUTIONS")}`

  return (
    <React.Fragment>
      <div className="account-pages h-100vh">
        <Container className="h-100">
          <Row className="justify-content-center align-items-center h-100 py-3">
            <Col md={12} lg={8} xl={10}>
              <Card className="overflow-hidden auth-bg auth-bg-reg rounded-5">
                <img className="auth-bg-img" src={loginBg} alt="login-bg"/>
                <div className="position-relative z-3">
                  <div className="text-primary text-center p-4">
                    <h5 className="text-white font-size-20">{t("REGISTRATION")}</h5>
                    <p className="text-white-70 mb-0">
                      {t("GET_YOUR_FREE_SILKWORM_ACCOUNT_NOW")}
                    </p>
                    <Link to="/index" className="logo logo-admin">
                      <img src={logoSm} height="42" alt="logo" />
                    </Link>
                  </div>
                </div>

                <div id="kyc" className="position-relative z-3"></div>

                <CardBody className="p-3 p-md-4 position-relative z-3">
                  <div className="form-horizontal form-wizard-wrapper wizard clearfix register-wizard">
                  

                      <div className="steps clearfix mt-4 pt-2">
                        <ul>
                          <NavItem
                            className={classnames({ current: activeTab === 1 })}
                          >
                            <NavLink
                              className={classnames({
                                current: activeTab === 1,
                              })}
                              // disabled
                              // onClick={() => {
                              //   // if (ckySubmit == false) {
                              //   setactiveTab(1)
                              //   // }
                              // }}
                            >
                              <span className="number">1</span>
                              <span className="d-sm-none">-</span>
                              <span className="d-none d-sm-inline-block">----</span>
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({ current: activeTab === 2 })}
                          >
                            <NavLink
                              className={classnames({
                                active: activeTab === 2,
                              })}
                              // disabled
                              onClick={() => {
                                if (activeTab <= 2) {
                                  setactiveTab(2)
                                }
                              }}
                            >
                              <span className="number">2</span>
                              <span className="d-sm-none">-</span>
                              <span className="d-none d-sm-inline-block">----</span>
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({ current: activeTab === 3 })}
                          >
                            <NavLink
                              className={classnames({
                                active: activeTab === 3,
                              })}
                              // disabled
                              onClick={() => {
                                // if (activeTab >= 2) {
                                  // setactiveTab(3)
                                // }
                              }}
                            >
                              <span>
                                <span className="number">3</span>
                                <span className="d-sm-none">-</span>
                              </span>
                              {/* <span className="d-none d-sm-inline-block">----</span> */}
                            </NavLink>
                          </NavItem>
                          {/* <NavItem
                            className={classnames({ current: activeTab === 3 })}
                          >
                            <NavLink
                              className={classnames({
                                active: activeTab === 3,
                              })}
                              // disabled
                              onClick={() => {
                                // if (activeTab >= 3) {
                                  setactiveTab(3)
                                // }
                              }}
                            >
                              <span className="number">3</span>
                              <span className="d-sm-none">-</span>
                              <span className="d-none d-sm-inline-block">----</span>
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({ current: activeTab === 4 })}
                          >
                            <NavLink
                              className={classnames({
                                active: activeTab === 4,
                              })}
                              // disabled
                              onClick={() => {
                                // if (activeTab >= 4) {
                                  setactiveTab(4)
                                // }
                              }}
                            >
                              <span className="number">4</span>
                              <span className="d-sm-none">-</span>
                              <span className="d-none d-sm-inline-block">----</span>
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({ current: activeTab === 5 })}
                          >
                            <NavLink
                              className={classnames({
                                active: activeTab === 5,
                              })}
                              // disabled
                              onClick={() => {
                                // if (activeTab >= 5) {
                                  setactiveTab(5)
                                // }
                              }}
                            >
                              <span className="number">5</span>
                              <span className="d-sm-none">-</span>
                              <span className="d-none d-sm-inline-block">----</span>
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({ current: activeTab === 6 })}
                          >
                            <NavLink
                              className={classnames({
                                active: activeTab === 6,
                              })}
                              // disabled
                              onClick={() => {
                                // if (activeTab >= 5) {
                                  setactiveTab(6)
                                // }
                              }}
                            >
                              <span className="number">6</span>
                            </NavLink> */}
                          {/* </NavItem> */}
                          
                        </ul>
                      </div>
  
                    <div className="content clearfix">
                      <TabContent activeTab={activeTab} className="body">
                        <TabPane tabId={1}>
                          <SignUpPage setactiveTab={changeTab} registrationData={registrationData}  countries={countries} titles={titles} setUserId={setUserId} signUpUser={signUpUser} setSignUpUser={setSignUpUser} />
                        </TabPane>
                      </TabContent>
                      <TabContent activeTab={activeTab} className="body">
                        <TabPane tabId={2}>
                        <SecurityQuestion setactiveTab={changeTab} registrationData={registrationData} userId={userId} questions={questions}  signUpUser={signUpUser}/>
                        </TabPane>
                      </TabContent>
                      <TabContent activeTab={activeTab} className="body">
                        <TabPane tabId={3}>
                        <PostSignUpPage setactiveTab={changeTab} registrationData={registrationData} userId={userId} questions={questions}  signUpUser={signUpUser}/>
                        </TabPane>
                      </TabContent>
                     {/*  <TabContent activeTab={activeTab} className="body">
                        <TabPane tabId={3}>
                          <SecurityQuestion setactiveTab={changeTab} registrationData={registrationData} questions={questions} />
                        </TabPane>
                      </TabContent>
                      <TabContent activeTab={activeTab} className="body">
                        <TabPane tabId={4}>
                          <BussinesData setactiveTab={changeTab} registrationData={registrationData} industries={industries} />
                        </TabPane>
                      </TabContent>
                      <TabContent activeTab={activeTab} className="body">
                        <TabPane tabId={5}>
                          <AddDirectors setactiveTab={changeTab} registrationData={registrationData} countries={countries} docTypes={docTypes} titles={titles}/>
                        </TabPane>
                      </TabContent>
                      <TabContent activeTab={activeTab} className="body">
                        <TabPane tabId={6}>
                          <PreviewData setactiveTab={changeTab} registrationData={registrationData} countries={countries} industries={industries}  />
                        </TabPane>
                      </TabContent> */}
                      
                    </div>
                  </div>

                  <div className="mt-5 text-center">
                    {activeTab !== 3 ? <p className="mb-1 text-white-70">
                      {t("ALREADY_HAVE_AN_ACOUNT?")}{" "}
                      <Link to="/login" className="fw-medium text-white">
                        {" "}
                        {t("LOGIN")}
                      </Link>{" "}
                    </p> : null}
                    <p className="mb-0 text-white-70">
                      © {new Date().getFullYear()} TPS {t("SOLUTIONS")}
                    </p>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default SignUp
