import { createBrowserRouter } from "react-router-dom";
import Authmiddleware from "./middleware/Authmiddleware";
import NonAuthLayout from "../routes/middleware/NonAuthLayout";
import SignUp from "../pages/SignUp/SignUp";
import ThankYouPage from "../pages/ThankYouPage/ThankYouPage";
import Logout from "../pages/Authentication/Logout";
import Login from "../pages/Authentication/Login";
import ResetPassword from "../pages/Authentication/ResetPassword";
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import Register from "../pages/Authentication/Register";
import Dashboard from "../pages/Dashboard";
import SourceOfFunds from "../pages/SourceOfFunds";
import AddSourceOfFunds from "../pages/SourceOfFunds/add";
import MicroDeposite from "../pages/SourceOfFunds/MicroDeposite";
import TopUp from "../pages/TopUp";
import TopUpTransactionHistory from "../pages/TopUp/TopUpTransactionHistory";
import Payee from "../pages/Payee";
import RegisterPayee from "../pages/Payee/add";
import PayeeTransactionHistory from "../pages/Payee/components/payeeTransactionHistory";
import UserProfile from "../pages/Authentication/user-profile";
import AddTopUp from "../pages/TopUp/add"
import FundsTransfer from "../pages/Funds_Transfer";
import NewPayee from "../pages/Funds_Transfer/NewPayee";
import NewPayeeComponent from "../pages/Funds_Transfer/newPayeeComponent";
import RegisteredPayee from "../pages/Funds_Transfer/RegisteredPayee";
import RegisteredPayeeComponent from "../pages/Funds_Transfer/RegisteredPayeeComponent";
import GlobalAccounts from "../pages/Global-Accouts/index";
import Inquiry from "../pages/Inquiry";
import RegisternewPayee from "../pages/Payee/RegisternewPayee";

const routes = createBrowserRouter([
  //public  routes
  {
    path: "/sign-up",
    element: (
      <NonAuthLayout>
        <SignUp />
      </NonAuthLayout>
    ),
    exact: true,
  },
  {
    path: "/thank-you",
    element: (
      <NonAuthLayout>
        <ThankYouPage />
      </NonAuthLayout>
    ),
    exact: true,
  },
  {
    path: "/logout",
    element: (
      <NonAuthLayout>
        <Logout />{" "}
      </NonAuthLayout>
    ),
    exact: true,
  },
  {
    path: "/login",
    element: (
      <NonAuthLayout>
        <Login />{" "}
      </NonAuthLayout>
    ),
    exact: true,
  },
  {
    path: "/forgot-password",
    element: (
      <NonAuthLayout>
        <ForgetPasswordPage />
      </NonAuthLayout>
    ),
    exact: true,
  },
  {
    path: "/reset-password",
    element: (
      <NonAuthLayout>
        <ResetPassword />
      </NonAuthLayout>
    ),
    exact: true,
  },
  {
    path: "/register",
    element: (
      <NonAuthLayout>
        <Register />
      </NonAuthLayout>
    ),
    exact: true,
  },

  //Private routes
  {
    path: "/", element: <Authmiddleware><Dashboard /></Authmiddleware>, exact : true
  },
  { path: "/dashboard", element: <Authmiddleware><Dashboard /></Authmiddleware>, exact : true},
  
  { path: "/source-of-funds", element: <Authmiddleware><SourceOfFunds /></Authmiddleware> },
  { path: "/add-source-of-funds", element: <Authmiddleware><AddSourceOfFunds /></Authmiddleware> },
  { path: "/confirm-micro-deposits", element: <Authmiddleware><MicroDeposite /></Authmiddleware> },

  //  Top-Up
  { path: "/top-up", element: <Authmiddleware><TopUp /></Authmiddleware> },
  { path: "/add-top-up", element: <Authmiddleware><AddTopUp /></Authmiddleware> },
  {
    path: "/top-up-transaction-history",
    element: <Authmiddleware><TopUpTransactionHistory /></Authmiddleware>,
  },

  //  Payee
  { path: "/payee", element: <Authmiddleware><Payee /></Authmiddleware> },
  { path: "/register-payee", element: <Authmiddleware><RegisternewPayee /></Authmiddleware> },
  {
    path: "/payee-transaction-history",
    element: <Authmiddleware><PayeeTransactionHistory /></Authmiddleware>,
  },


  // //profile
  { path: "/profile", element: <Authmiddleware><UserProfile /></Authmiddleware> },

  //fund transfer
  { path: "/funds-transfer", element: <Authmiddleware><FundsTransfer /></Authmiddleware> },
  { path: "/funds-transfer/registered-payee", element: <Authmiddleware><RegisteredPayeeComponent /></Authmiddleware> },
  { path: "/funds-transfer/new-payee", element: <Authmiddleware><NewPayeeComponent /></Authmiddleware> },

  //global Accounts
  { path: "/global-accounts", element: <Authmiddleware><GlobalAccounts /></Authmiddleware>},


  //  Inquiry
  { path: "/inquiry", element: <Authmiddleware><Inquiry /></Authmiddleware> },

]);

export default routes;
