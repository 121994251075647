import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormGroup,
  Form as rForm, Spinner, ModalHeader, ModalBody, ModalFooter, Modal,
} from "reactstrap";

// Formik validation
import * as yup from "yup";
import { Form, Formik, useFormik } from "formik";

// Redux

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import avatar from "../../assets/images/users/custom-user.png";
// actions
import FormDropdown from "../../common/formComponents/FormDropdown";
import { useTranslation } from "react-i18next";
import FormTextField from "../../common/formComponents/FormTextField";
import { toast } from "react-toastify";
import FormPhoneNumberField from "../../common/formComponents/FormPhoneNumberField";
import { useSelector } from "react-redux";
import {
  getSecurityQuestionById,
  updatePasswordWithCurrentOne,
  updateUserDetails, updateProfilePicture
} from "../../services/AuthService";
import { useDispatch } from "react-redux";
import { updateUserDetailsRedux } from "../../store/reducers/authReducer";
import {
  changeLanguageOnUser,
  saveSecurityQuestionsFirstTime,
  updateSecurityQuestionsAndAnswers,
} from "../../services/Profile";
import i18n from "../../i18n";
import { getSecurityQuestion } from "../../services/CommonService";
import {FormControlLabel, Switch} from "@mui/material";
import OtpValidateModal from "./otpValidateModal";

const UserProfile = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [email, setemail] = useState("");
  const [name, setname] = useState("");
  const [idx, setidx] = useState(1);
  const [userQuestionDetails, setUserQuestionDetails] = useState([]);
  const [loadingUserDetails, setLoadingUserDetails] = useState(false)
  const [loadingSubmitLanguage, setSubmitLanguage] = useState(false)
  const [loadingAuthorizeEmail, setSubmitAuthorizeEmail] = useState(false)
  const [loadingSubmitChangePassword, setSubmitChangePassword] = useState(false)
  const [loadingSubmitUserQuestions, setSubmitUserQuestions] = useState(false)
  const [isAuthorized, setIsAuthorized] = useState(false)
  const [authorizeEmail, setAuthorizeEmail] = useState("")
  const [isOTPModal, setIsOTPModal] = useState(false)
  const [isAuthSwitchModal, setIsAuthSwitchModal] = useState(false)

  const [quations1, setQuestions1] = useState([])
  const [quations2, setQuestions2] = useState([])
  const [quations3, setQuestions3] = useState([])


  const [userQuestionsLength, setUserQuestionLength] = useState([
    { obj1: "" },
    { obj2: "" },
    { obj3: "" },
  ]);
  const user = useSelector((state) => state.auth.data?.userDTO);

  const [languages, setLanguages] = useState([
    { label: "English", value: "en" },
    { label: "தமிழ்", value: "ta" },
    { label: "German", value: "de" },
    { label: "中國", value: "cn" },
  ]);
  
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    fetchOnlyQuestions()
    fetchSecurityQuestion();
  }, []);

  useEffect(() => {
    setIsAuthorized(user?.isAuthorized)
    setAuthorizeEmail(user?.authorizeEmail)
  }, [user]);


  useEffect(() => {
    changeDropDownFirstTime()
  }, [userQuestionDetails]);




  const fetchOnlyQuestions = async () => {
    try {
      const coUserId = user.coUserId;
      const response = await getSecurityQuestion(coUserId);
      const data = []
      
      response.result.forEach(element => {
       data.push({
          "label" : element.text,
          "value" : element.securityQuestionId
       })
      });


      setQuestions(data);
      setQuestions1(data)
      setQuestions2(data)
      setQuestions3(data)
      changeDropDownFirstTime()
    } catch (error) {
      // //toast.error("Error: Security Question")
    }
  }



  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showReNewPassword, setShowReNewPassword] = useState(false);

  const toggleCurrentPasswordVisibility = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  const toggleReNewPasswordVisibility = () => {
    setShowReNewPassword(!showReNewPassword);
  };





  const fetchSecurityQuestion = async () => {
    try {
      const coUserId = user.coUserId;
      const response = await getSecurityQuestionById(coUserId);
      setUserQuestionDetails(response.result);
    } catch (error) {
      // //toast.error("Error: Security Question")
    }
  };

 

  // -------  Mobile verification --------

  const [showOtpInput, setShowOtpInput] = useState(false);
  const [timer, setTimer] = useState(60);
  const [isDisable, setDisable] = useState(false);
  const [isOtpButtonText, setOtpButtonText] = useState("Send OTP");
  const [isOtpButtonColor, setOtpButtonColor] = useState("btn-primary");
  const [isButtonChange, setButtonChange] = useState(true);
  const [isOtpWrong, setOtpWrong] = useState(false);
  const [isPhoneNumberField, setPhoneNumberField] = useState(false);

  let timerInterval;

  const resetTimer = () => {
    clearInterval(timerInterval);
    setTimer(60);
    timerInterval = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);
  };

  // Countdown timer logic
  useEffect(() => {
    if (showOtpInput && timer > 0) {
      timerInterval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      clearInterval(timerInterval);
      setShowOtpInput(false);
      setTimer(60);
      setButtonChange(true);
      setOtpButtonText("Resend OTP");
      setOtpButtonColor("btn-warning");
      setOtpWrong(false);
      resetDataOtpFields();
    }

    return () => {
      clearInterval(timerInterval);
    };
  }, [showOtpInput, timer]);

  const handleSendOtp = (values) => {
    // Simulate OTP  sending
    alert(`OTP sent to ${values.phone}`);
    setShowOtpInput(true);
    setButtonChange(false);
  };

  const [otp1, setOtp1] = useState("");
  const [otp2, setOtp2] = useState("");
  const [otp3, setOtp3] = useState("");
  const [otp4, setOtp4] = useState("");
  const [otp5, setOtp5] = useState("");
  const [otp6, setOtp6] = useState("");

  const handleVerifyClick = (e) => {
    e.preventDefault();
    const enteredOTP = `${otp1}${otp2}${otp3}${otp4}${otp5}${otp6}`;
   
    if (enteredOTP == "123456") {
      setShowOtpInput(false);
      setOtpButtonText("Success");
      setDisable(true);
      setOtpButtonColor("btn-success");
      setButtonChange(true);
      setPhoneNumberField(true);
      resetTimer();
      resetDataOtpFields();
      // toast.success("Verify Success")
    } else {
      setOtpWrong(true);
      ////toast.error("Verification code is wrong")
    }
    // You can resend the OTP to the user's phone here
  };

  const resetDataOtpFields = () => {
    setOtp1("");
    setOtp2("");
    setOtp3("");
    setOtp4("");
    setOtp5("");
    setOtp6("");
    setOtpWrong(false);
  };

  const schema = yup.object({
    phone: yup.string().required(t("PLEASE_ENTER_YOUR_PHONE_NUMBER")),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      phone: "",
    },
    validationSchema: schema, // Your validation schema
    onSubmit: (values) => {
      handleSendOtp(values);
    },
  });

  const onSubmitUserDetails = async (values) => {
    setLoadingUserDetails(true)
    try {
      const data = {
        coUserId: user.coUserId,
        firstName: values.firstName,
        lastName: values.lastName,
        mobileNumber: values.phone,
      };
      const response = await updateUserDetails(data);
      setLoadingUserDetails(false)
      if (response.statusMessage == "Success") {
        dispatch(updateUserDetailsRedux(data));
        toast.success(t("SUCCESSFULLY_UPDATED"));
      }
    } catch (error) {
      setLoadingUserDetails(false)
      toast.error(t("ERROR_UPDATE_USER_DETAILS"))
    }
  };

  const onSubmitLanguage = async (values) => {
    setSubmitLanguage(true)
    try {
      //set language as i18n
      i18n.changeLanguage(values.language);
      localStorage.setItem("i18nextLng", JSON.stringify(values.language));
      const data = {
        coUserId: user.coUserId,
        language: values.language,
      };
      const response = await changeLanguageOnUser(data);
      setSubmitLanguage(false)
      dispatch(updateUserDetailsRedux(data));
      toast.success(t("SUCCESSFULLY_UPDATED"));
    } catch (error) {
      setSubmitLanguage(false)
      toast.err(t("ERROR_CHANGE_LANGUAGE"))
    }
  };

  const onSubmitAuthorizeEmail = async (values) => {
    setSubmitAuthorizeEmail(true);

    if (user?.authorizeEmail !== values.authorizeEmail && isAuthorized) {
      console.log("here1");
      setAuthorizeEmail(values.authorizeEmail);
      setIsOTPModal(true);
    } else {
      toast.error(t("ERROR_UPDATE_USER_DETAILS"));
    }
    setSubmitAuthorizeEmail(false);
  };

  const onAuthorizeSwitchChange = async (value) => {
    if (value) {
      setIsAuthorized(value)
      setIsAuthSwitchModal(true)
      // try {
      //   const data = {
      //     coUserId: user.coUserId,
      //     isAuthorized: isAuthorized,
      //   };
      //   const response = await updateUserDetails(data);
      //   setSubmitAuthorizeEmail(false);
      //   if (response.statusMessage === "Success") {
      //     dispatch(updateUserDetailsRedux(data));
      //     toast.success(t("SUCCESSFULLY_UPDATED"));
      //   }
      // } catch (error) {
      //   setSubmitAuthorizeEmail(false);
      //   toast.error(t("ERROR_UPDATE_USER_DETAILS"));
      // }
    } else {
      setIsAuthorized(value)
      setIsOTPModal(true)
    }
  }

  const onAuthorizeSwitchModalCancel = async () => {
    setIsAuthSwitchModal(false)
  }

  const handleAuthorizeEmailSave = async () => {
    try {
      const data = {
        coUserId: user.coUserId,
        authorizeEmail: isAuthorized ? authorizeEmail : undefined,
        isAuthorized: isAuthorized,
      };
      const response = await updateUserDetails(data);
      setSubmitAuthorizeEmail(false);
      setIsOTPModal(false);
      if (response.statusMessage === "Success") {
        dispatch(updateUserDetailsRedux(data));
        toast.success(t("SUCCESSFULLY_UPDATED"));
      }
    } catch (error) {
      setSubmitAuthorizeEmail(false);
      toast.error(t("ERROR_UPDATE_USER_DETAILS"));
    }
  }

  const handleModalCancel = async () => {
    setIsAuthorized(!isAuthorized)
    setIsOTPModal(false);
  };

  const getEmail = async () => {
      return authorizeEmail;
  }


  const onSubmitChangePassword = async (values) => {
    setSubmitChangePassword(true)
    const data = {
      coUserId: user.coUserId,
      newPassword: values.newPassword,
      oldPassword: values.currentPassword,
    };

    try {
      const data = {
        coUserId: user.coUserId,
        newPassword: values.newPassword,
        oldPassword: values.currentPassword,
      };
      const response = await updatePasswordWithCurrentOne(data);
      setSubmitChangePassword(false)
      toast.success(t("SUCCESSFULLY_UPDATED"));

      // dispatch(clearAuthData())
      // localStorage.clear();
      // navigate('/login');
    } catch (error) {
      setSubmitChangePassword(false)
      if (error?.response?.data?.result?.error){
        toast.error(`Error: ${error?.response?.data?.result?.error}`)
      }else {
        toast.error(t("ERROR_CHANGE_PASSWORD"));
      }

    }
  };

  const validationSchemaUserDetails = yup.object().shape({
    phone: yup.string().required(t("PHONE_NUMBER_IS_REQUIRED")),
    firstName: yup.string().required(t("FIRST_NAME_IS_REQUIRED")),
    lastName: yup.string().required(t("LAST_NAME_IS_REQUIRED")),
  });

  const validationSchemaLanguage = yup.object().shape({
    language: yup.string().nullable().required(t("LANGUAGE_IS_REQUIRED")),
  });


  const validationSchemaAuthorizeEmail = yup.object().shape({
    authorizeEmail: yup
        .string()
        .email(t("EMAIL_IS_REQUIRED"))
        .required(t("EMAIL_IS_REQUIRED")),
  });

  const validationSchemaChangePassword = yup.object().shape({
    currentPassword: yup.string().required(t("CURRENT_PASSWORD_IS_A_REQUIRED")),
    newPassword: yup
      .string()
      .required(t("NEW_PASSWORD_IS_A_REQUIRED"))
      .min(8, t("PASSWORD_SHORT"))
      .when("confirmPassword", {
        is: (confirmPassword) =>
          confirmPassword !== "*********" ? true : false,
        then: yup
          .string()
          .matches(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            t("MUST_CONTAIN_8_CHARACTERS_ONE_UPPERCASE_ONE_LOWERCASE_ONE_NUMBER_AND_ONE_SPECIAL_CASE_CHARACTER")
          ),
      })
      .typeError(t("FIELD_MUST_BE_STRING")),
    confirmPassword: yup
      .string()
      .required(t("CONFIRM_PASSWORD_IS_REQUIRED"))
      .oneOf([yup.ref("newPassword"), null], t("PASSWORD_SHOULD_MATCH")),
  });

  const validationSchemaUserQuestions = yup.object().shape({
    questionOne: yup.string().required(t("QUESTION_ONE_IS_REQUIRED"))
        .notOneOf([null, t("SELECT_AN_OPTION")], t("QUESTION_ONE_IS_REQUIRED"))
    ,
    answerOne: yup.string().required(t("ANSWER_ONE_IS_REQUIRED")),
    questionTwo: yup.string().required(t("QUESTION_TWO_IS_REQUIRED"))
        .notOneOf([null, t("SELECT_AN_OPTION")], t("QUESTION_TWO_IS_REQUIRED"))
    ,
    answerTwo: yup.string().required(t("ANSWER_TWO_IS_REQUIRED")),
    questionThree: yup.string().required(t("QUESTION_THREE_IS_REQUIRED"))
        .notOneOf([null, t("SELECT_AN_OPTION")], t("QUESTION_THREE_IS_REQUIRED"))
    ,
    answerThree: yup.string().required(t("ANSWER_THREE_IS_REQUIRED")),
  });

  const onSubmitUserQuestions = async (values) => {
    setSubmitUserQuestions(true)
    try {
      if (userQuestionDetails.length > 0) {

        const dataArray = [
          { questionText: "string", answerId: values.answerIdOne, questionId: values.questionOne, answerText: values.answerOne, coUserId: user.coUserId },
          { questionText: "string", answerId: values.answerIdTwo, questionId: values.questionTwo, answerText: values.answerTwo, coUserId: user.coUserId },
          { questionText: "string", answerId: values.answerIdThree, questionId: values.questionThree, answerText: values.answerThree, coUserId: user.coUserId }
        ];


        const data = {
          "answers":dataArray
        };

        const response = await updateSecurityQuestionsAndAnswers(data);
        setSubmitUserQuestions(false)
        toast.success(t("SUCCESSFULLY_UPDATED"));
      } else {

        const dataArray = [
          { questionText: "string", answerId: null, questionId: values.questionOne, answerText: values.answerOne, coUserId: user.coUserId },
          { questionText: "string", answerId: null, questionId: values.questionTwo, answerText: values.answerTwo, coUserId: user.coUserId },
          { questionText: "string", answerId: null, questionId: values.questionThree, answerText: values.answerThree, coUserId: user.coUserId }
        ];

        const data = {
          "answers":dataArray
        }

        const response = await saveSecurityQuestionsFirstTime(data);
        setSubmitUserQuestions(false)
        toast.success(t("SUCCESSFULLY_UPDATED"));
      }
    } catch (error) {
      setSubmitUserQuestions(false)
      toast.error(t("ERROR_SECURITY_QUESTION_SAVED"));
    }
  };

  const changeDropdown1 = (value, form) => {
  
    form.setFieldValue("questionOne",value)
    const restQuations1 = []
    const restQuations2 = []
    const restQuations3 = []

    questions.forEach((q) => {
      if(! ((form.values?.questionTwo && q.value == form.values?.questionTwo) || (form.values?.questionThree && q.value == form.values?.questionThree))){
        restQuations1.push(q)
      }
    })

     questions.forEach((q) => {
      if(!(q.value == value || (form.values?.questionThree && q.value == form.values?.questionThree))){
        restQuations2.push(q)
      }
    })

    questions.forEach((q) => {
      if(!(q.value == value || (form.values?.questionTwo && q.value == form.values?.questionTwo))){
        restQuations3.push(q)
      }
    })

    setQuestions1(restQuations1)
    setQuestions2(restQuations2)
    setQuestions3(restQuations3)
  }

  const changeDropdown2 = (value, form) => {

    form.setFieldValue("questionTwo",value)
    const restQuations1 = []
    const restQuations2 = []
    const restQuations3 = []


    questions.forEach((q) => {
      if(! ( q.value == value || (form.values?.questionThree && q.value == form.values?.questionThree))){
        restQuations1.push(q)
      }
    })

     questions.forEach((q) => {
      if(!((form.values?.questionOne && q.value == form.values?.questionOne) || (form.values?.questionThree && q.value == form.values?.questionThree))){
        restQuations2.push(q)
      }
    })

    questions.forEach((q) => {
      if(!(q.value == value || (form.values?.questionOne && q.value == form.values?.questionOne))){
        restQuations3.push(q)
      }
    })

    setQuestions1(restQuations1)
    setQuestions2(restQuations2)
    setQuestions3(restQuations3)
  }

  const changeDropdown3 = (value, form) => {
    form.setFieldValue("questionThree",value)
    const restQuations1 = []
    const restQuations2 = []
    const restQuations3 = []

    questions.forEach((q) => {
      if(! ( q.value == value || (form.values?.questionTwo && q.value == form.values?.questionTwo))){
        restQuations1.push(q)
      }
    })

     questions.forEach((q) => {
      if(! (q.value == value  || (form.values?.questionOne && q.value == form.values?.questionOne))){
        restQuations2.push(q)
      }
    })

    questions.forEach((q) => {
      if(!((form.values?.questionOne && q.value == form.values?.questionOne) || (form.values?.questionTwo && q.value == form.values?.questionTwo))){
        restQuations3.push(q)
      }
    })

  
    setQuestions1(restQuations1)
    setQuestions2(restQuations2)
    setQuestions3(restQuations3)
  }

  const changeDropDownFirstTime = () => {
    console.log('------------')
    console.log(userQuestionDetails)
    const firstQuestionId = userQuestionDetails[0]?.questionId
    const secondQuestionId = userQuestionDetails[1]?.questionId
    const thirdQuestionId = userQuestionDetails[2]?.questionId
    
    const restQuations1 = []
    const restQuations2 = []
    const restQuations3 = []

    questions.forEach((q) => {
      if(! ( ( secondQuestionId && q.value == secondQuestionId ) || (thirdQuestionId && q.value == thirdQuestionId))){
        restQuations1.push(q)
      }
    })

     questions.forEach((q) => {
      if(! ( ( firstQuestionId && q.value == firstQuestionId ) || (thirdQuestionId && q.value == thirdQuestionId))){
        restQuations2.push(q)
      }
    })

    questions.forEach((q) => {
      if(!( ( secondQuestionId && q.value == secondQuestionId ) || (firstQuestionId && q.value == firstQuestionId))){
        restQuations3.push(q)
      }
    })

  
    setQuestions1(restQuations1)
    setQuestions2(restQuations2)
    setQuestions3(restQuations3)
  }

  const [selectedImage, setSelectedImage] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    setSelectedImage(user.profilePicture)
  }, [user]);

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = async (e) => {
        const base64Image = e.target.result;
        setSelectedImage(base64Image);
        await saveProfilePicture(base64Image);
      };
      reader.readAsDataURL(file);
    }
  };

  const saveProfilePicture = async (base64Image) => {
    try {
      setIsUploading(true);
      const data = {
        coUserId: user.coUserId,
        profilePicture: base64Image,
      };

      const response = await updateProfilePicture(data); // Call API to update profile picture
      if (response.statusMessage == "Success") {
        dispatch(updateUserDetailsRedux(data));
        console.log("Profile picture updated successfully");
      } else {
        console.error("Failed to update profile picture");
      }
    } catch (error) {
      console.error("Error updating profile picture", error);
    } finally {
      setIsUploading(false); // Reset upload state
    }
  };

  document.title = t("PROFILE_TPS_SOLUTIONS");
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb
            title={t("TPS_SOLUTIONS")}
            breadcrumbItem={t("PROFILE")}
          />

          <Row>
            <Col lg="12">
              {props.error && props.error ? (
                <Alert color="danger">{props.error}</Alert>
              ) : null}
              {props.success ? (
                <Alert color="success">{props.success}</Alert>
              ) : null}

              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="mx-3 position-relative">
                      <img
                          src={selectedImage || avatar}
                          alt="profile"
                          className="avatar-xl rounded-circle img-thumbnail"
                      />
                      {/* Hidden file input for image upload */}
                      <input
                          type="file"
                          id="upload"
                          accept="image/*"
                          style={{ display: "none" }}
                          onChange={handleImageChange}
                      />
                      {/* Edit Button */}
                      <Button
                          color="secondary"
                          className="position-absolute"
                          style={{ bottom: 0, right: 0 }}
                          onClick={() => document.getElementById("upload").click()}
                          disabled={isUploading} // Disable button while uploading
                      >
                        {isUploading ?  <Spinner size="sm">
                          Loading...
                        </Spinner> : "Edit"}
                      </Button>
                    </div>
                    <div className="align-self-center flex-1">
                      <div className="text-muted">
                        <h4 className="mb-0 text-capitalize">
                          {`${user.firstName} ${user.lastName}`}
                        </h4>
                        <p className="mb-0">{user.businessEmail}</p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Card>
            <CardBody>
              <Formik
                enableReinitialize
                initialValues={{
                  phone: user?.mobileNumber ? user?.mobileNumber : "",
                  firstName: user?.mobileNumber ? user?.firstName : "",
                  lastName: user?.mobileNumber ? user?.lastName : "",
                }}
                validationSchema={validationSchemaUserDetails}
                onSubmit={onSubmitUserDetails}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <Row>
                      <Col md={12} lg={12}>
                        <h4 className="card-title mb-4">
                          {t("CHANGE_USER_DETAILS")}
                        </h4>
                      </Col>

                      <Col md={6} lg={4}>
                        <FormGroup className="mb-3">
                          <Label className="form-label">
                            {t("FIRST_NAME")}
                            <sup className="text-danger"> *</sup>
                          </Label>
                          <FormTextField
                            name="firstName"
                            id="firstName"
                            placeholder={t("FIRST_NAME")}
                          />
                          <Input name="idx" value={idx} type="hidden" />
                        </FormGroup>
                      </Col>

                      <Col md={6} lg={4}>
                        <FormGroup className="mb-3">
                          <Label htmlFor="lastName">{t("LAST_NAME")}
                            <sup className="text-danger"> *</sup>
                          </Label>
                          <FormTextField
                            name="lastName"
                            id="lastName"
                            placeholder={t("LAST_NAME")}
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6} lg={4}>
                        <FormGroup className="mb-3">
                          <Label htmlFor="lastName">{t("PHONE_NUMBER")}
                            <sup className="text-danger"> *</sup>
                          </Label>
                          <FormPhoneNumberField
                            name="phone"
                            id="phone"
                            // label={t("PHONE_NUMBER")}
                          />
                        </FormGroup>
                      </Col>
                      <Col
                        md={6}
                        lg={12}
                        className="d-flex justify-content-center justify-content-md-end align-items-end"
                      >
                        <Button type="submit" color="primary" className="mb-3">
                          { loadingUserDetails ? (
                              <Spinner size="sm">
                                Loading...
                              </Spinner>
                          ) : "" }
                          {' '}{' '}
                          {t("EDIT_AND_SAVE_USER_DETAILS")}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>

              <hr />

              <Formik
                enableReinitialize
                initialValues={{
                  authorizeEmail: user?.authorizeEmail ? user?.authorizeEmail : "",
                }}
                validationSchema={validationSchemaAuthorizeEmail}
                onSubmit={onSubmitAuthorizeEmail}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <Row>
                      <Col md={6} lg={6}>
                        <h4 className="card-title mb-4">
                          {t("AUTHORIZATION_EMAIL")}
                        </h4>
                      </Col>

                      <Col md={6} lg={6}>
                        <FormGroup>
                          <FormControlLabel
                              control={
                                <Switch
                                    checked={isAuthorized}
                                    onChange={(e) => onAuthorizeSwitchChange(e.target.checked) }
                                    name="isAuthorized"
                                    color="primary"
                                />
                              }
                              label={isAuthorized ? t("ENABLED") : t("DISABLED")}
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6} lg={4}>
                      <FormGroup className="mb-3">
                          <Label htmlFor="authorizeEmail">{t("AUTHORIZATION_EMAIL")}
                            <sup className="text-danger"> *</sup>
                          </Label>
                          <FormTextField
                            name="authorizeEmail"
                            id="authorizeEmail"
                            disabled={!isAuthorized}
                            placeholder={t("AUTHORIZATION_EMAIL")}
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6} lg={4}>
                        <FormGroup>
                          <label
                            htmlFor="#"
                            className="transparent-label d-block"
                          >
                            {t("TRANSPARENT_LABEL")}
                          </label>
                          <Button
                            type="submit"
                            color="primary mb-3"
                            className="w-md"
                          >
                            { loadingAuthorizeEmail ? (
                                <Spinner size="sm">
                                  Loading...
                                </Spinner>
                            ) : "" }
                            {' '}{' '}
                            {t("EDIT_AND_SAVE_USER_DETAILS")}
                          </Button>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>

              <hr />

              <Formik
                enableReinitialize
                initialValues={{
                  language: user?.language,
                }}
                validationSchema={validationSchemaLanguage}
                onSubmit={onSubmitLanguage}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <Row>
                      <Col md={12} lg={12}>
                        <h4 className="card-title mb-4">
                          {t("CHANGE_USER_LANGUAGE")}
                        </h4>
                      </Col>

                      <Col md={6} lg={4}>
                        <FormGroup className="mb-3">
                          <Label htmlFor="title">{t("LANGUAGE")}
                            <sup className="text-danger"> *</sup>
                          </Label>
                          <FormDropdown
                            name="language"
                            id="language"
                            placeholder={t("SELECT_LANGUAGE")}
                            optionsValues={languages}
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6} lg={4}>
                        <FormGroup>
                          <label
                            htmlFor="#"
                            className="transparent-label d-block"
                          >
                            {t("TRANSPARENT_LABEL")}
                          </label>
                          <Button
                            type="submit"
                            color="primary mb-3"
                            className="w-md"
                          >
                            { loadingSubmitLanguage ? (
                                <Spinner size="sm">
                                  Loading...
                                </Spinner>
                            ) : "" }
                            {' '}{' '}
                            {t("EDIT_AND_SAVE_USER_DETAILS")}
                          </Button>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>

              <hr />

              <Formik
                enableReinitialize
                initialValues={{
                  currentPassword: "",
                  newPassword: "",
                  confirmPassword: "",
                }}
                validationSchema={validationSchemaChangePassword}
                onSubmit={onSubmitChangePassword}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <Row>
                      <Col md={12} lg={12}>
                        <h4 className="card-title mb-4">
                          {t("CHANGE_USER_PASSWORD")}
                        </h4>
                      </Col>

                      <Col md={6} lg={4}>
                        <FormGroup className="mb-3">
                          <Label className="form-label" htmlFor="userpassword">
                            {t("CURRENT_PASSWORD")}
                            <sup className="text-danger"> *</sup>
                          </Label>
                          <div className="input-group password-input-group">
                            <FormTextField
                              name="currentPassword"
                              id="currentPassword"
                              placeholder={t("CURRENT_PASSWORD")}
                              type={showCurrentPassword ? "text" : "password"}
                            />

                            <div className="input-group-append">
                              <button
                                type="button"
                                className="btn btn-secondary password-btn"
                                onClick={toggleCurrentPasswordVisibility}
                              >
                                {showCurrentPassword ? (
                                  <i className="fas fa-eye-slash"></i> // You may need to import FontAwesome or use another icon library
                                ) : (
                                  <i className="fas fa-eye"></i> // You may need to import FontAwesome or use another icon library
                                )}
                              </button>
                            </div>
                          </div>
                        </FormGroup>
                      </Col>

                      <Col md={6} lg={4}>
                        <FormGroup className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="userNewpassword"
                          >
                            {t("NEW_PASSWORD")}
                            <sup className="text-danger"> *</sup>
                          </Label>
                          <div className="input-group password-input-group">
                            <FormTextField
                              name="newPassword"
                              id="newPassword"
                              placeholder={t("NEW_PASSWORD")}
                              type={showNewPassword ? "text" : "password"}
                            />
                            <div className="input-group-append">
                              <button
                                type="button"
                                className="btn btn-secondary password-btn"
                                onClick={toggleNewPasswordVisibility}
                              >
                                {showNewPassword ? (
                                  <i className="fas fa-eye-slash"></i> // You may need to import FontAwesome or use another icon library
                                ) : (
                                  <i className="fas fa-eye"></i> // You may need to import FontAwesome or use another icon library
                                )}
                              </button>
                            </div>
                          </div>
                        </FormGroup>
                      </Col>

                      <Col md={6} lg={4}>
                        <FormGroup className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="userNewpassword"
                          >
                            {t("CONFIRM_PASSWORD")}
                            <sup className="text-danger"> *</sup>
                          </Label>
                          <div className="input-group password-input-group">
                            <FormTextField
                              name="confirmPassword"
                              id="confirmPassword"
                              placeholder={t("CONFIRM_PASSWORD")}
                              type={showReNewPassword ? "text" : "password"}
                            />
                            <div className="input-group-append">
                              <button
                                type="button"
                                className="btn btn-secondary password-btn"
                                onClick={toggleReNewPasswordVisibility}
                              >
                                {showReNewPassword ? (
                                  <i className="fas fa-eye-slash"></i> // You may need to import FontAwesome or use another icon library
                                ) : (
                                  <i className="fas fa-eye"></i> // You may need to import FontAwesome or use another icon library
                                )}
                              </button>
                            </div>
                          </div>
                        </FormGroup>
                      </Col>

                      <Col
                        md={6}
                        lg={12}
                        className="d-flex justify-content-center justify-content-md-end align-items-end"
                      >
                        <Button type="submit" color="primary" className="mb-3">
                          { loadingSubmitChangePassword ? (
                              <Spinner size="sm">
                                Loading...
                              </Spinner>
                          ) : "" }
                          {' '}{' '}
                          {t("EDIT_AND_SAVE_USER_DETAILS")}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>

              <hr />

              {/*------------------------ Mobile verification -------------------------*/}

              <hr />

              {/*------------------------Security Question -------------------------*/}
              {/*<rForm onSubmit={handleSecurityQuestion}>*/}
              <Row>
                <Col md={12} lg={12}>
                  <h4 className="card-title mb-4">
                    {t("CHANGE_SECURITY_QUESTIONS")}
                  </h4>
                </Col>

                <Formik
                  enableReinitialize
                  initialValues={{
                    //answers: userQuestionDetails ? userQuestionDetails : [],
                    answerThree: userQuestionDetails ? userQuestionDetails[2]?.answerText : "",
                    questionThree: userQuestionDetails ? userQuestionDetails[2]?.questionId :  "",
                    answerTwo: userQuestionDetails ? userQuestionDetails[1]?.answerText : "",
                    questionTwo: userQuestionDetails ? userQuestionDetails[1]?.questionId :  "",
                    answerOne: userQuestionDetails ? userQuestionDetails[0]?.answerText : "",
                    questionOne: userQuestionDetails ? userQuestionDetails[0]?.questionId :  "",
                    answerIdOne: userQuestionDetails ? userQuestionDetails[0]?.answerId :  "",
                    answerIdTwo: userQuestionDetails ? userQuestionDetails[1]?.answerId :  "",
                    answerIdThree: userQuestionDetails ? userQuestionDetails[2]?.answerId :  "",
                  }}
                  validationSchema={validationSchemaUserQuestions}
                  onSubmit={onSubmitUserQuestions}
                >
                  {({ values }) => (
                    <Form>

                      <Row>
                        <Col md={6}>
                          <FormGroup className="mb-3">
                            <Label htmlFor="questionOne">{t("QUESTION")} 01
                              <sup className="text-danger"> *</sup>
                            </Label>
                            <FormDropdown
                                name="questionOne"
                                id="questionOne"
                                optionsValues={quations1}
                                changeDropdown={changeDropdown1}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup className="mb-3">
                            <Label htmlFor="answerOne">{t("ANSWER")} 01
                              <sup className="text-danger"> *</sup>
                            </Label>
                            <FormTextField
                                name="answerOne"
                                id="answerOne"
                            />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup className="mb-3">
                            <Label htmlFor="questionTwo">{t("QUESTION")} 02
                              <sup className="text-danger"> *</sup>
                            </Label>
                            <FormDropdown
                                name="questionTwo"
                                id="questionTwo"
                                optionsValues={quations2}
                                changeDropdown={changeDropdown2}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup className="mb-3">
                            <Label htmlFor="answerTwo">{t("ANSWER")} 02
                              <sup className="text-danger"> *</sup>
                            </Label>
                            <FormTextField
                                name="answerTwo"
                                id="answerTwo"
                            />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup className="mb-3">
                            <Label htmlFor="questionThree">{t("QUESTION")} 03
                              <sup className="text-danger"> *</sup>
                            </Label>
                            <FormDropdown
                                name="questionThree"
                                id="questionThree"
                                optionsValues={quations3}
                                changeDropdown={changeDropdown3}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup className="mb-3">
                            <Label htmlFor="answerThree">{t("ANSWER")} 03
                              <sup className="text-danger"> *</sup>
                            </Label>
                            <FormTextField
                                name="answerThree"
                                id="answerThree"
                            />

                          </FormGroup>
                        </Col>

                        <Col
                            md={12}
                            lg={12}
                            className="text-center text-md-end"
                        >
                          <Button
                              type="submit"
                              color="primary"
                          >
                            { loadingSubmitUserQuestions ? (
                                <Spinner size="sm">
                                  Loading...
                                </Spinner>
                            ) : "" }
                            {' '}{' '}
                            {t("EDIT_AND_SAVE_USER_DETAILS")}
                          </Button>
                        </Col>


                      </Row>
                    </Form>
                  )}
                </Formik>
              </Row>
              {/*</rForm>*/}
            </CardBody>
          </Card>
        </Container>

        {
          isOTPModal ? <OtpValidateModal
              isOpen={isOTPModal}
              handleSave={handleAuthorizeEmailSave}
              handleCancel={handleModalCancel}
              toggleModal={handleModalCancel}
              getEmail={getEmail}
              isAuthorized={isAuthorized}
          /> : ""
        }

        <Modal isOpen={isAuthSwitchModal} centered={true} toggle={onAuthorizeSwitchModalCancel}>
          <ModalHeader toggle={null}><span className="text-warning">{t("ALERT")}</span></ModalHeader>
          <ModalBody>
            {t("TO_ENABLE_SECOND_LEVEL_AUTH")}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={onAuthorizeSwitchModalCancel}>
              {t("OK")}
            </Button>
          </ModalFooter>
        </Modal>

      </div>
    </React.Fragment>
  );
};

export default UserProfile;
