import React, {useEffect, useState} from "react";
import {Badge, Button, Card, CardBody, CardTitle, Col, Container, FormGroup, Label, Row} from "reactstrap";
import {Form, Formik} from "formik";
// import FormTextField from "../../../common/formComponents/FormTextField";
// import FormDropdown from "../../../common/formComponents/FormDropdown";
import {MDBDataTable} from "mdbreact";
import {useTranslation} from "react-i18next";
import * as yup from "yup";
import {toast} from "react-toastify";
import FormTextField from "../../common/formComponents/FormTextField";
import FormDropdown from "../../common/formComponents/FormDropdown";
import { getPayeePurpose , getPayeeStatus} from "../../services/PayeeService";
// import {getBankCurrency} from "../../../services/CommonService";
// import {getPayeePurpose, getPayeeStatus} from "../../../services/PayeeService";

const TopUpTransactionHistory = (props)=>{

    const { t } = useTranslation()


    // Payee Transaction History Table

    const columns =  [
        {
            label: "Date & Time",
            field: "date_and_time",
            sort: "asc",
        },
        {
            label: "Payee Name",
            field: "payee_name",
            sort: "asc",
        },
        {
            label: "Paid Currency",
            field: "paid_currency",
            sort: "asc",
        },
        {
            label: "Paid Amount",
            field: "paid_amount",
            sort: "asc",
        },
        {
            label: "Status",
            field: "payee_transaction_status",
            sort: "asc",
        },
        {
            label: "Purpose",
            field: "purpose",
            sort: "asc",
        },
        {
            label: "Paid By",
            field: "paid_by",
            sort: "asc",
        },
        {
            label: "Conversion Currency",
            field: "conversion_Currency",
            sort: "asc",
        },
        // {
        //     label: "Action",
        //     field: "action",
        //     sort: "asc",
        // },
    ]

    const [isTableData,setTableData] = useState([])

    useEffect(()=>{
        let dataT = [
            {
                date_and_time: "30/10/2023, 11:38",
                payee_name: "Don Peter",
                paid_currency: "USD",
                paid_amount: "2100",
                payee_transaction_status: <Badge className="bg-warning py-1">Pending</Badge>,
                purpose: "Pay",
                paid_by: "Don Peter",
                conversion_Currency: "GBP",
            },
            {
                date_and_time: "30/10/2023, 11:38",
                payee_name: "Don Peter",
                paid_currency: "USD",
                paid_amount: "2100",
                payee_transaction_status: <Badge className="bg-success py-1">Approved</Badge>,
                purpose: "Pay",
                paid_by: "Don Peter",
                conversion_Currency: "GBP",
            },
            {
                date_and_time: "30/10/2023, 11:38",
                payee_name: "Don Peter",
                paid_currency: "USD",
                paid_amount: "2100",
                payee_transaction_status: <Badge className="bg-danger py-1">Failed</Badge>,
                purpose: "Pay",
                paid_by: "Don Peter",
                conversion_Currency: "GBP",
            },
            {
                date_and_time: "30/10/2023, 11:38",
                payee_name: "Don Peter",
                paid_currency: "USD",
                paid_amount: "2100",
                payee_transaction_status: <Badge className="bg-warning py-1">Pending</Badge>,
                purpose: "Pay",
                paid_by: "Don Peter",
                conversion_Currency: "GBP",
            },
            {
                date_and_time: "30/10/2023, 11:38",
                payee_name: "Don Peter",
                paid_currency: "USD",
                paid_amount: "2100",
                payee_transaction_status: <Badge className="bg-success py-1">Approved</Badge>,
                purpose: "Pay",
                paid_by: "Don Peter",
                conversion_Currency: "GBP",
            },
            {
                date_and_time: "30/10/2023, 11:38",
                payee_name: "Don Peter",
                paid_currency: "USD",
                paid_amount: "2100",
                payee_transaction_status: <Badge className="bg-danger py-1">Failed</Badge>,
                purpose: "Pay",
                paid_by: "Don Peter",
                conversion_Currency: "GBP",
            },
        ]


        // // Adding the "Action" field to each object
        // const dataWithAction = dataT.map((entryT) => ({
        //     ...entryT,
        //     action: (
        // <div className="d-flex justify-content-center align-items-center gap-3">
        //     <Tooltip
        //         placement="top"
        //         isOpen={editTooltip}
        //         target="edit"
        //         toggle={() => {
        //             setEditTooltip(!editTooltip);
        //         }}
        //     >
        //         Edit
        //     </Tooltip>
        //     <Button color="warning" className="waves-effect waves-light rounded-circle p-0 btn-icon-circle" id="edit" onClick={toggleTransactionHistoryModal}>
        //         <i className="ti-pencil"></i>
        //     </Button>
        // </div>
        //     ),
        // }));

        setTableData(dataT);

    },[])

    const data = {
        columns:columns,
        rows: isTableData,
    };


    // Payee Transaction History

    const [isPurpose,setPurpose] = useState([
        { label: "Option 1", value: "option 1" },
        { label: "Option 2", value: "option 2" },
        { label: "Option 3", value: "option 3" },
    ])

    const [isStatus,setStatus] = useState([
        { label: "Pending", value: "pending" },
        { label: "Approved", value: "approved" },
        { label: "Failed", value: "failed" },
    ])


    useEffect(()=>{
        fetchPayeeStatus()
        fetchPayeePurpose()
    },[])

    const fetchPayeeStatus = async () =>{
        try {
            const response = await getPayeeStatus()
            let payeeStatus = response.result.map(payeeStatus => ({
                label: payeeStatus.status,
                value: payeeStatus.status_id,
            }));

            setStatus(payeeStatus)

        }catch (error){
            //toast.error("Error: Fetch Payee Status")
        }
    }

    const fetchPayeePurpose = async () =>{
        try {
            const response = await getPayeePurpose()
            let payeePurpose = response.result.map(payeePurpose => ({
                label: payeePurpose.purpose,
                value: payeePurpose.purpose_id,
            }));

            setPurpose(payeePurpose)

        }catch (error){
            //toast.error("Error: Fetch Bank Currency")
        }
    }


    const validationSchema = yup.object().shape({
        periodFrom: yup.string().required("Period From Is Required"),
        periodTo: yup.string().required("Period To Is Required"),
    })


    const onSearchTransactionHistory = values => {

        // let data = {
        //
        // }

        try {

            toast.success("Search Transaction History Success")
        } catch (error) {

            //toast.error("Error: Search Transaction History")
        }
    }

    document.title = 'Payee Transaction History | TPS Solutions';
    return(
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <br/>
                    <Row>
                        <Col md={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle className="h4">{t("TOP_UP_TRANSACTION_HISTORY")}</CardTitle>

                                    <Formik
                                        enableReinitialize
                                        initialValues={{
                                            periodFrom:"",
                                            periodTo:"",
                                            status:"",
                                            purpose:"",
                                        }}
                                        validationSchema={validationSchema}
                                        onSubmit={onSearchTransactionHistory}
                                    >
                                        {({ isSubmitting }) => (
                                            <Form>
                                                <Row className="mt-4">
                                                    <Col md={3} xl={2}>
                                                        <FormGroup className="mb-3">
                                                            <Label htmlFor="periodFrom">
                                                                {t("PERIOD_FROM")}
                                                                <sup className="text-danger"> *</sup>
                                                            </Label>
                                                            <FormTextField
                                                                name="periodFrom"
                                                                id="periodFrom"
                                                                type="date"
                                                                placeholder={t("PERIOD_FROM")}
                                                            />
                                                        </FormGroup>
                                                    </Col>

                                                    <Col md={3} xl={2}>
                                                        <FormGroup className="mb-3">
                                                            <Label htmlFor="periodTo">
                                                                {t("PERIOD_TO")}
                                                                <sup className="text-danger"> *</sup>
                                                            </Label>
                                                            <FormTextField
                                                                name="periodTo"
                                                                id="periodTo"
                                                                type="date"
                                                                placeholder={t("PERIOD_TO")}
                                                            />
                                                        </FormGroup>
                                                    </Col>

                                                    <Col md={6} xl={4}>
                                                        <FormGroup className="mb-3">
                                                            <Label htmlFor="status">
                                                                {t("STATUS")}
                                                            </Label>
                                                            <FormDropdown
                                                                name="status"
                                                                id="status"
                                                                placeholder={t("STATUS")}
                                                                optionsValues={isStatus}
                                                            />
                                                        </FormGroup>
                                                    </Col>

                                                    <Col md={6} xl={4}>
                                                        <FormGroup className="mb-3">
                                                            <Label htmlFor="purpose">
                                                                {t("PURPOSE")}
                                                            </Label>
                                                            <FormDropdown
                                                                name="purpose"
                                                                id="purpose"
                                                                placeholder={t("PURPOSE")}
                                                                optionsValues={isPurpose}
                                                            />
                                                        </FormGroup>
                                                    </Col>

                                                    <Col md={12} xl={12} className="d-flex justify-content-end">
                                                        <Button className="btn-primary w-md" type="submit">{t("SEARCH")}</Button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        )}
                                    </Formik>



                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={12}>
                            <Card>
                                <CardBody>
                                    <div className="custom-table">
                                        <MDBDataTable responsive data={data} />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default TopUpTransactionHistory;