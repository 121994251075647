import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import {
    Badge,
    Button, Card, CardBody,
    CardTitle,
    Col,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Spinner
} from "reactstrap";
import {
    SignUpRegister,
    sendOtpToPhnNumber,
    oTPVerification,
    SignUpUserValidate,
    sendOtpToPhnNumberAndVerify,
} from "../../services/AuthService";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const PaymentValidateModal = props => {
    const { isOpen, toggleModal, handleSave, handleCancel, loading, setLoading, secondLevelAuth } = props

    const { t } = useTranslation()
    const user = useSelector((state) => state.auth.data?.userDTO);
    const isAccountActive = user?.isActive;

    const [otpType, setOtpType] = useState("EMAIL");
    const inputRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
    ];

    const [resultId, setResultId] = useState(null);
    const [isSent, setIsSent] = useState(false);
    const [isOTPModal, setIsOTPModal] = useState(false);
    const [isOtpWrong, setOtpWrong] = useState(false);
    const [otpVerified, setOptVerified] = useState(false);
    const [otps, setOtps] = useState({
        otp1: "",
        otp2: "",
        otp3: "",
        otp4: "",
        otp5: "",
        otp6: "",
    });
    const [timer, setTimer] = useState(60);

    let timerInterval;

    const resetTimer = () => {
        clearInterval(timerInterval);
        setTimer(60);
        timerInterval = setInterval(() => {
            setTimer((prevTimer) => prevTimer - 1);
        }, 1000);
    };

    const handleInputChange = (index, value) => {
        // Move to the next input when a value is entered
        if (value.length === 1 && index < inputRefs.length - 1) {
            inputRefs[index + 1].current.focus();
        }
    };

    const verifyOtp = async () => {
        setLoading(true)
        try {
            const data = {
                massageId: resultId,
                otpCode: `${otps.otp1}${otps.otp2}${otps.otp3}${otps.otp4}${otps.otp5}${otps.otp6}`,
            };

            const response = await oTPVerification(data);

            if (response.statusMessage === "Success") {
                resetTimer();
                setOtps({
                    otp1: "",
                    otp2: "",
                    otp3: "",
                    otp4: "",
                    otp5: "",
                    otp6: "",
                });
                setOptVerified(true);
                handleCancel();
                toast.success(t("VERIFY_SUCCESS"));
                await handleSave();
            } else {
                setOtpWrong(true);
                setOptVerified(false);
            }
        } catch (error) {
            setLoading(false)
            if (error.code === "ERR_NETWORK") {
                toast.error(`Error: ${error.message}`);
            } else {
                setOtpWrong(true);
                // setOptVerified(false);
                toast.error(`Error: ${error.response.data.result.error}`);
            }
        }
        setLoading(false)
    }

    const handleModalCancel = async => {
        handleCancel();
        setOtps({
            otp1: "",
            otp2: "",
            otp3: "",
            otp4: "",
            otp5: "",
            otp6: "",
        })
    }

    const sendOptHandler = async () => {
        console.log(user)
        setLoading(true)
        setOtps({
            otp1: "",
            otp2: "",
            otp3: "",
            otp4: "",
            otp5: "",
            otp6: "",
        })

        try {
            const data = {
                expireIn: 500,
                messageId: null,
                messageText: null,
                messageType: "OTP",
                recipientEmail: secondLevelAuth ? user.authorizeEmail : user.businessEmail,
                recipientPhoneNumber: user.mobileNumber,
                senderId: null,
                senderPhoneNumber: null,
                status: null,
                otpType: otpType,
            };

            const response = await sendOtpToPhnNumber(data);
            setOtpWrong(false)
            setResultId(response.result);
            setIsSent(true);
        } catch (error) {
            if (error?.response?.data?.result?.error) {
                toast.error(error?.response?.data?.result?.error);
            } else {
                toast.error(t("ERROR_SEND_PHONE_NUMBER"));
            }
        }
        setLoading(false)
    }

    // useEffect(() => {
    //     if (isOpen) {
    //         sendOptHandler();
    //     }
    // }, []);

    useEffect(() => {
        if (isSent && timer > 0) {
            timerInterval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        } else if (timer === 0) {
            clearInterval(timerInterval);
            setTimer(60);
            setOptVerified(false);
            // resetDataOtpFields()
        }

        return () => {
            clearInterval(timerInterval);
        };
    }, [isSent, timer]);

    return (
        <div>
            <Modal isOpen={isOpen} toggle={toggleModal} centered={true} style={{ 'max-width': '600px' }} backdrop="static" >
                <ModalHeader >{isSent
                    ? t("WE_SEND_YOU_A_CODE_TO_VERIFY_YOUR_PAYMENT") + " " +
                    (otpType === "EMAIL"
                        ? secondLevelAuth ? t("AUTHORIZATION_EMAIL") + " " + user?.authorizeEmail + " " + t("VERIFY_YOUR_PAYMENT")
                            : t("Email") + " " + user?.businessEmail + " " + t("VERIFY_YOUR_PAYMENT")
                        : t("MOBILE_NUMBER") + " " + user?.mobileNumber + " " + t("VERIFY_YOUR_PAYMENT"))
                    : secondLevelAuth ? t("SECOND_LEVEL_AUTH_NEEDED") : t("VALIDATE_YOUR_PAYMENT")
                }</ModalHeader>
                <ModalBody>
                    <Row md={12}>
                        <Col md={8} lg={8}>
                            {!isSent ?
                                <FormGroup>
                                <Label for="otpType">{t("SELECT_OTP_METHOD")}</Label>
                                <Input
                                    type="select"
                                    name="otpType"
                                    id="otpType"
                                    value={otpType}
                                    disabled={secondLevelAuth}
                                    onChange={(e) => setOtpType(e.target.value)}
                                >
                                    <option value="EMAIL">EMAIL</option>
                                    <option value="SMS">SMS</option>
                                </Input>
                            </FormGroup>
                                 :
                                <FormGroup className="text-center text-md-start">
                                    <div className="d-flex justify-content-center justify-content-md-start gap-2 gap-lg-3">
                                        <input
                                            key={0}
                                            ref={inputRefs[0]}
                                            name="otp1"
                                            className="form-control text-center max-w-35"
                                            placeholder=""
                                            type="text"
                                            id="otp1"
                                            maxLength={1}
                                            value={otps.otp1}
                                            onChange={(e) => {
                                                setOtps({
                                                    ...otps,
                                                    otp1: e.target.value,
                                                });
                                                handleInputChange(0, e.target.value);
                                            }}
                                        />

                                        <input
                                            key={1}
                                            ref={inputRefs[1]}
                                            name="otp2"
                                            className="form-control text-center max-w-35"
                                            placeholder=""
                                            type="text"
                                            id="otp2"
                                            maxLength={1}
                                            value={otps.otp2}
                                            onChange={(e) => {
                                                setOtps({
                                                    ...otps,
                                                    otp2: e.target.value,
                                                });
                                                handleInputChange(1, e.target.value);
                                            }}
                                        />

                                        <input
                                            key={2}
                                            ref={inputRefs[2]}
                                            name="otp3"
                                            className="form-control text-center max-w-35"
                                            placeholder=""
                                            type="text"
                                            id="otp3"
                                            maxLength={1}
                                            value={otps.otp3}
                                            onChange={(e) => {
                                                setOtps({
                                                    ...otps,
                                                    otp3: e.target.value,
                                                });
                                                handleInputChange(2, e.target.value);
                                            }}
                                        />

                                        <input
                                            key={3}
                                            ref={inputRefs[3]}
                                            name="otp4"
                                            className="form-control text-center max-w-35"
                                            placeholder=""
                                            type="text"
                                            id="otp4"
                                            maxLength={1}
                                            value={otps.otp4}
                                            onChange={(e) => {
                                                setOtps({
                                                    ...otps,
                                                    otp4: e.target.value,
                                                });
                                                handleInputChange(3, e.target.value);
                                            }}
                                        />

                                        <input
                                            key={4}
                                            ref={inputRefs[4]}
                                            name="otp5"
                                            className="form-control text-center max-w-35"
                                            placeholder=""
                                            type="text"
                                            id="otp5"
                                            maxLength={1}
                                            value={otps.otp5}
                                            onChange={(e) => {
                                                setOtps({
                                                    ...otps,
                                                    otp5: e.target.value,
                                                });
                                                handleInputChange(4, e.target.value);
                                            }}
                                        />

                                        <input
                                            key={5}
                                            ref={inputRefs[5]}
                                            name="otp6"
                                            className="form-control text-center max-w-35"
                                            placeholder=""
                                            type="text"
                                            id="otp6"
                                            maxLength={1}
                                            value={otps.otp6}
                                            onChange={(e) => {
                                                setOtps({
                                                    ...otps,
                                                    otp6: e.target.value,
                                                });
                                            }}
                                        />
                                    </div>
                                    <p className="mt-2 mb-0 font-size-12 lh-1">
                                        {t("YOU_WILL_RECEIVE_YOUR_CODE_IN")}{" "}
                                        <span className="text-black">{timer}</span>
                                        {isOtpWrong ? (
                                            <>
                                                {" | "}{" "}
                                                <span style={{ color: "red" }}>
                                                    {t("VERIFICATION_CODE_IS_WRONG")}
                                                </span>
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                    </p>
                                </FormGroup>
                            }
                        </Col>
                        <Col md={4} lg={4}>
                            {isOtpWrong ?
                                <Button
                                    className="btn-success"
                                    type="button"
                                    onClick={sendOptHandler}
                                >
                                    {loading ? (
                                        <Spinner size="sm">Loading...</Spinner>
                                    ) : (
                                        ""
                                    )}{" "}
                                    {t("RESEND")}
                                </Button> : <></>
                            }
                        </Col>
                    </Row>
                    <Row md={12}>
                        <div className="d-flex justify-content-between mt-3">
                            <Button
                                className="btn-secondary"
                                type="button"
                                onClick={handleModalCancel}
                            >
                                {loading ? (
                                    <Spinner size="sm">Loading...</Spinner>
                                ) : (
                                    ""
                                )}{" "}
                                {t("CANCEL")}
                            </Button>
                            {isSent ?
                                <Button className="btn-success" onClick={verifyOtp} disabled={!isAccountActive || loading}>
                                    {loading ? (
                                        <Spinner size="sm">Loading...</Spinner>
                                    ) : (
                                        ""
                                    )}{" "}
                                    {t("VERIFY_AND_PAY")}
                                </Button> :
                                <Button
                                    className="btn-success"
                                    type="button"
                                    onClick={sendOptHandler}
                                >
                                    {loading ? (
                                        <Spinner size="sm">Loading...</Spinner>
                                    ) : (
                                        ""
                                    )}{" "}
                                    {t("SEND_VALIDATION_CODE")}
                                </Button>
                            }
                        </div>
                    </Row>
                </ModalBody>
            </Modal>
        </div>
    )
}

export default PaymentValidateModal;