import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import logoSm from "../../assets/images/logo/Logo-Icon.webp";
import { Formik, useFormik, Form } from "formik";
import * as yup from "yup";
import FormTextField from "../../common/formComponents/FormTextField";
import { useTranslation } from "react-i18next";
import { ChangePasswordService } from "../../services/AuthService";
import { toast } from "react-toastify";

const PasswordChange = ({coUserId}) => {
  const { t } = useTranslation()
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showReNewPassword, setShowReNewPassword] = useState(false);
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  const toggleReNewPasswordVisibility = () => {
    setShowReNewPassword(!showReNewPassword);
  };

  const validationSchema = yup.object().shape({
    password: yup
      .string()
      .required(t("PASSWORD_IS_REQUIRED"))
      .min(8, t("MUST_CONTAIN_8_CHARACTERS"))
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        t("MUST_CONTAIN_8_CHARACTERS_ONE_UPPERCASE_ONE_LOWERCASE_ONE_NUMBER_AND_ONE_SPECIAL_CASE_CHARACTER")
      ),
  });

  const onSubmit = async (values) =>  {
    setLoading(true)
    try {
      const data = {
        "coUserId":coUserId,
        "newPassword": values.password
    }

    const response = await ChangePasswordService(data)
      toast.success(t("PASSWORD_CHANGED_SUCCESSFULLY"))
      setLoading(false)
      navigate("/login")
    } catch (error) {
      toast.error("Error")  
      setLoading(false)
    }
  };

  document.title = t("PASSWORD_CHANGE_TPS_SOLUTIONS");

  return (
    <React.Fragment>
      <Formik
        enableReinitialize
        initialValues={{
          password: "",
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values }) => (
          <Form>
            <Row>
              <Col md={12}>
                <FormGroup className="mt-5">
                  
                  {/* <Col md={6} lg={4}> */}
                    <FormGroup className="mb-3 text-white-70">
                      <Label className="form-label" htmlFor="userNewpassword">
                        {t("NEW_PASSWORD")}
                      </Label>
                      <div className="input-group password-input-group">
                        <FormTextField
                          name="password"
                          id="password"
                          placeholder={t("NEW_PASSWORD")}
                          type={showNewPassword ? "text" : "password"}
                        />
                        <div className="input-group-append bg-white auth-bg-input-append">
                          <button
                            type="button"
                            className="btn btn-secondary password-btn"
                            onClick={toggleNewPasswordVisibility}
                          >
                            {showNewPassword ? (
                              <i className="fas fa-eye"></i> // You may need to import FontAwesome or use another icon library
                            ) : (
                              <i className="fas fa-eye-slash"></i> // You may need to import FontAwesome or use another icon library
                            )}
                          </button>
                        </div>
                      </div>
                    </FormGroup>
                  {/* </Col> */}
                </FormGroup>
              </Col>

              <Col md={12} className="text-center mt-4">
                <button
                  className="btn glass-btn w-md waves-effect waves-light"
                  type="submit"
                >
                   {loading ? <Spinner size="sm">Loading...</Spinner> : ""}{" "}
                  {t("SUBMIT")}
                </button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default PasswordChange;
