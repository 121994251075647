import React, { useEffect, useState, useRef } from "react";
import * as yup from "yup";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  FormGroup,
  Label,
  Spinner,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useTranslation } from "react-i18next";
import { Base64 } from "js-base64";
import { init, createElement } from '@airwallex/payouts-web-sdk';
import { postAirwallex, axiosApiAirwallex } from "../../helpers/api_helper";
import { Field, Form, Formik } from "formik";
import { toast } from "react-toastify";
import FormDropdown from "../../common/formComponents/FormDropdown";
import FormTextField from "../../common/formComponents/FormTextField";
import FormImageUploader from "../../common/formComponents/FormImageUploader";
import {
  getPayeeDetailsBypayeeId,
  getPayeeRegisterData,
} from "../../services/PayeeService";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import classnames from 'classnames';
import {
  saveSourceOfFundsNewPayee, validateFundTransfer,
} from "../../services/SourceOfFunds";
import { v4 as uuidv4 } from 'uuid';
import PaymentValidateModal from "./paymentValidateModal";

const NewPayeeComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [purposeOfTransfer, setPurposeOfTransfer] = useState([]);
  const [beneficiaryPayload, setBeneficiaryPayload] = useState({});
  const [transactionPayload, setTransactionPayload] = useState({});
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [isChecked, setIsChecked] = useState(false);
  const [payOutRequest, setPayOutRequest] = useState({});

  
  const beneficiaryFormRef = useRef(null);
  const beneficiaryComponentElementRef = useRef(null);
  const payoutFormRef = useRef(null);
  const payoutComponentElementRef = useRef(null);
  const [isReady, setIsReady] = useState(false);

  const user = useSelector((state) => state.auth.data?.userDTO);
  const isAccountActive = user?.isActive;
  const isAuthorized = user?.isAuthorized;

  const [secondLevelAuth, setSecondLevelAuth] = useState(false);

  const [isOTPModal, setIsOTPModal] = useState(false);

  const handleModalCancel = async =>{
    setIsOTPModal(false);
  }

  const handleSave = async () => {
    await savePaymentData(payOutRequest)
  }

  const validationSchemaPayerData = yup.object().shape({
    payee_id: yup.string().required(t("PAYEE_NAME_IS_REQUIRED")),
  });

  const validationSchemaAdditionalData = yup.object().shape({
    reference: yup.string().required(t("REFERENCE_NAME_IS_REQUIRED")),
    purpose: yup.string().required(t("PURPOSE_NAME_IS_REQUIRED")),
  });

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const getTransactionPayload = async () => {
    if (payoutComponentElementRef.current) {
      setLoading(true);
      const formResult = await payoutComponentElementRef.current.submit();
      console.log("transactionResult", formResult);

      if (!formResult.errors) {
        try {
          const response = await validateFundTransfer(formResult.values, user.coUserId);
          if(response?.result?.SecondLevelAuth){
            setSecondLevelAuth(response?.result?.SecondLevelAuth)
          }
          setPurposeOfTransfer(formResult.additionalInfo.reason);
          setTransactionPayload(formResult.values);
          toggleTab(3)
        } catch (error) {
          console.log("error", error)
          console.log("response", error.response)
          toast.error(error.response.data.result.error)
          // toast.error(t("ERROR_FETCH_PAYEE"));
        }
      }
      setLoading(false);
    }
  }

  const getBeneficiaryPayload = async () => {
    if (beneficiaryComponentElementRef.current) {
      const formResult = await beneficiaryComponentElementRef.current.submit();
      console.log("beneficiaryResult", formResult);
      if (!formResult.errors) {
        setBeneficiaryPayload(formResult.values);
        await initAndRenderPayoutElement(formResult.values);
        toggleTab(2);
      }
    }
  }

  const handleSubmit = async (values) => {
    let data = transactionPayload;
    data.reason = values.purpose;
    data.reference = values.reference;
    data.request_id = uuidv4()
    console.log("data", data);
    console.log("values", values);
    let request = {
      coUserId: user.coUserId,
      payeeId: null,
      isBeneficiary: isChecked,
      beneficiary: beneficiaryPayload,
      payout: data
    }
    setPayOutRequest(request)

      setIsOTPModal(true);
      setLoading(false);
    // await savePaymentData(request)
    // try {
    //   const response = await saveSourceOfFundsNewPayee(request, user.coUserId);
    //   setLoading(false);
    //   toast.success(t("FUNDS_TRANSFER_TO_REGISTERED_PAYEE_SUCCESS"));
    //   navigate("/payee-transaction-history");
    // } catch (error) {
    //   setLoading(false);
    //   if(error.response.data.errorType === "Airwallex side failure!"){
    //     toast.error(error.response.data.result.error.errors[0].source)
    //   } else {
    //     toast.error(t("ERROR_TRANSACTION_FAILED"));
    //   }
    // }
    setLoading(false);
  }

  const savePaymentData = async (request) => {
    setLoading(true);
    try {
      const response = await saveSourceOfFundsNewPayee(request, user.coUserId);
      setLoading(false);
      toast.success(t("FUNDS_TRANSFER_TO_REGISTERED_PAYEE_SUCCESS"));
      navigate("/payee-transaction-history");
    } catch (error) {
      setLoading(false);
      if(error.response.data.errorType === "Airwallex side failure!"){
        toast.error(error.response.data.result.error.errors[0].source)
      } else {
        toast.error(t("ERROR_TRANSACTION_FAILED"));
      }
    }
    setLoading(false);
  }

  const handleCheckboxChange = async () => {
    setIsChecked(!isChecked);
  };

  const generateCodeVerifier = async () => {
    const length = Math.floor(Math.random() * (129 - 43)) + 43;
    const array = new Uint32Array(length / 2);
    window.crypto.getRandomValues(array);
    return Array.from(array, dec => ("0" + dec.toString(16)).slice(-2)).join("");
  };

  const generateCodeChallengeFromVerifier = async (verifier) => {
    const encoder = new TextEncoder();
    const data = encoder.encode(verifier);
    const hashed = await window.crypto.subtle.digest("SHA-256", data);
    const bytes = new Uint8Array(hashed);
    return Base64.fromUint8Array(bytes, true);
  };

  const fetchAuthorizationCode = async (codeChallenge) => {
    try {
      const response = await postAirwallex("authentication/authorize", {
        code_challenge: codeChallenge,
        scope: ["w:awx_action:transfers_edit"],
      });
      return response.authorization_code;
    } catch (error) {
      console.error("Error fetching authorization code", error);
    }
  };

  const fetchAuthToken = async () => {
    try {
      const { token } = await postAirwallex("authentication/login", []);
      axiosApiAirwallex.defaults.headers['Authorization'] = `Bearer ${token}`;
      localStorage.setItem("airWallexToken", JSON.stringify(token));
      return token;
    } catch (error) {
      console.error("Error fetching auth token", error);
    }
  };



  const initAndRenderPayoutElement = async (beneficiary) => {
    try {
      setLoading(true);
      await fetchAuthToken();
      const condeVerifier = await generateCodeVerifier();
      const codeChallenge = await generateCodeChallengeFromVerifier(condeVerifier);
      const authCode = await fetchAuthorizationCode(codeChallenge);

      const options = {
        langKey: "en",
        env: process.env.REACT_APP_AIRWALLEX_ENV,
        authCode: authCode,
        clientId: process.env.REACT_APP_AIRWALLEX_CLIENT_ID,
        codeVerifier: condeVerifier,
      };
      console.log("options", options)
      await init(options);

      console.log("beneficiaryPayload", beneficiary)
      const payoutComponentElement = await createElement('payoutForm', {
        defaultValues: {
          beneficiary: beneficiary.beneficiary,
          payment_method: beneficiary.payment_methods[0],
          paymentCurrency: beneficiary.beneficiary.bank_details.account_currency
        },
        customizations: {
          fields: {
            'beneficiary.payment_method': {
              disabled: true,
            },
            'beneficiary.entity_type': {
              disabled: true,
              // hidden: true
            },
            "beneficiary.bank_details.local_clearing_system": {
              disabled: true,
            },
            "beneficiary.bank_details.bank_country_code": {
              disabled: true,
            },
            paymentCurrency: {
              disabled: true,
            }
          },
        },

      });
      payoutComponentElementRef.current = payoutComponentElement;
      console.log("beneficiaryComponentlement", payoutComponentElement)
      if (payoutFormRef.current) {
        payoutComponentElement.mount(payoutFormRef.current); // Mount to the ref
      }

      payoutComponentElement.on('ready', () => {
        console.log("ready")
        setIsReady(true);
        // Handle after ready
      });

      payoutComponentElement.on('change', ({ value }) => {
        // console.log("change", value)
        // Handle when form value changes
      });

      payoutComponentElement.on('formState', ({ loading, validating, errors }) => {
        // console.log("formState", loading, validating, errors)
        // Handle when form state changes
      });

      setLoading(false);
    } catch (error) {
      console.error("Error initializing and rendering the element", error);
      toast.error('Network Error')
    }
  };

  const initAndRenderBeneficiaryElement = async () => {
    try {

      await fetchAuthToken();
      const condeVerifier = await generateCodeVerifier();
      const codeChallenge = await generateCodeChallengeFromVerifier(condeVerifier);
      const authCode = await fetchAuthorizationCode(codeChallenge);

      const options = {
        langKey: "en",
        env: process.env.REACT_APP_AIRWALLEX_ENV,
        authCode: authCode,
        clientId: process.env.REACT_APP_AIRWALLEX_CLIENT_ID,
        codeVerifier: condeVerifier,
      };
      console.log("options", options)
      await init(options);

      const Customizations = {
        fields: {
          source_currency: {
            disabled: true
          }
        }
      }
      
 
      const beneficiaryComponentElement = await createElement('beneficiaryForm', {
      });
      beneficiaryComponentElementRef.current = beneficiaryComponentElement;
      // console.log("beneficiaryComponentlement", beneficiaryComponentElement)
      if (beneficiaryFormRef.current) {
        beneficiaryComponentElement.mount(beneficiaryFormRef.current); // Mount to the ref
      } 

      beneficiaryComponentElement.on('ready', () => {
        console.log("ready")
        setIsReady(true);
       // Handle after ready
      });
      
      beneficiaryComponentElement.on('change', ({value}) => {
        // console.log("change", value)
        // Handle when form value changes
      });
      
      beneficiaryComponentElement.on('formState', ({loading, validating, errors}) => {
        // console.log("formState", loading, validating, errors)
        // Handle when form state changes
      });

      setLoading(false);
    } catch (error) {
      console.error("Error initializing and rendering the element", error);
      toast.error('Network Error')
      // setTimeout(() => {
      //   window.location.reload()
      // }, 3000);
    }
  };

  useEffect(() => {
    initAndRenderBeneficiaryElement();
  }, []);

  document.title = t("FUNDS_TRANSFER_TPS_SOLUTIONS");

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
        <Breadcrumbs
            maintitle={t('TPS_SOLUTIONS')}
            title={t("FUNDS_TRANSFER")}
            breadcrumbItem={t("FUNDS_TRANSFER_TO_NEW_PAYEE")}
            mainTitleLink="/"
            titleLink="/funds-transfer"
          />

          <Row>
            <Col md={12}>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === 1 })}
                    onClick={() => toggleTab(1)}
                  >
                    {t("PAYEE_DETAILS")}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === 2 })}
                    onClick={() => toggleTab(2)}
                  >
                    {t("TRANSFER_DETAILS")}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === 3 })}
                    onClick={() => toggleTab(3)}
                  >
                    {t("ADDITIONAL_DETAILS")}
                  </NavLink>
                </NavItem>
              </Nav>

              <TabContent activeTab={activeTab}>
                <TabPane tabId={1}>
                <Row className="mt-4">
                  <Col md={10} xl={8}>
                    <Col md={12} className="mb-3">
                      <Card>
                        <CardBody>
                          <h5 className="text-dark-color mb-3">
                            {t("PAYEE_DETAILS")}
                          </h5>
                          <div ref={beneficiaryFormRef} id="beneficiary-form-container" />
                        </CardBody>
                      </Card>

                        <div className="form-check mt-3">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="savePayeeDetails"
                            checked={isChecked}
                            onChange={handleCheckboxChange}
                          />
                          <label className="form-check-label" htmlFor="savePayeeDetails">
                            {t("SAVE_PAYEE_DETAILS")}
                          </label>
                        </div>

                      <div className="d-flex justify-content-end mt-3">
                        <Button
                          className="btn-primary"
                          type="button"
                          onClick={() => getBeneficiaryPayload()}
                        >
                          {loading ? (
                            <Spinner size="sm">Loading...</Spinner>
                          ) : (
                            ""
                          )}{" "}
                          {t("NEXT")}
                        </Button>
                      </div>
                    </Col>
                  </Col>
                  </Row>
                </TabPane>

                <TabPane tabId={2}>
                  <Row className="mt-4">
                  <Col md={10} xl={8}>
                    <Col md={12} className="mb-3">
                      <Card>
                        <CardBody>
                          <h5 className="text-dark-color mb-3">
                            {t("TRANSFER_DETAILS")}
                          </h5>
                          <div ref={payoutFormRef} id="payout-form-container" />
                          {/* {loading ? (
                            <div className="text-center">
                              <Spinner color="primary" />
                            </div>
                          ) : (<div ref={payoutFormRef} id="payee-form-container" />)} */}

                        </CardBody>
                      </Card>

                      <div className="d-flex justify-content-between mt-3">
                        <Button
                          className="btn-secondary"
                          type="button"
                          onClick={() => toggleTab(1)}
                        >
                          {loading ? (
                            <Spinner size="sm">Loading...</Spinner>
                          ) : (
                            ""
                          )}{" "}
                          {t("BACK")}
                        </Button>
                        <Button
                          className="btn-primary"
                          type="button"
                          onClick={() => getTransactionPayload()}
                        >
                          {loading ? (
                            <Spinner size="sm">Loading...</Spinner>
                          ) : (
                            ""
                          )}{" "}
                          {t("NEXT")}
                        </Button>
                      </div>
                    </Col>
                  </Col>
                  </Row>
                </TabPane>

                <TabPane tabId={3}>
                  <Row className="mt-4">
                    <Formik
                      enableReinitialize
                      initialValues={{
                        purpose: "",
                        reference: "",
                        description: "",
                        dirDocument: ""
                      }}
                      validationSchema={validationSchemaAdditionalData}
                      onSubmit={handleSubmit}
                    >
                      {({ isSubmitting, values, setFieldValue }) => (
                        <Col md={10} xl={8}>
                        <Form>
                          <Col md={12} className="mb-3">
                            <Card>
                              <CardBody>
                                <h5 className="text-dark-color mb-3">
                                  {t("ADDITIONAL_DETAILS")}
                                </h5>
                                <Row className="">
                                  <Col md={6} xl={6}>
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="purpose">
                                        {t("PURPOSE_OF_TRANSFER")}
                                        <sup className="text-danger"> *</sup>
                                      </Label>
                                      <FormDropdown
                                        name="purpose"
                                        id="purpose"
                                        optionsValues={purposeOfTransfer}
                                        disabled={
                                          isAccountActive ? false : true
                                        }
                                        placeholder={t("PURPOSE_OF_TRANSFER")}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={6} xl={6}>
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="reference">
                                        {t("REFERENCE")}
                                        <sup className="text-danger"> *</sup>
                                      </Label>
                                      <FormTextField
                                        name="reference"
                                        id="reference"
                                        disabled={
                                          isAccountActive ? false : true
                                        }
                                        placeholder={t("REFERENCE")}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={6} xl={6}>
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="description">
                                        {t("DESCRIPTION")}
                                      </Label>
                                      <FormTextField
                                        name="description"
                                        id="description"
                                        placeholder={t("DESCRIPTION")}
                                        disabled={
                                          isAccountActive ? false : true
                                        }
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={6} xl={6}>
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="dirDocument">
                                        {t("REFERENCE_DOCUMENT")}
                                      </Label>
                                      <Field
                                        name="dirDocument"
                                        component={FormImageUploader}
                                        label="Choose a file"
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                            <div className="d-flex justify-content-between mt-3">
                              <Button
                                className="btn-secondary"
                                type="button"
                                onClick={() => toggleTab(2)}
                              >
                                {loading ? (
                                  <Spinner size="sm">Loading...</Spinner>
                                ) : (
                                  ""
                                )}{" "}
                                {t("BACK")}
                              </Button>
                              <Button className="btn-success" type="submit">
                                {loading ? (
                                  <Spinner size="sm">Loading...</Spinner>
                                ) : (
                                  ""
                                )}{" "}
                                {t("PAY")}
                              </Button>
                            </div>
                          </Col>
                        </Form>
                      </Col>
                      )}
                    </Formik>
                  </Row>
                  {isOTPModal ? <PaymentValidateModal
                    isOpen={isOTPModal}
                    loading={loading}
                    setLoading={setLoading}
                    handleSave={handleSave}
                    handleCancel={handleModalCancel}
                    toggleModal={handleModalCancel}
                    secondLevelAuth={secondLevelAuth}
                  /> : <></>}
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default NewPayeeComponent;
