import React, { useState } from "react"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Badge,
  Tooltip
} from "reactstrap"
import { Link } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { MDBDataTable } from "mdbreact"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useEffect } from "react"

const SourceOfFunds = props => {
  const [topUpToolTip, setTopUpToolTip] = useState(false)
  const [deleteToolTip, setDeleteToolTip] = useState(false)
  // const [microDepositModel, setMicroDepositModel] = useState(false)
  // const co_user_id = 1


  useEffect(() => {
    fetchSourceOfFunds()
  }, [])

  const fetchSourceOfFunds = async () => {
    try {
      // const response = await getSourceOfFunds(co_user_id)
      // setFunds(response.results)
    } catch (error) {
      //toast.error("Error: Fetch Source Of Founds")
    }
  }


  const data = {
    columns: [
      {
        label: "Bank Account Number",
        field: "bank_account_number",
        sort: "asc",
      },
      {
        label: "Account Owner Name",
        field: "account_owner_name",
        sort: "asc",
      },
      {
        label: "Account Email",
        field: "account_email",
        sort: "asc",
        width: 270,
      },
      {
        label: "Nick Name",
        field: "nick_name",
        sort: "asc",
      },
      {
        label: "Bank Name",
        field: "bank_name",
        sort: "asc",
      },
      {
        label: "Branch Name",
        field: "branch_name",
        sort: "asc",
      },
      {
        label: "Bank Code",
        field: "bank_code",
        sort: "asc",
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
      },
    ],
    rows: [
      {
        bank_account_number: "123456",
        account_owner_name: "Don Peter",
        account_email: "donpeter21@gmail.com",
        nick_name: "don21",
        bank_name: "DFCC",
        branch_name: "Mount Lavinia",
        bank_code: "123456",
        status: <Badge className="bg-warning py-1">Pending</Badge>,
        action: (
          <div className="d-flex justify-content-center align-items-center gap-3">
           
            <Tooltip
              placement="top"
              isOpen={topUpToolTip}
              target="topUp"
              toggle={() => {
                setTopUpToolTip(!topUpToolTip)
              }}
            >
              Top Up
            </Tooltip>
            <Link to="/add-top-up">
              <Button
                color="info"
                className="waves-effect waves-light rounded-circle p-0 btn-icon-circle"
                id="topUp"
              >
                <i className="ti-hand-point-up"></i>
              </Button>
            </Link>

            <Tooltip
              placement="top"
              isOpen={topUpToolTip}
              target="microDeposit"
              toggle={() => {
                setTopUpToolTip(!topUpToolTip)
              }}
            >
              Micro Deposit
            </Tooltip>
            <Link to="/confirm-micro-deposits">
              <Button
                color="info"
                className="waves-effect waves-light rounded-circle p-0 btn-icon-circle"
                id="microDeposit"
              >
                <i className="ti-lock"></i>
              </Button>
            </Link>

            <Tooltip
              placement="top"
              isOpen={deleteToolTip}
              target="delete"
              toggle={() => {
                setDeleteToolTip(!deleteToolTip)
              }}
            >
              Delete
            </Tooltip>
            <Button
              color="danger"
              className="waves-effect waves-light rounded-circle p-0 btn-icon-circle"
              id="delete"
            >
              <i className="ti-trash"></i>
            </Button>
          </div>
        ),
      },

      {
        bank_account_number: "123456",
        account_owner_name: "Jennifer Lawrence",
        account_email: "jenniferlawrence@gmail.com",
        nick_name: "Jenni",
        bank_name: "DFCC",
        branch_name: "Mount Lavinia",
        bank_code: "123456",
        status: <Badge className="bg-success py-1">Approved</Badge>,
        action: (
          <div className="d-flex justify-content-center align-items-center gap-3">
            <Tooltip
              placement="top"
              isOpen={topUpToolTip}
              target="topUp"
              toggle={() => {
                setTopUpToolTip(!topUpToolTip)
              }}
            >
              Top Up
            </Tooltip>
            <Link to="/add-top-up">
              <Button
                color="info"
                className="waves-effect waves-light rounded-circle p-0 btn-icon-circle"
                id="topUp"
              >
                <i className="ti-hand-point-up"></i>
              </Button>
            </Link>

            <Tooltip
              placement="top"
              isOpen={topUpToolTip}
              target="microDeposit"
              toggle={() => {
                setTopUpToolTip(!topUpToolTip)
              }}
            >
              Micro Deposit
            </Tooltip>
            <Link to="/confirm-micro-deposits">
              <Button
                color="info"
                className="waves-effect waves-light rounded-circle p-0 btn-icon-circle"
                id="microDeposit"
              >
                <i className="ti-lock"></i>
              </Button>
            </Link>

            <Tooltip
              placement="top"
              isOpen={deleteToolTip}
              target="delete"
              toggle={() => {
                setDeleteToolTip(!deleteToolTip)
              }}
            >
              Delete
            </Tooltip>
            <Button
              color="danger"
              className="waves-effect waves-light rounded-circle p-0 btn-icon-circle"
              id="delete"
            >
              <i className="ti-trash"></i>
            </Button>
          </div>
        ),
      },

      {
        bank_account_number: "123456",
        account_owner_name: "Bruce Wayne",
        account_email: "brucewayne@gmail.com",
        nick_name: "Bruce",
        bank_name: "Boc",
        branch_name: "Mount Lavinia",
        bank_code: "123456",
        status: <Badge className="bg-danger py-1">Failed</Badge>,
        action: (
          <div className="d-flex justify-content-center align-items-center gap-3">
            <Tooltip
              placement="top"
              isOpen={topUpToolTip}
              target="topUp"
              toggle={() => {
                setTopUpToolTip(!topUpToolTip)
              }}
            >
              Top Up
            </Tooltip>
            <Link to="/add-top-up">
              <Button
                color="info"
                className="waves-effect waves-light rounded-circle p-0 btn-icon-circle"
                id="topUp"
              >
                <i className="ti-hand-point-up"></i>
              </Button>
            </Link>

            <Tooltip
              placement="top"
              isOpen={topUpToolTip}
              target="microDeposit"
              toggle={() => {
                setTopUpToolTip(!topUpToolTip)
              }}
            >
              Micro Deposit
            </Tooltip>
            <Link to="/confirm-micro-deposits">
              <Button
                color="info"
                className="waves-effect waves-light rounded-circle p-0 btn-icon-circle"
                id="microDeposit"
              >
                <i className="ti-lock"></i>
              </Button>
            </Link>

            <Tooltip
              placement="top"
              isOpen={deleteToolTip}
              target="delete"
              toggle={() => {
                setDeleteToolTip(!deleteToolTip)
              }}
            >
              Delete
            </Tooltip>
            <Button
              color="danger"
              className="waves-effect waves-light rounded-circle p-0 btn-icon-circle"
              id="delete"
            >
              <i className="ti-trash"></i>
            </Button>
          </div>
        ),
      },
    ],
  }

  // const toggle = () =>  setMicroDepositModel(microDepositModel => !microDepositModel)

  document.title = "Source Of Funds | TPS Solutions"
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box p-0">
            <Row>
              <Col md={6}>
                <Breadcrumbs
                  title="TPS Solutions"
                  breadcrumbItem="Source Of Funds"
                  mainTitleLink="/"
                  titleLink="#"
                />
              </Col>

              <Col
                md={6}
                className="d-flex justify-content-md-end py-3 py-md-4"
              >
                <Link to="/add-source-of-funds">
                  <Button color="primary w-md">Add Funds</Button>
                </Link>
              </Col>
            </Row>
          </div>

          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  {/*<CardTitle className="h4">Datatable</CardTitle>*/}
                    <div className="custom-table">
                        <MDBDataTable responsive data={data} />
                    </div>

                </CardBody>

              </Card>
            </Col>
          </Row>
        </Container>
      </div>

    </React.Fragment>
  )
}

export default withTranslation()(SourceOfFunds)
