import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  FormGroup,
  Label} from "reactstrap";
import { useTranslation } from "react-i18next";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import * as yup from "yup";
import { Form, Formik } from "formik";
import FormTextField from "../../common/formComponents/FormTextField";
import FormDropdown from "../../common/formComponents/FormDropdown";

import { toast } from "react-toastify";
import { useEffect } from "react";
import { getBankCurrency, getCountries } from "../../services/CommonService";

const AddSourceOfFunds = (props) => {
  const { t } = useTranslation();
  // const co_user_id = 1;

  // const [funds, setFunds] = useState([]);

  const [bankCodeType, setBankCodeType] = useState([
    { label: "au_bank", value: "au_bank" },
    { label: "ca_bank", value: "ca_bank" },
    { label: "eu_bank", value: "eu_bank" },
    { label: "gb_bank", value: "gb_bank" },
    { label: "hk_bank", value: "hk_bank" },
    { label: "nz_bank", value: "nz_bank" },
    { label: "sg_bank", value: "sg_bank" },
    { label: "us_bank", value: "us_bank" },
  ]);

  const [countries, setCountries] = useState([
    { label: "Vietnam", value: "84" },
    { label: "United Kingdom", value: "44" },
    { label: "UAE", value: "971" },
  ]);

  const [isBankCurrency, setBankCurrency] = useState([
    { label: "USD", value: "usd" },
    { label: "LKR", value: "lkr" },
    { label: "EUR", value: "eur" },
  ]);

  useEffect(() => {
    fetchCountries();
    fetchBankCurrency();
  }, []);

  const fetchBankCurrency = async () => {
    try {
      const response = await getBankCurrency();

      let bankCurrency = response.result.map((bankCurrency) => ({
        label: bankCurrency.bank_currency,
        value: bankCurrency.bank_currency_id,
      }));

      setBankCurrency(bankCurrency);
    } catch (error) {
      //toast.error("Error: Fetch Bank Currency")
    }
  };

  const fetchCountries = async () => {
    try {
      const response = await getCountries();
      let countries = response.result.map((country) => ({
        label: country.country_name,
        value: country.country_id,
      }));

      setCountries(countries);
    } catch (error) {
      //toast.error("Error: Fetch Countries")
    }
  };

  const validationSchema = yup.object().shape({
    // bankAccountNumber: yup.string().required("Bank Account Number Is Required"),
    // accountOwnerName: yup.string().required("Account Owner Name Is Required"),
    // bankAccountName: yup.string().required("Bank Account Name Is Required"),
    // accountOwnerEmail: yup
    //   .string()
    //   .email("Invalid Email Address")
    //   .required("Account Owner Email Is Required"),
    // bankName: yup.string().required("Bank Name Is Required"),
    // branchName: yup.string().required("Branch Name Is Required"),
    // bankCodeType: yup.string().required("Bank Code Type Is Required"),
    // bankCode: yup.string().required("Bank Code Is Required"),
    // bankCountry: yup.string().required("Bank Country Is Required"),
    // bankCurrency: yup.string().required("Bank Currency Is Required"),
    // microDeposit1: yup.string().required("Micro Deposit 1 Is Required"),
    // microDeposit2: yup.string().required("Micro Deposit 2 Is Required"),
    // nickName: yup.string().required("Nick Name is Required"),
  });

  const onSubmit = async (values) => {
    try {
      // const data = {
      //   // "active": true,
      //   bankCode: values.bankCode,
      //   bankCodeType: values.bankCodeType,
      //   bankCountry: values.bankCountry,
      //   bankCurrency: values.bankCurrency,
      //   bankName: values.AddSourceOfFunds,
      //   branchName: values.AddSourceOfFunds,
      //   coUserId: values.AddSourceOfFunds,
      //   // "default": true,
      //   // "description": values.AddSourceOfFunds,
      //   // "expirationDate": values.AddSourceOfFunds,
      //   // "microDepositStatus": values.AddSourceOfFunds,
      //   // "microDeposits": [
      //   //   {
      //   //     "deposit": 0,
      //   //     "microDepositId": "string",
      //   //     "sourceId": "string",
      //   //     "status": "string",
      //   //     "verified": true
      //   //   }
      //   // ],
      //   nickName: values.AddSourceOfFunds,
      //   // "sourceId": values.AddSourceOfFunds,
      //   // "sourceIdentifier": values.AddSourceOfFunds,
      //   sourceOwnerEmail: values.accountOwnerEmail,
      //   sourceOwnerName: values.accountOwnerName,
      //   // "sourceType": values.AddSourceOfFunds,
      //   // "verified": true
      // };
      // const response = await saveSourceOfFunds(data);
      toast.success("Successfully Insert");
    } catch (error) {
      //toast.error("Error : Insert Source Of Funds")
    }
  };

  document.title = "Add Funds | TPS Solutions";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            maintitle="TPS Solutions"
            title="Source Of Funds"
            breadcrumbItem="Add Funds"
            mainTitleLink="/"
            titleLink="/source-of-funds"
          />

          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  {/*<CardTitle className="h4 font-size-18">Linked Source Of Funds</CardTitle>*/}

                  <Row className="mt-0">
                    <Col md={12} className="mb-4">
                      <p className="mb-0 font-size-12">
                        {t(
                          "YOU_MAY_ADD_MONEY_TO_TPS_SOLUTIONS_VIA_DIRECT_DEBIT_BY_CONNECTING_YOUR_BANK_ACCOUNT"
                        )}
                      </p>
                      <p className="mb-0 font-size-12">
                        {t("TYPICALLY_FUNDS_RECEIVED_IN_1_2_BUSINESS_DAYS")}
                      </p>
                    </Col>

                    <Formik
                      enableReinitialize
                      initialValues={{
                        bankAccountNumber: "",
                        accountOwnerName: "",
                        bankAccountName: "",
                        accountOwnerEmail: "",
                        nickName: "",
                        bankName: "",
                        branchName: "",
                        bankCodeType: "",
                        bankCode: "",
                        bankCountry: "",
                        bankCurrency: "",
                        bsbCode: "",
                      }}
                      validationSchema={validationSchema}
                      onSubmit={onSubmit}
                    >
                      {({ isSubmitting, values }) => (
                        <Form>
                          <Col md={12} className="mb-3">
                            <Row>
                              <Col md={6} xl={4}>
                                <FormGroup className="mb-3">
                                  <Label htmlFor="bankCodeType">
                                    {t("BANK_CODE_TYPE")}
                                  </Label>
                                  <FormDropdown
                                    name="bankCodeType"
                                    id="bankCodeType"
                                    placeholder={t("SELECT_BANK_CODE_TYPE")}
                                    optionsValues={bankCodeType}
                                  />
                                </FormGroup>
                              </Col>
                              <Col md={6} xl={4}>
                                <FormGroup className="mb-3">
                                  <Label htmlFor="accountOwnerName">
                                    {t("ACCOUNT_NAME")}
                                  </Label>
                                  <FormTextField
                                    name="accountOwnerName"
                                    id="accountOwnerName"
                                    placeholder={t("ACCOUNT_NAME")}
                                  />
                                </FormGroup>
                              </Col>
                              {values.bankCodeType === "eu_bank" ? (
                                ""
                              ) : (
                                <Col md={6} xl={4}>
                                  <FormGroup className="mb-3">
                                    <Label htmlFor="bankAccountNumber">
                                      {t("ACCOUNT_NUMBER")}
                                    </Label>
                                    <FormTextField
                                      name="bankAccountNumber"
                                      id="bankAccountNumber"
                                      placeholder={t("ACCOUNT_NUMBER")}
                                    />
                                  </FormGroup>
                                </Col>
                              )}

                              {/* <Col md={6} xl={4}>
                                <FormGroup className="mb-3">
                                  <Label htmlFor="accountOwnerEmail">
                                    {t("ACCOUNT_OWNER_EMAIL")}
                                  </Label>
                                  <FormTextField
                                    name="accountOwnerEmail"
                                    id="accountOwnerEmail"
                                    placeholder={t("ACCOUNT_OWNER_EMAIL")}
                                  />
                                </FormGroup>
                              </Col> */}

                              {/* <Col md={6} xl={4}>
                                <FormGroup className="mb-3">
                                  <Label htmlFor="nickName">
                                    {t("NICK_NAME")}
                                  </Label>
                                  <FormTextField
                                    name="nickName"
                                    id="nickName"
                                    placeholder={t("NICK_NAME")}
                                  />
                                </FormGroup>
                              </Col> */}

                              {/* <Col md={6} xl={4}>
                                <FormGroup className="mb-3">
                                  <Label htmlFor="bankName">
                                    {t("BANK_NAME")}
                                  </Label>
                                  <FormTextField
                                    name="bankName"
                                    id="bankName"
                                    placeholder={t("BANK_NAME")}
                                  />
                                </FormGroup>
                              </Col> */}

                              {/* <Col md={6} xl={4}>
                                <FormGroup className="mb-3">
                                  <Label htmlFor="branchName">
                                    {t("BRANCH_NAME")}
                                  </Label>
                                  <FormTextField
                                    name="branchName"
                                    id="branchName"
                                    placeholder={t("BRANCH_NAME")}
                                  />
                                </FormGroup>
                              </Col> */}

                              {/* <Col sm={6} md={3} xl={2}>
                                                                <FormGroup className="mb-3">
                                                                    <Label htmlFor="bankCodeType">{t("BANK_CODE_TYPE")}</Label>
                                                                    <FormDropdown
                                                                        name="bankCodeType"
                                                                        id="bankCodeType"
                                                                        placeholder={t("SELECT_BANK_CODE_TYPE")}
                                                                        optionsValues={isBankCodeType}
                                                                    />
                                                                </FormGroup>
                                                            </Col> */}

                              {/* <Col sm={6} md={3} xl={2} className="ps-sm-0">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="bankCode">
                                    {t("BANK_CODE")}
                                  </Label>
                                  <FormTextField
                                    name="bankCode"
                                    id="bankCode"
                                    placeholder={t("BANK_CODE")}
                                  />
                                </FormGroup>
                              </Col> */}

                              {/* <Col md={6} xl={4}>
                                <FormGroup className="mb-3">
                                  <Label htmlFor="bankCountry">
                                    {t("BANK_COUNTRY")}
                                  </Label>
                                  <FormDropdown
                                    name="bankCountry"
                                    id="bankCountry"
                                    placeholder={t("SELECT_COUNTRY")}
                                    optionsValues={countries}
                                  />
                                </FormGroup>
                              </Col> */}

                              <Col md={6} xl={4}>
                                <FormGroup className="mb-3">
                                  <Label htmlFor="bankCurrency">
                                    {t("BANK_CURRENCY")}
                                  </Label>
                                  <FormDropdown
                                    name="bankCurrency"
                                    id="bankCurrency"
                                    placeholder={t("SELECT_BANK_CURRENCY")}
                                    optionsValues={isBankCurrency}
                                  />
                                </FormGroup>
                              </Col>

                              {values.bankCodeType === "au_bank" ? (
                                <Col md={6} xl={4}>
                                  <FormGroup className="mb-3">
                                    <Label htmlFor="bsbCode">
                                      {t("BSB_CODE")}
                                    </Label>
                                    <FormTextField
                                      name="bsbCode"
                                      id="bsbCode"
                                      placeholder={t("BSB_CODE")}
                                    />
                                  </FormGroup>
                                </Col>
                              ) : (
                                ""
                              )}

                              {values.bankCodeType === "ca_bank" ? (
                                <>
                                  <Col md={6} xl={4}>
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="institution_number">
                                        {t("INSTITUTION_NUMBER")}
                                      </Label>
                                      <FormTextField
                                        name="institution_number"
                                        id="institution_number"
                                        placeholder={t("INSTITUTION_NUMBER")}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={6} xl={4}>
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="transit_number">
                                        {t("TRANSIT_NUMBER")}
                                      </Label>
                                      <FormTextField
                                        name="transit_number"
                                        id="transit_number"
                                        placeholder={t("TRANSIT_NUMBER")}
                                      />
                                    </FormGroup>
                                  </Col>
                                </>
                              ) : (
                                ""
                              )}

                              {values.bankCodeType === "eu_bank" ? (
                                <Col md={6} xl={4}>
                                  <FormGroup className="mb-3">
                                    <Label htmlFor="iban">{t("I_BAN")}</Label>
                                    <FormTextField
                                      name="iban"
                                      id="iban"
                                      placeholder={t("I_BAN")}
                                    />
                                  </FormGroup>
                                </Col>
                              ) : (
                                ""
                              )}

                              {values.bankCodeType === "gb_bank" ? (
                                <Col md={6} xl={4}>
                                  <FormGroup className="mb-3">
                                    <Label htmlFor="sort_code">
                                      {t("SORT_CODE")}
                                    </Label>
                                    <FormTextField
                                      name="sort_code"
                                      id="sort_code"
                                      placeholder={t("SORT_CODE")}
                                    />
                                  </FormGroup>
                                </Col>
                              ) : (
                                ""
                              )}

                              {values.bankCodeType === "hk_bank" ? (
                                <Col md={6} xl={4}>
                                  <FormGroup className="mb-3">
                                    <Label htmlFor="bank_code">
                                      {t("BANK_CODE")}
                                    </Label>
                                    <FormTextField
                                      name="bank_code"
                                      id="bank_code"
                                      placeholder={t("BANK_CODE")}
                                    />
                                  </FormGroup>
                                </Col>
                              ) : (
                                ""
                              )}

                              {values.bankCodeType === "sg_bank" ? (
                                <Col md={6} xl={4}>
                                  <FormGroup className="mb-3">
                                    <Label htmlFor="swift_code">
                                      {t("SWIFT_CODE")}
                                    </Label>
                                    <FormTextField
                                      name="swift_code"
                                      id="swift_code"
                                      placeholder={t("SWIFT_CODE")}
                                    />
                                  </FormGroup>
                                </Col>
                              ) : (
                                ""
                              )}

                              {values.bankCodeType === "us_bank" ? (
                                <Col md={6} xl={4}>
                                  <FormGroup className="mb-3">
                                    <Label htmlFor="ach">{t("ACH")}</Label>
                                    <FormTextField
                                      name="ach"
                                      id="ach"
                                      placeholder={t("ACH")}
                                    />
                                  </FormGroup>
                                </Col>
                              ) : (
                                ""
                              )}

                              {/* <Col md={6} xl={4}>
                                <FormGroup className="mb-3">
                                  <Label htmlFor="microDeposit1">
                                    {t("MICRO_DEPOSIT_1")}
                                  </Label>
                                  <FormTextField
                                    name="microDeposit1"
                                    id="microDeposit1"
                                    placeholder={t("MICRO_DEPOSIT_1")}
                                  />
                                </FormGroup>
                              </Col>

                              <Col md={6} xl={4}>
                                <FormGroup className="mb-3">
                                  <Label htmlFor="microDeposit2">
                                    {t("MICRO_DEPOSIT_2")}
                                  </Label>
                                  <FormTextField
                                    name="microDeposit2"
                                    id="microDeposit2"
                                    placeholder={t("MICRO_DEPOSIT_2")}
                                  />
                                </FormGroup>
                              </Col> */}
                            </Row>
                            <Row>
                              <h5
                                style={{
                                  marginTop: "20px",
                                  marginBottom: "10px",
                                }}
                              >
                                Authorize Signatory Details
                              </h5>
                              <Col md={6} xl={4}>
                                <FormGroup className="mb-3">
                                  <Label htmlFor="nameOfSignatory">
                                    {t("NAME_OF_THE_SIGNATORY")}
                                  </Label>
                                  <FormTextField
                                    name="nameOfSignatory"
                                    id="nameOfSignatory"
                                    placeholder={t("NAME_OF_THE_SIGNATORY")}
                                  />
                                </FormGroup>
                              </Col>
                              <Col md={6} xl={4}>
                                <FormGroup className="mb-3">
                                  <Label htmlFor="email">{t("EMAIL")}</Label>
                                  <FormTextField
                                    name="email"
                                    id="email"
                                    placeholder={t("EMAIL")}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          </Col>
                          <Col md={12} className="mb-4">
                            <p className="mb-0 font-size-12">
                              <i className="ti-info-alt me-2 mb-0"></i>
                              {t(
                                "TWO_MICRO_DEPOSIT_WII_BE_SENT_TO_YOUR_EXTERNAL_BANK_ACCOUNT_AND_WE_WLL_NEED_YOU_TO_CONFIRM_THE_AMOUNT_THE_DEPOSITS_SHOULD_SHOW_UP_IN_TWO_BUSINESS_DAYS"
                              )}
                            </p>
                          </Col>

                          <Col
                            md={12}
                            className="d-flex justify-content-end align-items-center gap-3"
                          >
                            <Button
                              className="btn-secondary w-md"
                              type="button"
                            >
                              Cancel
                            </Button>
                            <Button className="btn-primary w-md" type="submit">
                              Submit
                            </Button>
                          </Col>
                        </Form>
                      )}
                    </Formik>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AddSourceOfFunds;
