import React, { useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
} from "reactstrap"
import classnames from "classnames"

import PostSignUpPage from "./PostSignUpPage"
import { Link, useLocation, useNavigate } from "react-router-dom"
import logoSm from "../../assets/images/logo/Logo-Icon.webp"
import { useTranslation } from 'react-i18next'
import { useEffect } from "react"
import loginBg from "../../assets/images/login/auth-bg.jpeg";


const ThankYouPage = props => {
  const { t } = useTranslation();
  const [activeTab, setactiveTab] = useState(3)
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state?.data;
  const success = location.state?.success;

  const changeTab = val => {
    setactiveTab(val)
  }

 

  useEffect(() => {
    if (!success) {
      navigate("/login");
    } else {
      setactiveTab(3);
    }
  }, [success, navigate]);

  if(!success){
    return null;
  }

  document.title = `${t("THANK_YOU")} | TPS ${t("SOLUTIONS")}`

  return (
    <React.Fragment>
      <div className="account-pages h-100vh">
        <Container className="h-100">
          <Row className="justify-content-center align-items-center h-100 py-3">
            <Col md={12} lg={8} xl={10}>
              <Card className="overflow-hidden auth-bg auth-bg-reg rounded-5">
                <img className="auth-bg-img" src={loginBg} alt="login-bg"/>
                <div className="position-relative z-3">
                  <div className="text-primary text-center p-4">
                    <h5 className="text-white font-size-20">{t("REGISTRATION")}</h5>
                    <p className="text-white-70 mb-0">
                      {t("GET_YOUR_FREE_SILKWORM_ACCOUNT_NOW")}
                    </p>
                    <Link to="/index" className="logo logo-admin">
                      <img src={logoSm} height="42" alt="logo" />
                    </Link>
                  </div>
                </div>

                <div id="kyc" className="position-relative z-3"></div>

                <CardBody className="p-3 p-md-4 position-relative z-3">
                  <div className="form-horizontal form-wizard-wrapper wizard clearfix register-wizard">
                  

                      <div className="steps clearfix mt-4 pt-2">
                        <ul>
                          <NavItem
                            className={classnames({ current: activeTab === 1 })}
                          >
                            <NavLink
                              className={classnames({
                                current: activeTab === 1,
                              })}
                            >
                              <span className="number">1</span>
                              <span className="d-sm-none">-</span>
                              <span className="d-none d-sm-inline-block">----</span>
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({ current: activeTab === 2 })}
                          >
                            <NavLink
                              className={classnames({
                                active: activeTab === 2,
                              })}
                            >
                              <span className="number">2</span>
                              <span className="d-sm-none">-</span>
                              <span className="d-none d-sm-inline-block">----</span>
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({ current: activeTab === 3 })}
                          >
                            <NavLink
                              className={classnames({
                                active: activeTab === 3,
                              })}
                            >
                              <span>
                                <span className="number">3</span>
                                <span className="d-sm-none">-</span>
                              </span>
                              {/* <span className="d-none d-sm-inline-block">----</span> */}
                            </NavLink>
                          </NavItem>
                        </ul>
                      </div>
  
                    <div className="content clearfix">
                      <TabContent activeTab={activeTab} className="body">
                        <TabPane tabId={1}>
                          {/* <SignUpPage setactiveTab={changeTab} registrationData={registrationData}  countries={countries} titles={titles} setUserId={setUserId} signUpUser={signUpUser} setSignUpUser={setSignUpUser} /> */}
                        </TabPane>
                      </TabContent>
                      <TabContent activeTab={activeTab} className="body">
                        <TabPane tabId={2}>
                        {/* <SecurityQuestion setactiveTab={changeTab} registrationData={registrationData} userId={userId} questions={questions}  signUpUser={signUpUser}/> */}
                        </TabPane>
                      </TabContent>
                      <TabContent activeTab={activeTab} className="body">
                        <TabPane tabId={3}>
                        <PostSignUpPage setactiveTab={changeTab}/>
                        </TabPane>
                      </TabContent>
                    </div>
                  </div>

                  <div className="mt-5 text-center">
                    {activeTab !== 3 ? <p className="mb-1 text-white-70">
                      {t("ALREADY_HAVE_AN_ACOUNT?")}{" "}
                      <Link to="/login" className="fw-medium text-white">
                        {" "}
                        {t("LOGIN")}
                      </Link>{" "}
                    </p> : null}
                    <p className="mb-0 text-white-70">
                      © {new Date().getFullYear()} TPS {t("SOLUTIONS")}
                    </p>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ThankYouPage
