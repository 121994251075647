import { get, post, put } from "../helpers/api_helper"
import * as url from "./url_helper"

// add Events
export const SignUpRegister = (data) => post(url.POST_SIGNUP_REGISTER, data) 

export const SignIn = (data) => post(url.POST_SIGNIN, data) 

export const VerifyAccessToken = (data) => post(url.TOKEN_VERIFY, data)

export const sendOtpToPhnNumber = (data) => post(url.SEND_OTP_TO_PHONE, data)
export const sendOtpToPhnNumberAndVerify = (data) => post(url.SEND_OTP_TO_PHONE_AND_VERIFY, data)
export const oTPVerification = (data) => post(url.OTP_VERIFICATION + `messageId=${data.massageId}&otpCode=${data.otpCode}`, {})
export const answersForSecurityQuestions = (data) => post(url.ANSWER_SECURITY_QUESTIONS, data)
export const ChangePasswordService = (data) => post(url.CHANGE_PASSWORD, data)

export const updateUserDetails = (data) => put(url.UPDATE_USER_DETAILS, data)
export const getSecurityQuestionById = (coUserId) => get(url.GET_SECURITY_QUESTION_BY_ID+coUserId)
export const updatePasswordWithCurrentOne = (data) => put(url.UPDATE_PASSWORD_WITH_CURRENT_ONE, data)
export const updateProfilePicture = (data) => post(url.UPDATE_PROFILE_PICTURE, data)

//sign up data retrive
export const SignUpDataRetrive = (email) => get(`${url.SIGN_UP_DATA_RETRIVE}${email}`)


//sign up user validate
export const SignUpUserValidate = (data) => post(url.POST_SIGNUP_USER_VALIDATE, data)
