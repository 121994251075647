import React from 'react';
import {
    Container,
    Row,
    Col,
    Button,
    Card,
    CardBody,
    Input, Form, FormGroup, Label, CardTitle
} from "reactstrap";
import { Link } from "react-router-dom";
import {withTranslation} from "react-i18next";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {MDBDataTable} from "mdbreact";

const TopUp = (props) => {

    const data = {
        columns: [
            {
                label: "Date & Time",
                field: "date_and_time",
                sort: "asc",
            },
            {
                label: "Source Of Funds",
                field: "source_of_funds",
                sort: "asc",
            },
            {
                label: "Currency Code",
                field: "currency_code",
                sort: "asc",
            },
            {
                label: "Amount",
                field: "amount",
                sort: "asc",
            },
        ],
        rows: [
            {
                date_and_time: "30/10/2023, 11:38",
                source_of_funds: "Source Of Fund 01",
                currency_code: "LKR",
                amount: "422,000.00",
            },
            {
                date_and_time: "30/10/2023, 11:38",
                source_of_funds: "Source Of Fund 02",
                currency_code: "USD",
                amount: "2,100.00",
            },
            {
                date_and_time: "30/10/2023, 11:38",
                source_of_funds: "Source Of Fund 01",
                currency_code: "GBP",
                amount: "3,400.00",
            },
            {
                date_and_time: "30/10/2023, 11:38",
                source_of_funds: "Source Of Fund 04",
                currency_code: "RUB",
                amount: "24,000.00",
            },
        ],
    };

    document.title = "Top-Up | TPS Solutions";
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <div className="page-title-box p-0">
                        <Row>
                            <Col md={6}>
                                <Breadcrumbs title="TPS Solutions" breadcrumbItem="Top-Up" mainTitleLink="/" titleLink="#" />
                            </Col>

                            <Col md={6} className="d-flex justify-content-md-end py-3 py-md-4 gap-2">
                                <Link to="/top-up-transaction-history">
                                    <Button color="primary w-md" >
                                        Top up Transaction
                                    </Button>
                                </Link>
                            </Col>

                        </Row>
                    </div>

                    <Row>
                        <Col md={12}>
                            <Card>
                                <CardBody>
                                    <Form action="#">
                                        <Row>
                                            <Col sm={6} md={4}>
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="bankAccountCurrency">Bank Account Currency <sup className="text-danger">*</sup></Label>
                                                    <div className="d-flex align-items-center">
                                                        {/*Bank Currency Balance*/}
                                                        <div className="w-75">
                                                            <Input
                                                                name="bankAccountCurrencyBalance"
                                                                placeholder="Bank Account Currency Balance"
                                                                type="text"
                                                                className="form-control input-group-left-radius input-group-left-border"
                                                                id="bankAccountCurrencyBalance"
                                                                // onChange={e => setBankAccountCurrencyBalance(e.target.value)}
                                                                // value={validation.values.bankAccountCurrencyBalance || ""}
                                                                // invalid={errors.bankAccountCurrencyBalance ? true : false}
                                                            />
                                                            {/*{errors.bankAccountCurrencyBalance ? (*/}
                                                            {/*    <FormFeedback type="invalid">{errors.bankAccountCurrencyBalance}</FormFeedback>*/}
                                                            {/*) : null}*/}
                                                        </div>
                                                        {/*Bank Currency Balance*/}

                                                        {/*Bank Currency Type*/}
                                                        <div className="w-25">
                                                            <Input
                                                                name="bankAccountCurrency"
                                                                placeholder="Bank Account Currency"
                                                                type="select"
                                                                className="form-control input-group-right-radius"
                                                                id="bankAccountCurrency"
                                                                // onChange={e => setBankAccountCurrency(e.target.value)}
                                                                // value={bankAccountCurrency}
                                                                // invalid={errors.bankAccountCurrency ? true : false}
                                                            >
                                                                <option value="">LKR</option>
                                                                <option value="">USD</option>
                                                                <option value="">GBP</option>
                                                                {/*{bankAccountCurrencies.map((bankAccountCurrency, index) => (*/}
                                                                {/*    <option key={index} value={bankAccountCurrency.value}>*/}
                                                                {/*        {bankAccountCurrency.label}*/}
                                                                {/*    </option>*/}
                                                                {/*))}*/}
                                                            </Input>
                                                            {/*{errors.bankAccountCurrency ? (*/}
                                                            {/*    <FormFeedback type="invalid">{errors.bankAccountCurrency}</FormFeedback>*/}
                                                            {/*) : null}*/}
                                                        </div>
                                                        {/*Bank Currency Type*/}
                                                    </div>
                                                </FormGroup>
                                            </Col>

                                            <Col md={6} lg={4}>
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="sourceOfFunds">Source Of Funds <sup className="text-danger">*</sup></Label>
                                                    <Input
                                                        name="sourceOfFunds"
                                                        placeholder="Source Of Funds"
                                                        type="text"
                                                        className="form-control"
                                                        id="sourceOfFunds"
                                                        // onChange={e => setSourceOfFunds(e.target.value)}
                                                        // value={validation.values.sourceOfFunds || ""}
                                                        // invalid={errors.sourceOfFunds ? true : false}
                                                    />
                                                    {/*{errors.sourceOfFunds ? (*/}
                                                    {/*    <FormFeedback type="invalid">{errors.sourceOfFunds}</FormFeedback>*/}
                                                    {/*) : null}*/}
                                                </FormGroup>
                                            </Col>

                                            <Col md={6} lg={4}>
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="amount">Amount <sup className="text-danger">*</sup></Label>
                                                    <Input
                                                        name="amount"
                                                        placeholder="Amount"
                                                        type="number"
                                                        className="form-control"
                                                        id="amount"
                                                        // onChange={e => setAmount(e.target.value)}
                                                        // value={validation.values.amount || ""}
                                                        // invalid={errors.amount ? true : false}
                                                    />
                                                    {/*{errors.amount ? (*/}
                                                    {/*    <FormFeedback type="invalid">{errors.amount}</FormFeedback>*/}
                                                    {/*) : null}*/}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle className="h4">Top-Up History</CardTitle>

                                    <div className="custom-table">
                                        <MDBDataTable responsive data={data} />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};
export default withTranslation()(TopUp);