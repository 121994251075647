import React, { useEffect, useState } from "react";
import * as yup from "yup";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  FormGroup,
  Label,
  Spinner,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useTranslation } from "react-i18next";
import { Field, Form, Formik } from "formik";
import { toast } from "react-toastify";
import FormDropdown from "../../common/formComponents/FormDropdown";
import FormTextField from "../../common/formComponents/FormTextField";
import {
  getPayeeDetailsBypayeeId,
  getPayeeRegisterData,
} from "../../services/PayeeService";
import { useSelector } from "react-redux";
import {
  getCurrencyConvert,
  getSourceOfFundsPurpose,
  saveSourceOfFundsRegisterPayee,
} from "../../services/SourceOfFunds";
import { useLocation, useNavigate } from "react-router-dom";
import FormImageUploader from "../../common/formComponents/FormImageUploader";
import { getGlobalAccounts } from "../../services/GlobalAccountsService";
import { getGlobalAccountBalance } from "../../services/CommonService";

const RegisteredPayee = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [registeredPayees, setRegisteredPayees] = useState([]);
  const [selectedPayee, setSelectedPayee] = useState([]);
  const [purposeOfTransfer, setPurposeOfTransfer] = useState([]);
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [globalAccounts, setGlobalAccounts] = useState([]);
  const [accountBalance, setAccountBalance] = useState("");
  const [selectedAccountCurrency, setSelectedAccountCurrency] = useState(null);
  const [fxWarningMassage, setFxWarningMassage] = useState(false);
  const [fxErrorMassage, setFxErrorMassage] = useState("");
  const [transferFee, setTrasnferFee] = useState(0)
  const [swiftOptions, setSwiftOptions] = useState([
    {label: "SHA", value: "SHARED"},
    {label: "OUR", value: "PAYER"}
  ]);

  const user = useSelector((state) => state.auth.data?.userDTO);
  const isAccountActive = user?.isActive;

  // Parse the query parameters from the location object
  const queryParams = new URLSearchParams(location.search);

  // Get the value of the 'payee_id' parameter
  const payee_id = queryParams.get("payee_id");

  useEffect(() => {
    if (isAccountActive == true) {
      fetchPayees();
      fetchPurpose();
      fetchCurrentBalances();
    }
  }, [isAccountActive]);

  useEffect(() => {
    changePayeeForParams(payee_id);
  }, [payee_id]);

  const fetchCurrentBalances = async () => {
    const coUserId = user?.coUserId;
    try {
      const respons = await getGlobalAccountBalance(coUserId);
      const newResponse = respons.result.map((account) => {
        return {
          value: account?.currency_code,
          label: `${account?.total} ${account?.currency_code}`,
          total: account?.total,
          flag: account?.flag,
        };
      });
      setGlobalAccounts(newResponse);
    } catch (error) {
      //error massage
    }
  };

  const fetchGlobalAccounts = async () => {
    try {
      const response = await getGlobalAccounts(user.coUserId);
      const newResponse = response.result.map((account) => {
        return {
          value: account?.globalAccountId,
          label: `${account?.accountNumber} - ${account?.currency}`,
          balance: account?.accountBalance,
          currency: account?.currency,
        };
      });
      setGlobalAccounts(newResponse);
    } catch (error) {
      //toast.error(t("ERROR_FETCH_GLOBAL_ACCOUNTS"))
    }
  };

  const fetchPurpose = async () => {
    try {
      const response = await getSourceOfFundsPurpose();
      const data = response.result.map((purpose) => {
        return {
          value: purpose.purpose,
          label: purpose.purpose,
        };
      });
      setPurposeOfTransfer(data);
    } catch (error) {
      //toast.error(t("ERROR_FETCH_PAYEE_PURPOSE"));
    }
  };

  const fetchPayees = async () => {
    try {
      const data = {
        co_user_id: user.coUserId,
      };
      const response = await getPayeeRegisterData(data);
      const payees = response.result.map((payee) => {
        if (payee.payee_type == "PERSONAL") {
          return {
            value: payee.payee_id,
            label: `${payee.first_name} ${payee.last_name}`,
          };
        } else {
          return {
            value: payee.payee_id,
            label: payee.company_name,
          };
        }
      });
      setRegisteredPayees(payees);
    } catch (error) {
      // toast.error(t("ERROR_FETCH_PAYEE"));
    }
  };

  const validationSchema = yup.object().shape({
    payee_id: yup.string().required(t("PAYEE_NAME_IS_REQUIRED")),
    payAmount: yup
      .number()
      .required(t("PAY_AMOUNT_IS_REQUIRED"))
      .typeError(t("MUST_BE_A_NUMBER"))
      .positive(t("MUST_BE_A_POSITIVE_NUMBER"))
      .min(10, t("PAY_AMOUNT_SHOULD_BE_AT_LEAST_TWO_DIGIT_NUMBER"))
      .max(
        accountBalance,
        t("PAY_AMOUT_MUST_BE_LESS_THAN_OR_QEUAL_TO_AVAILABLE_BALANCE")
      ),
    transferOn: yup.string().required(t("TRANSFER_ON_IS_REQUIRED")),
    transferAmount: yup.string().required(t("TRANSFER_AMOUNT_IS_REQUIRED")),
    reference: yup.string().required(t("REFERENCE_NAME_IS_REQUIRED")),
    purpose: yup.string().required(t("PURPOSE_NAME_IS_REQUIRED")),
    accountCurrency: yup
      .string()
      .required(t("CURRENCY_IS_REQUIRED"))
      .nullable(),
  });

  const onSubmit = async (values) => {
    setLoading(true);

    let transferAmout = values.transferAmount.split(" ");
    try {
      const data = {
        fundTransferId: null,
        coUserId: user.coUserId,
        walletId: user.walletsId[0] ? user.walletsId[0] : null,
        payeeId: values.payee_id,
        paymentAmount: values.payAmount,
        feeCurrency: values?.accountCurrency,
        status: "string",
        reason: values.purpose,
        reference: values.reference,
        requestId: null,
        referenceDocument: values.dirDocument,
        paymentMethod: values.transferMethod[0],
        bank_country: values.bankCountry,
        transfer_amount: transferAmout[0],
        transferDate: values.transferOn,
        swiftOption: values.swiftOption
      };
      const response = await saveSourceOfFundsRegisterPayee(data);
      setLoading(false);
      toast.success(t("FUNDS_TRANSFER_TO_REGISTERED_PAYEE_SUCCESS"));
      navigate("/payee-transaction-history");
    } catch (error) {
      setLoading(false);
      if(error.response.data.errorType === "Airwallex side failure!"){
        toast.error(error.response.data.result.error.errors[0].source)
      } else {
        toast.error(t("ERROR_TRANSACTION_FAILED"));
      }
    }
  };

  const changePayeeForParams = async (payee_id) => {
    try {
      const data = {
        payee_id: payee_id,
      };
      const response = await getPayeeDetailsBypayeeId(data);
      setSelectedPayee(response.result[0]);
      // form
    } catch (error) {
      toast.error(t("ERROR_FETCH_PAYEE"));
    }
  };

  const changePayee = async (value, form) => {
    try {
      const data = {
        payee_id: value,
      };
      const response = await getPayeeDetailsBypayeeId(data);
      setSelectedPayee(response.result[0]);
      // form
    } catch (error) {
      toast.error(t("ERROR_FETCH_PAYEE"));
    }
  };

  const showAvailableBalance = async (value, form) => {
    const getAccount = globalAccounts.find(
      (account) => account?.value === value
    );
    const selectedCurrencyTotal = getAccount?.total;

    if (value == "Select an option") {
      setAccountBalance(null);
      setSelectedAccountCurrency(null);
      form.setFieldValue("accountCurrency", null);
    } else if (
      form.values.payAmount > 0 &&
      form.values.payAmount <= selectedCurrencyTotal
    ) {
      setAccountBalance(getAccount?.total);
      setSelectedAccountCurrency(getAccount?.value);
      form.setFieldValue("accountCurrency", value);
      calculateTransferAmount(form.values.payAmount, form);
    } else {
      setAccountBalance(getAccount?.total);
      setSelectedAccountCurrency(getAccount?.value);
      form.setFieldValue("accountCurrency", value);
    }
  };

  const calculateTransferAmount = async (value, form) => {
    if (form.values.bankCurrency === "") {
      form.setFieldValue("payAmount", value);
      form.setFieldValue("transferAmount", "");
      return;
    }

    let swiftOpt = form.values?.swiftOption == 'SHARED' ? 'SHA' : form.values?.swiftOption === 'PAYER' ? 'OUR' : undefined
    let clearingSystem = selectedPayee?.local_clearing_system
    console.log("clearingSystem", clearingSystem)
    console.log("form.values?.transferMethod", form.values?.transferMethod[0])
    let tranferMethod = getPaymentValue(form.values.transferMethod[0])
    console.log("tranferMethod", tranferMethod)
    tranferMethod = tranferMethod === 'SWIFT' && swiftOpt ? tranferMethod + ' - ' + swiftOpt : 
                      tranferMethod === 'LOCAL' && clearingSystem ? clearingSystem : tranferMethod
    
    if (value <= accountBalance) {
      // let tranferMethod = getPaymentValue(form.values.transferMethod[0])

      const availbleBalance = globalAccounts.find( account => account?.value === form.values.accountCurrency)
   
      const data = {
        buy_amount: value,
        buy_currency: selectedAccountCurrency,
        sell_currency: selectedPayee?.bank_currency,
        isFx:
          selectedAccountCurrency == selectedPayee?.bank_currency
            ? false
            : true,
        accountBalance: availbleBalance?.total,
        transferFee: {
          country: selectedPayee?.bank_country,
          currency: selectedPayee?.bank_currency,
          paymentMethod: tranferMethod,
          type: "RATES",
        },
      };

      setFxErrorMassage("");
      setFxWarningMassage(
        selectedAccountCurrency == selectedPayee?.bank_currency ? false : true
      );

      try {
        if (value > 10) {
          const response = await getCurrencyConvert(data);
          const convertedAmount = response.result.transferAmount;
          form.setFieldValue("transferAmount", `${convertedAmount} ${form.values.bankCurrency}`);
          setTrasnferFee(response.result?.fee)
        }
      } catch (error) {
        if (error?.response?.data?.result?.error) {
          setFxErrorMassage(error?.response?.data?.result?.error);
        }
        form.setFieldValue("transferAmount", "");
      }

      form.setFieldValue("payAmount", value);
    } else {
      form.setFieldValue("payAmount", value);
      form.setFieldValue("transferAmount", "");
    }
  };

  function getPaymentValue(paymentMethod) {
    if (paymentMethod.startsWith("local - ")) {
      return paymentMethod.split(" - ")[1].toUpperCase();
    } else {
      return paymentMethod.toUpperCase();
    }
  }

  document.title = t("FUNDS_TRANSFER_TPS_SOLUTIONS");

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            maintitle={t('TPS_SOLUTIONS')}
            title={t("FUNDS_TRANSFER")}
            breadcrumbItem={t("FUNDS_TRANSFER_TO_REGISTERED_PAYEE")}
            mainTitleLink="/"
            titleLink="/funds-transfer"
          />

          <Row>
            <Col md={12}>
              {/*<Card>*/}
              {/*  <CardBody>*/}
              {/*<CardTitle className="h4 font-size-18">Linked Source Of Funds</CardTitle>*/}

              <Row className="mt-0">
                <Formik
                  enableReinitialize
                  initialValues={{
                    payee_id: payee_id
                      ? payee_id
                      : selectedPayee
                      ? selectedPayee?.payee_id
                      : "",
                    bankCountry: selectedPayee
                      ? selectedPayee?.bank_country
                      : "",
                    accountNumber: selectedPayee
                      ? selectedPayee?.account_number
                      : "",
                    transferMethod: selectedPayee
                      ? selectedPayee?.payment_methods
                      : "",
                    bankCurrency: selectedPayee
                      ? selectedPayee?.bank_currency
                      : "",
                    payAmount: "",
                    transferOn: "",
                    transferAmount: "",
                    purpose: "",
                    reference: "",
                    description: "",
                    dirDocument: "",
                    accountCurrency: "",
                    swiftOption: "SHARED",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  {({ isSubmitting, values, setFieldValue }) => (
                    <Row className="d-flex justify-content-start">
                      <Col md={10} xl={8}>
                        <Form>
                          <Col md={12} className="mb-3">
                            <Card>
                              <CardBody>
                                <Row>
                                  <Col md={24}>
                                    <h5 className="text-dark-color mb-3">
                                      {t("PAYEE_DETAILS")}
                                    </h5>
                                  </Col>
                                  <Col md={6} xl={6}>
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="payee_id">
                                        {t("PAYEE_NAME")}
                                        <sup className="text-danger"> *</sup>
                                      </Label>
                                      <FormDropdown
                                        name="payee_id"
                                        id="payee_id"
                                        optionsValues={registeredPayees}
                                        changeDropdown={changePayee}
                                        disabled={
                                          isAccountActive ? false : true
                                        }
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={6} xl={6}>
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="bankCountry">
                                        {t("BANK_COUNTRY")}
                                        <sup className="text-danger"> *</sup>
                                      </Label>
                                      <FormTextField
                                        name="bankCountry"
                                        id="bankCountry"
                                        disabled={true}
                                        placeholder={t("BANK_COUNTRY")}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={6} xl={6}>
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="accountNumber">
                                        {t("ACCOUNT_NUMBER")}
                                        <sup className="text-danger"> *</sup>
                                      </Label>
                                      <FormTextField
                                        name="accountNumber"
                                        id="accountNumber"
                                        disabled={true}
                                        placeholder={t("ACCOUNT_NUMBER")}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={6} xl={6}>
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="transferMethod">
                                        {t("TRANSFER_METHOD")}
                                        <sup className="text-danger"> *</sup>
                                      </Label>
                                      <FormTextField
                                        name="transferMethod"
                                        id="transferMethod"
                                        disabled={true}
                                        placeholder={t("TRANSFER_METHOD")}
                                      />
                                    </FormGroup>
                                  </Col>

                                  <Col md={6} xl={6}>
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="bankCurrency">
                                        {t("BANK_CURRENCY")}
                                        <sup className="text-danger"> *</sup>
                                      </Label>
                                      <FormTextField
                                        name="bankCurrency"
                                        id="bankCurrency"
                                        disabled={true}
                                        placeholder={t("BANK_CURRENCY")}
                                      />
                                    </FormGroup>
                                  </Col>
                                  {
                                    selectedPayee?.payment_methods == 'SWIFT' ? (
                                      <Col md={6} xl={6}>
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="swiftOption">
                                        {t("SWIFT_OPTION")}
                                        <sup className="text-danger"> *</sup>
                                      </Label>
                                      <FormDropdown
                                        name="swiftOption"
                                        id="swiftOption"
                                        placeholder={t("SWIFT_OPTION")}
                                        optionsValues={swiftOptions}
                                        setFieldValue={setFieldValue}
                                        disabled={
                                          isAccountActive ? false : true
                                        }
                                      />
                                    </FormGroup>
                                  </Col>
                                    ) : ("")
                                  }
                                </Row>
                              </CardBody>
                            </Card>
                            <Card>
                              <CardBody>
                                <Row className="">
                                  <Col md={24}>
                                    <h5 className="text-dark-color mb-3">
                                      {t("TRANSFER_DETAILS")}
                                    </h5>
                                  </Col>

                                  <Col md={6} xl={6}>
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="payAmount">
                                        {t("SELECT_ACCOUNT")}
                                        <sup className="text-danger"> *</sup>
                                      </Label>
                                      <FormDropdown
                                        name="accountCurrency"
                                        id="accountCurrency"
                                        optionsValues={globalAccounts}
                                        disabled={
                                          isAccountActive &&
                                          values.bankCurrency != null
                                            ? false
                                            : true
                                        }
                                        placeholder={t("SELECT_ACCOUNT")}
                                        changeDropdown={showAvailableBalance}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={6} xl={6}>
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="payAmount">
                                        {t("AMOUNT_YOU_PAY")}
                                        <sup className="text-danger"> *</sup>
                                      </Label>
                                      <FormTextField
                                        name="payAmount"
                                        id="payAmount"
                                        type="number"
                                        placeholder={t("AMOUNT_YOU_PAY")}
                                        changeInput={calculateTransferAmount}
                                        disabled={
                                          isAccountActive &&
                                          values.bankCurrency != null
                                            ? false
                                            : true
                                        }
                                      />

                                      <h6 style={{ textAlign: "center" }}>
                                        {t("AVAILABLE_BALANCE")} :{" "}
                                        {accountBalance ? accountBalance : "0"}{" "}
                                        {values?.accountCurrency
                                          ? ` - ${values?.accountCurrency}`
                                          : ""}
                                      </h6>
                                      {fxErrorMassage ? (
                                        <span style={{ color: "#cc3300" }}>
                                          {fxErrorMassage}
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </FormGroup>
                                  </Col>
                                  <Col md={6} xl={6}>
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="transferOn">
                                        {t("TRANSFER_ON")}
                                        <sup className="text-danger"> *</sup>
                                      </Label>
                                      <FormTextField
                                        type="date"
                                        name="transferOn"
                                        id="transferOn "
                                        disabled={
                                          isAccountActive ? false : true
                                        }
                                        placeholder={t("TRANSFER_ON")}
                                        disablePastDate={true}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={6} xl={6}>
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="email">
                                        {t("TRANSFER_FEE")}
                                        <sup className="text-danger"> *</sup>
                                      </Label>
                                      <h6 className="text-primary">
                                        {" "}
                                        {/* {t("1_SGD_014_USD")} */}
                                        { transferFee && transferFee} { transferFee && transferFee > 0 ? values.accountCurrency : ""}
                                      </h6>
                                    </FormGroup>
                                  </Col>
                                  <Col md={6} xl={6}>
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="email">
                                        {t("TRANSFER_AMOUNT")}
                                        <sup className="text-danger"> *</sup>
                                      </Label>
                                      <FormTextField
                                        disabled={true}
                                        name="transferAmount"
                                        id="transferAmount"
                                        placeholder={t("TRANSFER_AMOUNT")}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={6} xl={6}>
                                    <FormGroup
                                      className="mb-3"
                                      style={{ marginTop: "25px" }}
                                    >
                                      {/* <Label htmlFor="email">
                                        {t("TRANSFER_AMOUNT")}
                                        <sup className="text-danger"> *</sup>
                                      </Label> */}
                                      {fxWarningMassage ? (
                                        <span style={{ color: "#cc3300" }}>
                                          Transferred amount may vary due to
                                          currency exchange rates.
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                            <Card>
                              <CardBody>
                                <Row className="">
                                  <Col md={24}>
                                    <h5 className="text-dark-color mb-3">
                                      {t("ADDITIONAL_DETAILS")}
                                    </h5>
                                  </Col>
                                  <Col md={6} xl={6}>
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="purpose">
                                        {t("PURPOSE_OF_TRANSFER")}
                                        <sup className="text-danger"> *</sup>
                                      </Label>
                                      <FormDropdown
                                        name="purpose"
                                        id="purpose"
                                        optionsValues={purposeOfTransfer}
                                        disabled={
                                          isAccountActive ? false : true
                                        }
                                        placeholder={t("PURPOSE_OF_TRANSFER")}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={6} xl={6}>
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="reference">
                                        {t("REFERENCE")}
                                        <sup className="text-danger"> *</sup>
                                      </Label>
                                      <FormTextField
                                        name="reference"
                                        id="reference"
                                        disabled={
                                          isAccountActive ? false : true
                                        }
                                        placeholder={t("REFERENCE")}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={6} xl={6}>
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="description">
                                        {t("DESCRIPTION")}
                                      </Label>
                                      <FormTextField
                                        name="description"
                                        id="description"
                                        placeholder={t("DESCRIPTION")}
                                        disabled={
                                          isAccountActive ? false : true
                                        }
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={6} xl={6}>
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="dirDocument">
                                        {t("REFERENCE_DOCUMENT")}
                                      </Label>
                                      <Field
                                        name="dirDocument"
                                        component={FormImageUploader}
                                        label="Choose a file"
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Col>

                          <Col
                            md={12}
                            className="d-flex justify-content-end align-items-center gap-3"
                          >
                            <Button
                              className="btn-secondary w-md"
                              type="button"
                              onClick={() => navigate("/funds-transfer")}
                            >
                              {t("CANCEL")}
                            </Button>
                            <Button
                              className="btn-primary w-md"
                              type="submit"
                              disabled={isAccountActive ? false : true}
                            >
                              {loading ? (
                                <Spinner size="sm">Loading...</Spinner>
                              ) : (
                                ""
                              )}{" "}
                              {t("PAY")}
                            </Button>
                          </Col>
                        </Form>
                      </Col>
                    </Row>
                  )}
                </Formik>
              </Row>
              {/*  </CardBody>*/}
              {/*</Card>*/}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default RegisteredPayee;
