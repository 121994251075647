import React, { useEffect, useState } from "react"
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap"
import { get, map } from "lodash"
import {useTranslation, withTranslation} from "react-i18next"

//i18n
import i18n from "../../../i18n"
import languages from "../../../common/languages"
import { toast } from "react-toastify"
import { changeLanguageOnUser } from "../../../services/Profile"
import { useSelector } from "react-redux"
import { updateUserDetailsRedux } from "../../../store/reducers/authReducer"
import { useDispatch } from "react-redux"

const LanguageDropdown = () => {
  const user = useSelector((state) => state.auth.data?.userDTO);
  // Declare a new state variable, which we'll call "menu"
  const [selectedLang, setSelectedLang] = useState(null)
  const [menu, setMenu] = useState(false)
  const dispatch = useDispatch()
  const { t } = useTranslation();

  useEffect(() => {
    const currentLanguage = user?.language
    setSelectedLang(currentLanguage)
  }, [selectedLang])

  const changeLanguageAction = lang => {
    i18n.changeLanguage(lang)
    localStorage.setItem("i18nextLng", lang)
    setSelectedLang(lang)

    try {
      const data = {
        coUserId: user?.coUserId,
        language: lang,
      };
      changeLanguageOnUser(data);
      dispatch(updateUserDetailsRedux(data));
      toast.success(t("LANGUAGE_UPDATED_SUCCESSFULLY"));

    } catch (error) {
      toast.error("Error");
    }

  }

  const toggle = () => {
    setMenu(!menu)
  }

  return (
    <>
      <Dropdown isOpen={menu} toggle={toggle} className="d-none d-md-block ms-2">
        <DropdownToggle className="btn header-item waves-effect" tag="button">
          <img
            src={get(languages, `${user?.language}.flag`)}
            alt="Veltrix"
            height="16"
            className="me-2"
          />
          {" "}{get(languages, `${user?.language}.label`)}
          {" "}<span className="mdi mdi-chevron-down"></span>
        </DropdownToggle>
        <DropdownMenu className="language-switch dropdown-menu-end">
          {map(Object.keys(languages), key => (
            <DropdownItem
              key={key}
              onClick={() => changeLanguageAction(key)}
              className={`notify-item ${selectedLang === key ? "active" : "none"
                }`}
            >
              <img
                src={get(languages, `${key}.flag`)}
                alt="Veltrix"
                className="me-2"
                height="12"
              />
              <span className="align-middle">
                {get(languages, `${key}.label`)}
              </span>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </>
  )
}

export default withTranslation()(LanguageDropdown)
