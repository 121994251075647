import { Form, Formik } from "formik";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
  Toast,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import FormDropdown from "../../common/formComponents/FormDropdown";
import FormTextField from "../../common/formComponents/FormTextField";
import * as yup from "yup";
import { getBankCurrency, getCountries } from "../../services/CommonService";
import { toast } from "react-toastify";
import {
  deleteGlobalAccounts,
  getGlobalAccounts,
  saveGlobalAccount,
  updateGlobalAccount,
  downloadGlobalAccountStatement
} from "../../services/GlobalAccountsService";
import { useSelector } from "react-redux";
import MaterialTableCustom from "../../common/tables/MaterialTableCustom";
import jsonCountryCurrency from '../../data/COUNTRY_CURRENCY.json';

const MonthlyStatement = () => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.auth.data?.userDTO);

  document.title = t("MONTHLY_STATEMENT");

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box p-0">
            <Row>
              <Col md={6}>
                <Breadcrumbs
                  title={t("TPS_SOLUTIONS")}
                  breadcrumbItem={t("MONTHLY_STATEMENT")}
                  mainTitleLink="/"
                  titleLink="#"
                />
              </Col>
            </Row>
          </div>
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <CardTitle className="h4 mb-3">
                    {t("MONTHLY_STATEMENT")}
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default MonthlyStatement;
