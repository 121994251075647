import React from "react"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  FormGroup,
  Label,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useTranslation } from "react-i18next"
import { Form, Formik } from "formik"
import * as yup from "yup"
import FormTextField from "../../common/formComponents/FormTextField"
import { toast } from "react-toastify"

const MicroDeposite = () => {
  const { t } = useTranslation()





  const validationSchema = yup.object().shape({
    microdeposite_one: yup.string().required("Micro Deposite Value 1 Is Required"),
    microdeposite_two: yup.string().required("Micro Deposite Value 2 Is Required"),
  })

  const onSubmit = async values => {
    try {
      // const data = {
      //   //data
      // }
    //   const response = await saveSourceOfFunds(data)
      toast.success("Successfully Insert")
    } catch (error) {
      //toast.error("Error : Insert Source Of Funds")
    }
  }



  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            maintitle="TPS Solutions"
            title="Source Of Funds"
            breadcrumbItem="Add Microdeposite"
            mainTitleLink="/"
            titleLink="/source-of-funds"
          />

          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  {/*<CardTitle className="h4 font-size-18">Linked Source Of Funds</CardTitle>*/}

                  <Row className="mt-0">
                    
                    <Formik
                      enableReinitialize
                      initialValues={{
                        microdeposite_one : "",
                        microdeposite_two : ""
                      }}
                      validationSchema={validationSchema}
                      onSubmit={onSubmit}
                    >
                      {({ isSubmitting, values }) => (
                        <Form>
                          <Col md={12} className="mb-3">
                            <Row>
                              <Col md={6} xl={4}>
                                <FormGroup className="mb-3">
                                  <Label htmlFor="microdeposite_one">
                                    {t("MICRO_DEPOSIT_VALUE_ONE")}
                                  </Label>
                                  <FormTextField
                                    name="microdeposite_one"
                                    id="microdeposite_one"
                                    placeholder={t("MICRO_DEPOSIT_VALUE_TWO")}
                                  />
                                </FormGroup>
                              </Col>

                              <Col md={6} xl={4}>
                                <FormGroup className="mb-3">
                                  <Label htmlFor="microdeposite_two">
                                    {t("MICRO_DEPOSIT_VALUE_TWO")}
                                  </Label>
                                  <FormTextField
                                    name="microdeposite_two"
                                    id="microdeposite_two"
                                    placeholder={t("MICRO_DEPOSIT_VALUE_TWO")}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          </Col>
                          <Col md={12} className="mb-4">
                            <p className="mb-0 font-size-12">
                              <i className="ti-info-alt me-2 mb-0"></i>
                              {t(
                                "TWO_MICRO_DEPOSIT_WII_BE_SENT_TO_YOUR_EXTERNAL_BANK_ACCOUNT_AND_WE_WLL_NEED_YOU_TO_CONFIRM_THE_AMOUNT_THE_DEPOSITS_SHOULD_SHOW_UP_IN_TWO_BUSINESS_DAYS"
                              )}
                            </p>
                          </Col>

                          <Col
                            md={12}
                            className="d-flex justify-content-end align-items-center gap-3"
                          >
                            <Button
                              className="btn-secondary w-md"
                              type="button"
                            >
                              Cancel
                            </Button>
                            <Button className="btn-primary w-md" type="submit">
                              Submit
                            </Button>
                          </Col>
                        </Form>
                      )}
                    </Formik>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default MicroDeposite
